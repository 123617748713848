import { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { accountService } from '../../services/account.service';
import { history } from '../../helpers';
import { alertService } from '../../services/alert.service';


function VerifyEmail() {
    const EmailStatus = {
        Verifying: 'Verifying',
        Failed: 'Failed'
    }

    const [redirect, setRedirect] = useState(false);
    const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

    useEffect(() => {
        const { token } = queryString.parse(window.location.search);

        // remove token from url to prevent http referer leakage
        history.replace(window.location.pathname);

        accountService.verifyEmail(token as string)
            .then(() => {
                console.log("Verification successful");
                alertService.success('Weryfikacja', 'Weryfikacja zakończona pomyślnie, możesz się zalogować.', { keepAfterRouteChange: true });
                history.push('login');
                setRedirect(true);
            })
            .catch(() => {
                setEmailStatus(EmailStatus.Failed);
            });
    }, [EmailStatus.Failed]);

    function getBody() {
        switch (emailStatus) {
            case EmailStatus.Verifying:
                return <div>Trwa weryfikacja...</div>;
            case EmailStatus.Failed:
                return <div>Weryfikacja się nie powiodła, możesz ponowić próbę poprzez <Link to="forgot-password">proces zmiany hasła</Link>.</div>;
        }
    }

    if (redirect) {
        return <Redirect to='/'/>;
    }

    return (
        
        <div>
            <h3 className="card-header">Verify Email</h3>
            <div className="card-body">{getBody()}</div>
        </div>
    )
}

export { VerifyEmail }; 