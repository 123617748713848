
export enum ModalDialogMode {
	View = 0,
	Edit = 1,
	Add = 2,
	Delete = 3
}

export interface IModalDialogProps {
	modalDialogMode: ModalDialogMode,
	isModalDialogOpened: boolean;
	closeModalDialog: (exitCode: number | undefined) => void;
}
