import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { UserContext, isLoggedIn  } from '../../../config/user-context';
import { accountService } from '../../../services/account.service';

class DropdownProfile extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
	logout() {
		//this.context.login(null);
	}
	render() {
		return (
			<UserContext.Consumer>
			{userContext => (
			isLoggedIn(userContext.userInfo) &&	
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="navbar-item navbar-user dropdown" tag="div">
				<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
					{/* <div className="image image-icon bg-gray-800 text-gray-600">
						<i className="fa fa-user"></i>
					</div> */}
					<span> 
						<span className="simple-text">{ userContext.userInfo.firstName }</span>
						<b className="caret"></b>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-end" right tag="div">
					<DropdownItem>Edit Profile</DropdownItem>
					{/* <DropdownItem><span className="badge bg-danger float-end rounded-pill">2</span> Inbox</DropdownItem> */}
					{/* <DropdownItem>Calendar</DropdownItem> */}
					{/* <DropdownItem>Setting</DropdownItem> */}
					<DropdownItem>Regulamin</DropdownItem>
					<DropdownItem>Polityka Prywatności</DropdownItem>
					<DropdownItem>Kontakt/Zgłoś błąd</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem onClick={() => accountService.logout()}>Wyloguj</DropdownItem>
				</DropdownMenu>
			</Dropdown>			
			)}
			</UserContext.Consumer>
		);
	}
};

export default DropdownProfile;
