import { IUserInfoProps, UserContext } from '../../config/user-context';
import { IProcessingModel } from '../../helpers';
import { UseFieldArrayRemove, UseFieldArrayReturn, UseFormGetValues, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import { Form, FloatingLabel, Card, Button } from 'react-bootstrap';
import React from 'react';

const boxTypes: { 
					id: string, 
					name: string,
					boxSizes: { id: string, name: string }[]
				}[] = [
					{ id: "", name: "---", boxSizes: [] },
					{ id: "zn", name: "ZMIESZANE - odbiór 2/miesiąc", boxSizes: [
						{ id: "", name: "---"},
						{ id: "plw120l", name: "Pojemnik lub worek 120 l" },
						{ id: "p240l", name: "Pojemnik 240 l" },
						{ id: "p1100l", name: "Pojemnik 1100 l" },
						{ id: "k15", name: "Kontener 1,5 m3" },
						{ id: "k25", name: "Kontener 2,2 m3 do 2.5 m3" },
						{ id: "k50", name: "Kontener 5 m3" },
						{ id: "k70", name: "Kontener 7 m3" },
						{ id: "k100", name: "Kontener 10 m3" }
					] },
					{ id: "bio", name: "BIODEGRADACJA - odbiór 2/miesiąc", boxSizes: [
						{ id: "", name: "---"},
						{ id: "plw120l", name: "Pojemnik lub worek 120 l" },
						{ id: "p240l", name: "Pojemnik 240 l" },
						{ id: "p1100l", name: "Pojemnik 1100 l" },
						{ id: "k15", name: "Kontener 1,5 m3" },
						{ id: "k25", name: "Kontener 2,2 m3 do 2.5 m3" },
						{ id: "k50", name: "Kontener 5 m3" },
						{ id: "k70", name: "Kontener 7 m3" },
						{ id: "k100", name: "Kontener 10 m3" }
					] },
					{ id: "sz", name: "SZKŁO - odbiór 1/miesiąc", boxSizes: [
						{ id: "", name: "---"},
						{ id: "plw120l", name: "Pojemnik lub worek 120 l" },
						{ id: "k15", name: "Kontener 1,5 m3" },
						{ id: "k25", name: "Kontener 2,2 m3 do 2.5 m3" },
						{ id: "k50", name: "Kontener 5 m3" },
						{ id: "k70", name: "Kontener 7 m3" },
						{ id: "k100", name: "Kontener 10 m3" }
					] },
		
					{ id: "pa", name: "PAPIER - odbiór 1/miesiąc", boxSizes: [
						{ id: "", name: "---"},
						{ id: "plw120l", name: "Pojemnik lub worek 120 l" },
						{ id: "k15", name: "Kontener 1,5 m3" },
						{ id: "k25", name: "Kontener 2,2 m3 do 2.5 m3" },
						{ id: "k50", name: "Kontener 5 m3" },
						{ id: "k70", name: "Kontener 7 m3" },
						{ id: "k100", name: "Kontener 10 m3" }
					] },
					{ id: "pl", name: "PLASTIK - odbiór 1/miesiąc", boxSizes: [
						{ id: "", name: "---"},
						{ id: "plw120l", name: "Pojemnik lub worek 120 l" },
						{ id: "k15", name: "Kontener 1,5 m3" },
						{ id: "k25", name: "Kontener 2,2 m3 do 2.5 m3" },
						{ id: "k50", name: "Kontener 5 m3" },
						{ id: "k70", name: "Kontener 7 m3" },
						{ id: "k100", name: "Kontener 10 m3" }
					] }
		];

interface IBox {
	boxType: string,
	boxSize: string,	
	boxAmount: number
}

export type FormInputs = {
	contractId: number | undefined,
	boxes: IBox[]
};

export interface IDeclarationsProps 
	extends IUserInfoProps, UseFormReturn<FormInputs, any>, UseFieldArrayReturn<FormInputs, "boxes">  {
}

interface IDeclarationBoxItemProps extends IDeclarationsProps {
	itemNumber: number,
	itemCount: number;
}

interface IDeclarationBoxItemState extends IProcessingModel {
	boxSizes: { id: string, name: string }[]
}

class DeclarationBoxItem extends React.Component<IDeclarationBoxItemProps, IDeclarationBoxItemState> {
	static contextType = UserContext;

	constructor(prop: IDeclarationBoxItemProps) {
		super(prop);
		this.state = {
			error: undefined,
			isProcessing: false,
			boxSizes: []
		}
	}

	removeClick = (i: number, remove: UseFieldArrayRemove, getValues: UseFormGetValues<FormInputs>, setValue: UseFormSetValue<FormInputs>) => {
		remove(i);
	}

	render() {
		const { register,  getValues, setValue, formState: { errors }, remove } = this.props;

		return (
			<Card bg='Primary' className="border primary p-1 mb-2" >
				{/* <Card.Header>Informacja o pojemikach</Card.Header> */}
				<Card.Body className="declaration-box-item-card row ">
					<FloatingLabel label="Rodzaj odpadu" className='mb-3 col-xl-4 col-md-6 declaration-box-item-floating-label'>
						<Form.Select  {...register(`boxes.${this.props.itemNumber}.boxType`)} onChange={(evt)=>{
								// // evt.persist();
								this.setState( { boxSizes: boxTypes.find(x => x.id === evt.target.value)?.boxSizes ?? [] }); 
							}}>
							{
								boxTypes.map((bs, i) => 
										<option key={i} value={bs.id}>{bs.name}</option>
									)
							}
						</Form.Select>
						{errors.boxes?.[this.props.itemNumber]?.boxType && <p>{errors.boxes?.[this.props.itemNumber].boxType?.message}</p>}
					</FloatingLabel>


					<FloatingLabel label="Rozmiar pojemnika" className='mb-3 col-xl-5 col-md-6 declaration-box-item-floating-label'>
						<Form.Select  {...register(`boxes.${this.props.itemNumber}.boxSize`) } >
							{
								this.state.boxSizes.map((bs, i) => 
											<option key={i} value={bs.id}>{bs.name}</option>
									)
							}
						</Form.Select>
						{errors.boxes?.[this.props.itemNumber]?.boxSize && <p>{errors.boxes?.[this.props.itemNumber].boxSize?.message}</p>}
					</FloatingLabel>
					
					<FloatingLabel label="Liczba pojemników" className='mb-3 col-xl-2 col-md-6 declaration-box-item-floating-label '>		
						<input className="form-control" type="number" step="1" {...register(`boxes.${this.props.itemNumber}.boxAmount`)} />
						{errors.boxes?.[this.props.itemNumber]?.boxAmount && <p>{errors.boxes?.[this.props.itemNumber].boxAmount?.message}</p>}
					</FloatingLabel>
					
					<div className='col-xl-1 col-md-6 align-items-center'>
						{this.props.itemCount > 1 &&
							<Button  variant="primary" onClick={(ev) => this.removeClick(this.props.itemNumber, remove, getValues, setValue)}>Usuń</Button>
						}
					</div>
				</Card.Body>
		</Card>)
	}
}		

export default DeclarationBoxItem;

