import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import { IUserInfoProps, UserContext } from '../config/user-context';
import CustomerSection from './components/customers-section';
import { IPaymentDto } from '../models/generated';
import { dateHelper, IProcessingModel } from '../helpers';
import { paymentsService } from '../services/payments.service';
import moment from 'moment';
import { stringHelper } from '../helpers/string';

interface IPaymentsState extends IProcessingModel {
	toDate: number;
	fromDate: number;
	//selectedContractType: string;
	filteredItems: IPaymentDto[];
}

class Payments extends React.Component<IUserInfoProps, IPaymentsState> {
	static contextType = UserContext;
	
	constructor(props: any){
		super(props); 
		const now = new Date();
		this.state = {
			//selectedContractType: '',
			filteredItems: [],
			fromDate: new Date(now).setMonth(now.getMonth() - 6),
			toDate: now.getTime(),			
			
			isProcessing: false,
			error: undefined,
		};
	}

	getItems = async (customerId: number | undefined, force: boolean = false): Promise<IPaymentDto[] | undefined> => {
		return new Promise<IPaymentDto[] | undefined>((resolve, reject) => {

			if (customerId !== undefined) { 
				this.setState({ error: undefined, isProcessing: true });
				paymentsService.getPayments(customerId)
					.then((res) => {
						// var ci = this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [];
						// var updatedCi = ci.map(x => {
						// 	x.payments = res.filter(newItem => newItem.contractId === x.contract.id);
						// 	return x;
						// });

						this.context.setPayments(customerId, res);
						this.setState({ error: undefined, isProcessing: false,  
							filteredItems: this.getFilterItems(
									res,
									//this.state.selectedContractType, 
									this.state.fromDate, 
									this.state.toDate)
						});
						resolve(res);
					})
					.catch((err) => {
						this.setState({ error: err, isProcessing: false });
						console.error(err);
						reject(err);
					});
			} else {
				this.setState({ error: undefined, isProcessing: false });
				resolve(undefined);
			}	
		});	
    }

	componentDidMount() {
		console.log('componentDidMount');
		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined
			//&& (this.props.userInfo?.selectedCustomerInfo.contractInfos === undefined
			//|| this.props.userInfo?.selectedCustomerInfo.contractInfos.length === 0)
			) {
			//this.getContracts(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				//.then((contracts) => {
					this.getItems(this.props.userInfo?.selectedCustomerInfo?.customer?.id)//;
				//})
				.catch(err => {
					console.error(err);
				});
			
		}
	}

	componentDidUpdate(prevProps: IUserInfoProps) {
		if (prevProps?.userInfo?.selectedCustomerInfo?.customer.id !== this.props.userInfo?.selectedCustomerInfo?.customer.id) {
			//this.getContracts(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				//.then((contracts) => {
					//return 
				this.getItems(this.props.userInfo?.selectedCustomerInfo?.customer?.id)//;
				//})
				.then((documents) => {
					this.setState({ 
						filteredItems: this.getFilterItems(
							this.props.userInfo?.selectedCustomerInfo?.payments ?? [],
							//this.state.selectedContractType,
							this.state.fromDate, this.state.toDate) 
					});
				})
				.catch(err => {
					console.error(err);
				});
		}  else {
			if (prevProps.userInfo.selectedCustomerInfo?.wasPaymentsChanged !== this.props.userInfo.selectedCustomerInfo?.wasPaymentsChanged ) {
				this.setState({ 
					filteredItems: 
						 (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined) ?
							this.getFilterItems(
								this.props.userInfo?.selectedCustomerInfo?.payments ?? [],
									//this.state.selectedContractType, 
									this.state.fromDate, this.state.toDate) : []
				});
			}
		}
	}

	getFilterItems(payments: IPaymentDto[], //selectContractType: string, 
		fromDate: number, toDate: number): IPaymentDto[] {
		return payments
			//.filter(ci => (selectContractType === '' || ci.contract.contractType === selectContractType))
			//.map(di => di.payments
				.filter(d => 
					d.date !== undefined 
					&& moment(d.date).valueOf() >= fromDate 
					&& moment(d.date).valueOf() <= toDate )			
			.reduce((a, b) => a.concat(b), [] as IPaymentDto[]);
	}																  

	// change = (event: any) => {
	// 	this.setState({ selectedContractType: event.target.value ,
	// 		filteredItems: this.getFilterItems(
	// 			//this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [],
	// 			event.target.value, 
	// 			this.state.fromDate, 
	// 			this.state.toDate)
	// 		});
	// }

	fromDateChange = (e: any) => {
		this.setState({ fromDate: new Date(e.target.value).valueOf() ,
			filteredItems: this.getFilterItems(
				this.props.userInfo?.selectedCustomerInfo?.payments ?? [],
				//this.state.selectedContractType, 
				new Date(e.target.value).valueOf(), this.state.toDate)  
			});
	}

	toDateChange = (e: any) => {
		this.setState({ toDate: new Date(e.target.value).valueOf()  ,
			filteredItems: this.getFilterItems(
				this.props.userInfo?.selectedCustomerInfo?.payments ?? [],
				//this.state.selectedContractType, 
				this.state.fromDate, new Date(e.target.value).valueOf()) 
			}); 
	}

	render() {
		return (
			<div>
				<div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div>
				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true">Płatności</PanelHeader>
					<PanelBody>
					 {/*combobox filter*/}
					 <form className="row row-cols-lg-auto g-3 align-items-center">
							{/* <div className="mb-3">
								<label className="form-label">Umowa</label>
								<select className="form-select" 
										onChange={this.change}
										value={this.state.selectedContractType}>
									<option value="">Wszystkie</option>
									<option value="Water">Woda</option>
									<option value="Waste">Śmieci</option>
								</select>  
							</div> */}
							{/*from dateFilter*/}
							<div className="mb-3">
								<label className="form-label">Od</label> 
								<div className="row"> 
									<input 
										className="form-control" 
										type="date" 
										value={dateHelper.numberToDateFormat(this.state.fromDate)}	
										onChange={this.fromDateChange}
										/>
								</div> 
							</div>
							{/*to dateFilter*/}
							<div className="mb-3">
								<label className="form-label">Do</label>
								<input 
										className="form-control" 
										type="date" 
										value={dateHelper.numberToDateFormat(this.state.toDate)}	
										onChange={this.toDateChange}
										/>
							</div>
	
							</form> 
						   
						{/*desktop version*/}
						<div className="d-lg-block" >
							<div className="row standard-table standard-header">
								<div className="col-lg-2 col-sm-4">Data</div>
								<div className="col-lg-2 col-sm-4">Kwota</div>
								<div className="col-lg-2 col-sm-4">Zaksięgowano do</div> 
								</div>

							<div className="row">
								{this.state.filteredItems

									.map((c,idx) =>
										<div key={idx} className="row standard-table">
											<div className="col-lg-2 col-sm-4">{dateHelper.numberToDateFormat(c.date)}</div>
											<div className="col-lg-2 col-sm-4 currency">{stringHelper.currencyFormat(c.amount)}</div>
											<div className="col-lg-2 col-sm-4">{c.descripton}</div>
										</div>
									)}
							</div>
						</div>

						{/*mobile version*/}
						{/* <div className="d-block d-lg-none" > 
							<div className="row standard-table standard-header">
								<div className="col-sm-4">Data</div> 
								<div className="col-sm-4">Numer umowy<br />Adres</div>
								<div className="col-sm-4">Odłącz</div>
							</div>

							<div className="row">
								{this.state.filterContractInfos

									.map((c, idx) =>
										<div key={idx} className="row standard-table">
											<div className="col-sm-4">{c.contract?.contractDate}<br /> {c.contract?.contractType}</div> 
											<div className="col-sm-4">{c.contract?.contractNumber}<br />{c.addressService}</div> 
											<div className="col-sm-4"><i className="far fa-eye-slash"></i></div> 
										</div>
									)}
							</div>
						</div> */}
					</PanelBody>
				</Panel>
			</div>
		)
	}
}

export default Payments;  