import React from 'react';
import { AuthenticateResponse, IContractWithDetailsDto, ICustomerDto, IDocumentDto, IMeterWithMeterReadsDto, IPaymentDto, Role } from '../models/generated';

export interface IUserInfoProps {
    userInfo: IUserInfoDto
}

export interface IContractInfoDto {
    contractWithDetails: IContractWithDetailsDto,

    meterInfos: IMeterWithMeterReadsDto[],
}

export interface ICustomerInfoDto {
    customer: ICustomerDto,
    contractInfos: IContractInfoDto[], 

    documents: IDocumentDto[],
    payments: IPaymentDto[],
    
    wasContractsChanged: number,
    wasDocumentsChanged: number,
    wasPaymentsChanged: number,
    wasMeterReadsChanged: number,
}

export interface IUserInfoDto {
    userId: number,
    email: string,
    firstName: string,
    lastName: string,    
    role: Role,           
    isLoggedIn: boolean,
    
    customerInfos: ICustomerInfoDto[],
    selectedCustomerInfo: ICustomerInfoDto | undefined,
    electronicInvoiceAgreed: boolean
}

export interface IUserContextData {
    userInfo: IUserInfoDto,
    login: (authenticateResponse: AuthenticateResponse | null) => void,

    setCustomerInfos: (customerInfos: ICustomerInfoDto[]) => void,

    setCustomers: (customers: ICustomerDto[]) => void,

    setContracts: (customerId: number, contracts: IContractWithDetailsDto[]) => void,
    //addOrUpdateContracts: (customerId: number, contracts: IContractDto[]) => void,
    setSelectedCustomer: (customerId: number) => void ,

    setDocuments: (customerId: number, documents: IDocumentDto[]) => void,

    setPayments: (customerId: number, payments: IPaymentDto[]) => void,  

    setMeterWithMeterReads: (customerId: number, meterReads: IMeterWithMeterReadsDto[]) => void,     

    openAddCustomerModalDialog: () => void
}

export const UserInfoDtoDefault: IUserInfoDto = { 
        userId: 0,
        isLoggedIn: false, 
        email: '',
        firstName: '', 
        lastName: '',
        role: Role.User,
        customerInfos: [],
    selectedCustomerInfo: undefined,
    electronicInvoiceAgreed: false
 };

export const isLoggedIn = (userInfo?: IUserInfoDto): boolean => {
    //console.log('isLoggedIn: ' + (userInfo !== undefined && userInfo !== null && userInfo.isLoggedIn));
    return (userInfo !== undefined && userInfo !== null && userInfo.isLoggedIn);
}

export const UserContext = React.createContext<IUserContextData>({ 
    userInfo: UserInfoDtoDefault, 
    login: (authenticateResponse: AuthenticateResponse | null) => null,
    
    setCustomerInfos: (customerInfos: ICustomerInfoDto[]) => null, 
    setCustomers: (customers: ICustomerDto[]) => null,    
    setSelectedCustomer: (customerId: number) => null,
    
    setContracts: (customerId: number, contracts: IContractWithDetailsDto[]) => null,
    ///addOrUpdateContracts: (customerId: number, contracts: IContractDto[]) => null,

    setDocuments: (customerId: number, contracts: IDocumentDto[]) => null,
    setPayments: (customerId: number, contracts: IPaymentDto[]) => null,  
    setMeterWithMeterReads: (customerId: number, contracts: IMeterWithMeterReadsDto[]) => null,  
    openAddCustomerModalDialog: () => null,
});

