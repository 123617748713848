import { fetchWrapper } from "../helpers";
import { IFormSubjectDto, IFormTemplateDto } from "../models/generated";
import { getConfiguration } from "./config";

export const applicationsAndComplainsService = {
  getFormSubjects,
  getFormTemplates,
  sendApplicationAndComplainForm
};

async function getFormSubjects(): Promise<IFormSubjectDto[]> {        
    const resp = await fetchWrapper.get(getConfiguration().apiUrl + '/applicationsAndComplains/getFormSubjects');
    const res: IFormSubjectDto[] = await resp;
    return res;
}

async function getFormTemplates(formSubjectId: number): Promise<IFormTemplateDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/applicationsAndComplains/getFormTemplates/${formSubjectId}`);
  const res: IFormTemplateDto[] = await resp;
  return res;
}

async function sendApplicationAndComplainForm(formSubjectId: number, values: FormData, customerId?: number): Promise<boolean> {        
  const customerIdUri = (customerId) ? `/${customerId}` : ``;
    const resp = await fetchWrapper.postFiles(getConfiguration().apiUrl + `/applicationsAndComplains/submitForm/${formSubjectId}${customerIdUri}`, values);
    return resp.ok;
}