import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AppContext } from '../../config/app-settings';
import { isLoggedIn, IUserContextData, UserContext } from '../../config/user-context';
import { accountService } from '../../services/account.service';
import { UseFormReturn } from "react-hook-form";
import { from, Subscription, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { alertService } from '../../services/alert.service';
import { withUseFormHook } from '../../helpers';

interface ILoginState {
	redirect: boolean,
	loading: boolean,
	message: string
}
interface ILoginProps extends UseFormReturn<FormInputs, any> {
	userContext: IUserContextData
}

type FormInputs = {
	email: string,
	password: string,
};

class Login extends React.Component<ILoginProps, ILoginState> {
	static contextType = AppContext;
	
	loginSubscription: Subscription | undefined;
	
	constructor(props: any) {
		super(props);
		var shouldRedirect = accountService.userValue?.isVerified === true;
		console.log('Login, shouldRedirect: ' + shouldRedirect)
		this.state = {
			redirect: shouldRedirect,
			loading: false,
			message: ""
		}
	}
	
	componentDidMount() {
		console.log('componentDidMount');
		
	}

	componentWillUnmount() {
		if (this.loginSubscription) {
			this.loginSubscription.unsubscribe();
		}
	}

    onSubmit = (data: FormInputs) => {		
		if (this.loginSubscription) {
			this.loginSubscription.unsubscribe();
		}
		this.loginSubscription = from(accountService.login(data.email, data.password))
		.pipe(
			map(x => {
				if (x && x.id && x.email && x.firstName && x.lastName) {
					this.props.userContext.login(x);
				} else {
					this.props.userContext.login(null);
					return throwError('Login failed.');
				}
			}),
			tap(x => {
				this.setState(state => ({
					redirect: true
				}));
			})
		)
		.subscribe({
			next: val => { console.log(`Login Success: ${val}`); },
			complete: () => console.log('Login Complete!'),
			error: val => { console.log(`Login Error: ${val}`); alertService.error(`Logowanie`, `${val}`, { autoClose: true }); } 
		});	
  }
  
	render() {
		const { register, handleSubmit, formState: { errors } } = this.props;
		if (this.state.redirect) {
			return <Redirect to='/'/>;
	 	}
		return (
			<UserContext.Consumer>
			{(userContext) => { 
				if (isLoggedIn(userContext.userInfo)) {
					return (<Redirect to='/'/>)	
				}
				else {
					return (
						<div className="login login-with-news-feed">
							<div className="login-container">
								<div className="login-content">
									<div>
										<strong>
										Witamy<br />
										w Elektronicznym Biurze Obsługi Klienta<br />
										Zakładu Gospodarki Komunalnej<br/>
										w Szamotułach<br/>
										</strong>
										<br/>
									</div>
									<form onSubmit={handleSubmit(this.onSubmit)} className="fs-13px">
										<div className="form-floating mb-15px">
											<input type="text" className="form-control h-45px fs-13px" placeholder="Email Adres" id="emailAddress" 
												{...register("email", { required: "Email adres jest wymagany", pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: "Niepoprawny format email"
												}})}/>
											<label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Adres</label>
											{errors.email && <p>{errors.email.message}</p>}
										</div>
										<div className="form-floating mb-15px">
											<input type="password" className="form-control h-45px fs-13px" placeholder="Hasło" id="password"
												{...register("password", { required: "Hasło jest wymagane" })} />
											<label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Hasło</label>
											{errors.password && <p>{errors.password.message}</p>}
										</div>
										<div className="mb-15px">
											<button type="submit" className="btn btn-success d-block h-45px w-100 btn-lg fs-14px">Zaloguj się</button>
										</div>
										<div className="pb-10px text-inverse">
											Zapomniałeś hasła? <Link to="/forgot-password" className="text-primary">Zresetuj hasło</Link>.
										</div>
										<div className="mb-20px pb-20px text-inverse">
											Nie masz konta? <Link to="/register" className="text-primary">Zarejestruj się</Link>.
										</div>
										
									</form>
								</div>
							</div>
						</div>
					)
				}
			}}
			</UserContext.Consumer>
		)
	}
}

export default withUseFormHook(Login);
