
export const stringHelper = {
  currencyFormat,
}

function currencyFormat(value: number | undefined | null, onUndefinde: string | undefined = undefined) : string {
  if (value !== undefined && value !== null) {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN'
    }).format(value);
  }
  return onUndefinde ?? '---';
}

