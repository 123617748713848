import { Role } from "../models/generated";

export const roleHelper = {
    compareRole,
}

function compareRole(userRole: string, minRole: string | undefined): boolean {
    if (minRole === undefined) {
        return true;
    }
    if (minRole === Role.User) {
        return true;
    }
    if (minRole === Role.Admin) {
        const res = userRole === Role.Admin;
        return res;
    }
    return false;
} 