import React from 'react';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';  
import { IContractInfoDto, IUserInfoProps, UserContext } from '../config/user-context';
import { dateHelper, IProcessingModel } from '../helpers';
import { addressHelper } from '../helpers/address';
import { IMeterDto, IMeterReadDto, IMeterWithMeterReadsDto } from '../models/generated';
import { metersService } from '../services/meters.service';
import CustomerSection from './components/customers-section';
import { ContractRelatedComponentBase } from './contract-related-base';

interface IMeterReadingState extends IProcessingModel {
	meters: IMeterDto[],
	selectedMeterId: number | undefined; 	
	filteredItems: IMeterReadDto[];
}

class MeterReading extends ContractRelatedComponentBase<IUserInfoProps, IMeterReadingState> {
	static contextType = UserContext;
	
	constructor(props: any){
		super(props); 
		this.state = {
			meters: [],
			selectedMeterId: undefined,
			filteredItems: [],
			isProcessing: false,
			error: undefined
		};
	}

	getItems = async (customerId: number | undefined, force: boolean = false): Promise<IMeterWithMeterReadsDto[] | undefined> => {
		return new Promise<IMeterWithMeterReadsDto[] | undefined>((resolve, reject) => {

			if (customerId !== undefined) { 
				this.setState({ error: undefined, isProcessing: true });
				metersService.getMetersWithMeterReads(customerId)
					.then((res) => {
						var ci = this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [];
						
						this.context.setMeterWithMeterReads(customerId, res);

						this.setMeterAndSelectedMeterInStatus(ci, res, this.state.selectedMeterId);

						resolve(res);
					})
					.catch((err) => {
						this.setState({ error: err, isProcessing: false });
						console.error(err);
						reject(err);
					});
			} else {
				this.setState({ error: undefined, isProcessing: false });
				resolve(undefined);
			}	
		});	
    }

	private setMeterAndSelectedMeterInStatus(contractInfos: IContractInfoDto[], res: IMeterWithMeterReadsDto[], selected: number | undefined) {

		var updatedCi = contractInfos.map(x => {
			x.meterInfos = res.filter(newItem => newItem.meter?.contractId === x.contractWithDetails.contract?.id);
			return x;
		});

		var meters: IMeterDto[] = res.map(x => x.meter).filter(x => x !== undefined) as IMeterDto[] ?? [];

		var selectedMeterId = ((selected) &&
			meters.find(x => x?.id === selected)) ?
			selected
			:
			((meters.length > 0) ? meters[0]?.id : undefined);

		this.setState({
			error: undefined, isProcessing: false,
			selectedMeterId: selectedMeterId,
			meters: meters,
			filteredItems: this.getFilterItems(
				updatedCi,
				selectedMeterId)
		});
	}

	componentDidMount() {
		console.log('componentDidMount');
		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined
			//&& (this.props.userInfo?.selectedCustomerInfo.contractInfos === undefined
			//|| this.props.userInfo?.selectedCustomerInfo.contractInfos.length === 0)
			) {
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				.then((contracts) => {
					this.getItems(this.props.userInfo?.selectedCustomerInfo?.customer?.id);
				})
				.catch(err => {
					console.error(err);
				});
			
		}
	}

	componentDidUpdate(prevProps: IUserInfoProps) {
		if (prevProps?.userInfo?.selectedCustomerInfo?.customer.id !== this.props.userInfo?.selectedCustomerInfo?.customer.id) {
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				.then((contracts) => {
					return this.getItems(this.props.userInfo?.selectedCustomerInfo?.customer?.id);
				})
				// .then((meterWithMeterReads) => {
				// 	this.setState({ 
				// 		filteredItems: this.getFilterItems(
				// 			this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [],
				// 			this.state.selectedMeterId) 
				// 	});
				// })
				.catch(err => {
					console.error(err);
				});
		}  else {
			if (prevProps.userInfo.selectedCustomerInfo?.wasPaymentsChanged !== this.props.userInfo.selectedCustomerInfo?.wasPaymentsChanged ) {
				// this.setState({ 
				// 	filteredItems: 
				// 		 (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined) ?
				// 			this.getFilterItems(
				// 				this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [],
				// 					this.state.selectedMeterId) : []
				// });
				var ci = this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [];
				var res = ci.map(x => x.meterInfos)
					.reduce((a, b) => a.concat(b), []);					
				this.setMeterAndSelectedMeterInStatus(ci, res, this.state.selectedMeterId);
			}
		}
	}
	getFilterItems(contractInfos: IContractInfoDto[], selectedMeterId: number | undefined): IMeterReadDto[] {
		if (selectedMeterId === undefined )
		{
			return [];
		}
		return contractInfos
			.map(ci => ci.meterInfos ?? [])
			.reduce(function(a: IMeterWithMeterReadsDto[], b: IMeterWithMeterReadsDto[]){ return a!.concat(b); }, [])			
			.find(x =>  x.meter?.id !== undefined && x.meter!.id === selectedMeterId!)
			?.meterReads ?? [];
	}																  

	change = (event: any) => {
		// this.setState({ selectedMeterId: event.target.value ,
		// 	filteredItems: this.getFilterItems(
		// 		this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [],
		// 		event.target.value)
		// 	});]
		var ci = this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [];
		var res = ci.map(x => x.meterInfos)
			.reduce((a, b) => a.concat(b), []);					
		this.setMeterAndSelectedMeterInStatus(ci, res, +event.target.value);
	}

	render() {   

		return (

			<div>
				<div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div>
				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true">Stan liczników</PanelHeader>
					<PanelBody>
					<form className="row row-cols-lg-auto g-3 align-items-center">
						<div className="mb-3">
									<label className="form-label">Punkt poboru</label>
							<select id='meterCombobox' 
									className="form-select"
									onChange={this.change}
									value={this.state.selectedMeterId}>
								{this.state.meters.map((meter) => 
												<option key={meter.id}
														value={meter.id}>
														{meter.number} - {addressHelper.format(meter)}
												</option>)}
							</select>
						</div>
					</form>

						{/*desktop version*/}
						<div className="d-none d-lg-block" >
							<div className="row standard-table standard-header"> 
								<div className="col-lg-2">Data odczytu</div>
								<div className="col-lg-2">Stan licznika</div>
								<div className="col-lg-2">Zużycie</div> 
								</div>

							<div className="row">
								{this.state.filteredItems
									.map((c, idx) => 
										<div key={idx} className="row standard-table">
											<div className="col-lg-2">{dateHelper.numberToDateFormat(c.readDate)}</div>
											<div className="col-lg-2 to-right">{c.value}</div>
											<div className="col-lg-2 to-right">{c.consumption}</div> 
										</div>
									)}
							</div>
						</div>
					</PanelBody> 
				</Panel> 
			</div>
		)
	} 
}

export default MeterReading;