import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import { IUserInfoProps, UserContext } from '../config/user-context';
import { IProcessingModel, withUseFormHookWithFieldArray, dateHelper } from '../helpers';
import { UseFieldArrayAppend, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';
import CustomerSection from './components/customers-section';
import { ContractRelatedComponentBase } from './contract-related-base';
import { ContractTypeEnum } from '../models/generated';
import { Form, FloatingLabel } from 'react-bootstrap';

import DeclarationBoxItem, { IDeclarationsProps, FormInputs } from './components/declaration-box-item'
import { declarationsService } from '../services/declarations.service';

interface IDeclarationsState extends IProcessingModel {
	formWasSend: boolean
}


class Declarations extends ContractRelatedComponentBase<IDeclarationsProps, IDeclarationsState> {
	static contextType = UserContext;

	constructor(props: IDeclarationsProps) {
		super(props);
		this.state = { 
			error: undefined, 
			isProcessing: false,
			formWasSend: false		 
		};
	}
	
	componentDidMount() {
		console.log('componentDidMount');
		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined) {
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
			.catch(err => {
				console.error(err);
			});
		}
	}

	componentDidUpdate(prevProps: IUserInfoProps) {
		if (prevProps?.userInfo?.selectedCustomerInfo?.customer.id !== this.props.userInfo?.selectedCustomerInfo?.customer.id) {
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
			.catch(err => {
				console.error(err);
			});
		} 
	}
	  
	addClick = (append: UseFieldArrayAppend<FormInputs, "boxes">, getValues: UseFormGetValues<FormInputs>, setValue: UseFormSetValue<FormInputs>) => {
		append({ boxSize: undefined, boxType: undefined, boxAmount: 0 });
	}
	  
	handleAfterSending = (ev: any, reset: UseFormReset<FormInputs>) => {
		reset();
		this.setState({ formWasSend: false });
	}

	onSubmit = (data: FormInputs) => {
		//alert('A name was submitted: ' + JSON.stringify(data));

		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined && data.contractId !== undefined) {
			this.setState(preState => ({...preState,  isProcessing: true, error: undefined }));
			var content = JSON.stringify(data.boxes);

			declarationsService.submitDeclaration(this.props.userInfo?.selectedCustomerInfo?.customer?.id, data.contractId, content)
				.then((res) => {
					this.setState(prev => ({...prev, isProcessing: false, formWasSend: true }));
				})
				.catch((err) => {
					this.setState(preState => ({...preState, isProcessing: false, error: err }))
				});
		}
	}

	render() {
		const { register,  handleSubmit, getValues, setValue, formState: { errors, isValid }, fields, append, reset } = this.props;

		return (
			<div>
				<div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div>
				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true">Deklaracja zmieniająca - nieruchomości niezamieszkałe</PanelHeader>
					<PanelBody>
					{
					(this.state.formWasSend) ?
						<div className="row">
							<span>Twoja wiadomość została wysłana.</span>
							<button className="btn btn-primary w-100px me-5px" onClick={(evt) => this.handleAfterSending(evt, reset)}>OK</button>
						</div>
						:							
						<form onSubmit={handleSubmit(this.onSubmit)}>
							<div>
							Składana deklaracja dotyczy następnego miesiąca pod warunkiem, że zostanie przesłana nie później niż 7 dni przed końcem bieżącego miesiąca.
							Niniejsza deklaracja bedzie obowiązywała od:
							<strong>{dateHelper.getMonthNameNum(dateHelper.getNextDeclarationMonth())}</strong>
							</div>
							{/* <input name="customerId" type="hidden"  value={this.props.userInfo?.selectedCustomerInfo?.customer?.id || ''} /> */}
							<FloatingLabel label="Umowa" className='mb-3'>
								<Form.Select {...register("contractId") } aria-label="Default select example">
									<option key={-1} value="" >---</option>
										{
											(this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [])
												.map(x => x.contractWithDetails.contract)
												.filter(x => x !== undefined && x.contractType === ContractTypeEnum.Waste)
													.map((contract, i) => 
														<option key={i} value={contract!.id}>{contract!.contractNumber}</option>
													)
										}
								</Form.Select>
							</FloatingLabel>
							{errors.contractId && <p>{errors.contractId.message}</p>}
							
							{fields.map((field, i) =>  {
								return <DeclarationBoxItem key={i} {...this.props} itemNumber={i} itemCount={fields.length} />;
								}
							)}   
							
							{/* {(errors.boxes as any)?.message && <p>{JSON.stringify((errors.boxes as any).message)}</p>}      */}
							<button disabled={isValid === undefined || isValid === false} className="btn btn-primary d-block h-45px fs-13px mb-3" onClick={(ev) => this.addClick(append, getValues, setValue)}>Dodaj nowy rodzaj pojemnika</button>
							
							<div >
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Wyślij</button>
							</div>
						</form>
					}
					</PanelBody>
				</Panel>
			</div>
		)
	}
} 

const formSchema = Yup.object().shape({
	contractId: Yup.number()
		.required('Wybór umowy jest wymagany')
		.typeError('Wybór umowy jest wymagany'),
	boxes: Yup.array().of(
		Yup.object().shape({
			boxSize: Yup.string()
				.required('Wybierz pojemnik'),
			boxType: Yup.string()
				.required('Wybierz rodzaj odpadu'),
			boxAmount: Yup.number()
				.integer('Określ 	liczbę pojemników')
				.typeError('Określ liczbę pojemników')
				.min(1, 'Liczba pojemników musi być większa od 0')
				.required('Określ liczbę pojemników')
		})
	)
})
const formOptions = { resolver: yupResolver(formSchema), defaultValues: { contractId: undefined, boxes: [{ boxAmount: 0 }] }  }

export default withUseFormHookWithFieldArray(Declarations, "boxes", {mode: 'onChange', reValidateMode: 'onChange', ...formOptions});

