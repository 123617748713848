export interface IConfiguration {
    apiUrl: string;
    baseUrl: string;
}

const serverVars: IConfiguration = {
    baseUrl: 'https://ebok.zgkszamotuly.pl/',
    apiUrl: 'https://ebok.zgkszamotuly.pl/api/1.0'
};

const localVars: IConfiguration = {
    baseUrl: 'http://localhost:5900',
    apiUrl: 'http://localhost:5900/api/1.0'
};

export function getConfiguration() {
    if (process.env.NODE_ENV === 'production') {
        return serverVars;
    }

    return localVars;
}
