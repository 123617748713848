import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import moment from 'moment';
import { ContractTypeEnum, IContractWithDetailsDto } from '../models/generated';
import CustomerSection from './components/customers-section';
import { IUserInfoProps, UserContext } from '../config/user-context';
import CustomerCard from './components/customer-card';
import { IProcessingModel } from '../helpers';
import { contractsService } from '../services/contracts.service';
import { alertService } from '../services/alert.service';
import { ContractRelatedComponentBase } from './contract-related-base';
import ContractDetails from './components/contract-details';
import { addressHelper } from '../helpers/address';
import SweetAlert from 'react-bootstrap-sweetalert';

interface IContractsState extends IProcessingModel  {
	isSynch: boolean
	selectedContractType: string;
	filteredItems: IContractWithDetailsDto[];
	selectedRow: number | undefined;

	showDeattacheConfirmation: boolean,
	deattachingContractId: number | undefined
}

class Contracts extends ContractRelatedComponentBase<IUserInfoProps, IContractsState> {
	static contextType = UserContext;
	
	constructor(props: any){
		super(props); 
		this.state = {
			selectedContractType: '',
			filteredItems: [],

			isProcessing: false,
			error: undefined,
			selectedRow: undefined,

			isSynch: false,

			showDeattacheConfirmation: false,
			deattachingContractId: undefined
		};
	}

	componentDidMount() {
		console.log('componentDidMount');
		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined
		   ) {
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
			.then((contracts) => {
				this.setState({ 
					filteredItems: this.getFilterItems(this.state.selectedContractType) });
			})
			.catch(err => {
				console.error(err);
			});
		}
	}

	componentDidUpdate(prevProps: IUserInfoProps) {
		if (prevProps?.userInfo?.selectedCustomerInfo?.customer.id !== this.props.userInfo?.selectedCustomerInfo?.customer.id) {
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
			.then((contracts) => {
				this.setState({ 
					filteredItems: this.getFilterItems(this.state.selectedContractType) });
			})
			.catch(err => {
				console.error(err);
			});
		} else {
				if (prevProps.userInfo.selectedCustomerInfo?.wasContractsChanged !== this.props.userInfo.selectedCustomerInfo?.wasContractsChanged ) {
					this.setState({ 
						filteredItems: 
						 	(this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined) ?
								this.getFilterItems(this.state.selectedContractType) : []
					});
				}
		}
	}

	getFilterItems(selectContractType: string
	): IContractWithDetailsDto[] {
		var contractInfos = this.props.userInfo?.selectedCustomerInfo?.contractInfos ?? [];
		var contractsWithDetails = contractInfos.map(x => x.contractWithDetails);
		var result = contractsWithDetails.filter(x => (selectContractType === '' || x.contract?.contractType === selectContractType)
				);
		return result;
	}

	change = (event: any) => {
		this.setState({ selectedContractType: event.target.value, 
			filteredItems: this.getFilterItems(event.target.value)
		});
	}

	deattache = (evt: any, id: number): void => {
		evt.preventDefault();
		evt.stopPropagation();
		this.setState(prev => ({ ...prev, showDeattacheConfirmation: true, deattachingContractId: id  }))
	} 

	deattacheConfirmed = (evt: any) : void => {
		console.log('deattacheConfirmed');

		var id = this.state.deattachingContractId;

		if (id !== undefined) {
			this.setState({  error: undefined, isProcessing: true });
			contractsService.deactivateContract(id)
				.then(x => {
					alertService.info('Odłączenie umowy', `Umowa ${x.contractNumber} została odłączona`, { autoClose: true });

					var newList = this.props.userInfo?.selectedCustomerInfo?.contractInfos
						.filter(x => x.contractWithDetails.contract?.id !== id)
						.map(x => x.contractWithDetails);

					this.context.setContracts(this.props.userInfo?.selectedCustomerInfo?.customer?.id, newList);				
					this.setState({  error: undefined, isProcessing: false, showDeattacheConfirmation: false, deattachingContractId: undefined });	
				})
				.catch(err => {
					alertService.error('Odłączenie umowy', `Odłączenie umowy nie powiodło się.`, { autoClose: true });
					this.setState({  error: err, isProcessing: false, showDeattacheConfirmation: false, deattachingContractId: undefined });	
				});
		}
	}

	deattacheCanceled = () => {
		this.setState(prev => ({ ...prev, showDeattacheConfirmation: false, deattachingContractId: undefined  }))
	}

	selectRow = (id: number): void => {
		if (this.state.selectedRow === id) {
			this.setState({ selectedRow: undefined });
		} else {
			this.setState({ selectedRow: id });
		}
		console.log('selectRow');
	} 

	openAddCustomer = () => {
		this.context.openAddCustomerModalDialog();
	};

	render() {
		return (
			<div>
				<div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div>

				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true"><div><span>Umowy</span>
						<span style={{ float: 'right' }}>
							<button  className="btn btn-purple " onClick={this.openAddCustomer}>
							{/* <i  className="far ion-md-add"></i> */}
							Dodaj klienta...
							</button></span></div></PanelHeader>
					<PanelBody>
						{this.props.userInfo.isLoggedIn && this.props.userInfo.selectedCustomerInfo?.customer &&
							<CustomerCard displayRemoveButton={true} userContextData={this.context} />
						}
						<div>
							{
								<span>
									Numer rachunku bankowego do opłat jest widoczny na fakturze.
								</span>
							}
						</div>
						<SweetAlert
							show={this.state.showDeattacheConfirmation}
							title="Odłączenie umowy"
							onConfirm={(evt) => this.deattacheConfirmed(evt)}
							onCancel={() => this.deattacheCanceled()}
							dependencies={[ this.state.deattachingContractId ]}											
							showCancel={true}
							focusCancelBtn={true}
							confirmBtnBsStyle="danger"
							cancelBtnBsStyle="primary"
						>
							Umowa zostanie usunięta z Twojego konta i utracisz do niej dostęp w EBOK.<br/>
							Czy na pewno chcesz odłączyć tą umowę?
						</SweetAlert>

						{/*combobox filter*/}
						<form className="row row-cols-lg-auto g-3 align-items-center">
							<div className="mb-3">
								<label className="form-label">Umowa</label>
								<select className="form-select" 
										onChange={this.change}
										value={this.state.selectedContractType}>
									<option value="">Wszystkie</option>
									<option value="Water">Woda</option>
									<option value="Waste">Śmieci</option>
								</select>  
							</div>
					
						</form> 
						   
						{/*desktop version*/}
						<div className="d-none d-lg-block" >
							<div className="row standard-table standard-header">
								<div className="col-lg-2">Data rozpoczęcia</div>
								<div className="col-lg-2">Typ umowy</div>
								{/* contractNumber */}
								<div className="col-lg-2">Numer umowy</div> 
								<div className="col-lg-4">Adres</div>
								<div className="col-lg-2">Odłącz</div>
							</div>

							<div className="row">
								{this.state.filteredItems
									.filter(x => x.contract?.id !== undefined)
									.map((c, idx) => 
										<div key={idx} className="row standard-table"  >
																					
											<div onClick={(evt) => this.selectRow(c.contract!.id!)} className="row ">
												<div className="col-lg-2">
													<i className="cursor-pointer far ion-md-arrow-dropdown me-4"></i>
													{moment(c.contract!.contractDate).format('YYYY-MM-DD')}
												</div>
												<div className="col-lg-2">{c.contract!.contractType === ContractTypeEnum.Water ? <i className="ion ion-md-water"></i> : <i className="ion ion-md-trash"></i>}</div>
												<div className="col-lg-2">{c.contract!.contractNumber}</div>
												<div className="col-lg-4">{addressHelper.format(c.contract!)}</div>
												<div className="col-lg-2"> <i onClick={(evt) => { this.deattache(evt, c.contract!.id!); return false; }} className="cursor-pointer far fa-eye-slash"></i> </div>
											</div>
											{
												this.state.selectedRow && this.state.selectedRow === c.contract!.id &&
												<ContractDetails contractDetails={c} className="contract-details"></ContractDetails>
											}
										</div>
									
									)}
							</div>
						</div>

						{/*mobile version*/}
						<div className="d-block d-lg-none" > 
							<div className="row standard-table standard-header">
								<div className="col-sm-4">Data rozpoczęcia<br />Typ umowy </div> 
								<div className="col-sm-4">Numer umowy<br />Adres</div>
								<div className="col-sm-4">Odłącz</div>
							</div>

							<div className="row">
								{this.state.filteredItems
									.filter(x => x.contract?.id !== undefined)
									.map((c, idx) =>
									<div key={idx} className="row standard-table" >
										<div onClick={(evt) => this.selectRow(c.contract!.id!)} className="row ">
											<div className="col-sm-4">{moment(c.contract!.contractDate).format('YYYY-MM-DD')}<br />{c.contract!.contractType === ContractTypeEnum.Water ? <i className="ion ion-md-water"></i> : <i className="ion ion-md-trash"></i>}</div> 
											<div className="col-sm-4">{c.contract!.contractNumber}<br />{addressHelper.format(c.contract!)}</div> 
											<div className="col-sm-4"><i onClick={(evt) => { this.deattache(evt, c.contract!.id!); return false; }} className="cursor-pointer far fa-eye-slash"></i></div> 
										</div>
										{
											this.state.selectedRow && this.state.selectedRow === c.contract!.id &&  
											<ContractDetails contractDetails={c} className="contract-details"></ContractDetails>
										}
									</div>
									)}
							</div>
						</div>
					</PanelBody>
				</Panel>
			</div>
		)
	}
} 

export default Contracts;
