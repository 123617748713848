import moment from 'moment';
import React from 'react';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import { IUserInfoProps } from '../config/user-context';
import { IProcessingModel } from '../helpers';
import { IMessageDto } from '../models/generated';
import { alertService } from '../services/alert.service';
import { messagesService } from '../services/messages.service';
import MessageEditor from './components/message-editor';
import { IModalDialogProps, ModalDialogMode } from '../models/models';

interface IMessageManagerState extends IModalDialogProps, IProcessingModel {
	messages: IMessageDto[],
	onlyActive: boolean,
	messageToManage: IMessageDto | undefined
}

class MessageManager extends React.Component<IUserInfoProps, IMessageManagerState> {
	
	
	/**
	 *
	 */
	constructor(props: any) {
		super(props);
		this.state = {
			isProcessing: false,
			error: undefined,
			messages: [],
			onlyActive: false,

			isModalDialogOpened: false,
			modalDialogMode: ModalDialogMode.Add,
			closeModalDialog: this.closeMessageEditorModalDialog,
			messageToManage: undefined
		}
	}

	componentDidMount() {
		this.getMessages(0, false);
	}


	getMessages(skip: number, onlyActive: boolean) {
		this.setState({ isProcessing: true, error: undefined })
		from(messagesService.getMessages(onlyActive, 0))
			.pipe(
				first()
			)
			.subscribe({
				next: val => {
					this.setState({ isProcessing: false, error: undefined, messages: val })
				},
				complete: () => {
					this.setState({ isProcessing: true, error: undefined })
				},
				error: err => {
					this.setState({ isProcessing: true, error: err, messages: [] })
				}
			});
			
	}
	change = (event: any) => {
		var newValue = !this.state.onlyActive;
		this.setState({ onlyActive: newValue});
		this.getMessages(0, newValue); 
	}

	openAddMessage = (e: any) => {
		this.setState({ 
			isModalDialogOpened: true,
			messageToManage: {
				subject: '',
				contentText: ''
			},
			modalDialogMode: ModalDialogMode.Add
		});
	};

	closeMessageEditorModalDialog = (exitCode: number | undefined) => {
		this.setState({ 
			isModalDialogOpened: false
		});
		this.getMessages(0, this.state.onlyActive);
	}

	openEditMessage = (c: IMessageDto) => {

		this.setState({ 
			isModalDialogOpened: true,
			messageToManage: c,
			modalDialogMode: ModalDialogMode.Edit			
		});
	}

	changeMessageActivity = (c: IMessageDto) => {
		var changeToActive = c.deactivationDate !== undefined && c.deactivationDate !== null;
		c.deactivationDate = (changeToActive ? undefined : new Date());

		this.setState((prevState) => ({ isProcessing: true }));	
		from(messagesService.updateMessage(c))			 
		.pipe(first())
		.subscribe({
			next: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	

				console.log( `ChangeActivityMessage: ${val.id}, ${val.deactivationDate}!`);
				alertService.info(`${changeToActive ? 'Aktywowanie' : 'Deaktywowanie'} wiadomości powiodło się`, `Wiadomość${changeToActive ? '' : ' nie'} będzie widoczna dla użytkowników.`, { autoClose: true }); 				
			},
			complete: () => {
				this.setState((prevState) => ({ isProcessing: false }));	
				console.log('ChangeActivityMessage Complete!' );
			},
			error: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	
				console.log(`ChangeActivityMessage Error: ${val}!`);
				alertService.error(`${changeToActive ? 'Aktywowanie' : 'Deaktywowanie'} wiadomości nie powiodło się`, `${val}`, { autoClose: true }); 
			} 
		});	
	}

	render() {
		return (
			<div>
				{/* <h1 className="page-header col-md-4">Zarządzanie wiadomościami</h1> */}
				<Panel>
					<PanelHeader noButton="true">Zarządzanie wiadomościami</PanelHeader>
					<PanelBody>
					<MessageEditor {...this.state} />
					 {/*combobox filter*/}
					 <div  className="row row-cols-lg-auto g-3 align-items-center mb-3">
						<form>
								<div >
									<label className="form-label">Tylko aktywne</label>
									<input   type="checkbox"
											onChange={this.change}
											checked={this.state.onlyActive}>
									</input>  
								</div>
								
		
							</form> 
							<div >
								<button className='btn btn-purple'
										onClick={this.openAddMessage}>
											Dodaj wiadomość...
								</button>  
							</div>
						</div>
						{/*desktop version*/}
						<div className="d-none d-lg-block" >
							<div className="row standard-table standard-header">
								<div className="col-lg-2">Data publikacji</div>
								<div className="col-lg-3">Temat</div>
								<div className="col-lg-5">Treść</div> 
								<div className="col-lg-2"></div>
								</div>

							<div className="row">
								{this.state.messages
									.map((c,idx) =>
										<div key={idx} className="row standard-table">
											<div className="col-lg-2">{moment(c.publicationDate).format('YYYY-MM-DD')}</div>
											<div className="col-lg-3">{c.subject}</div>
											<div className="col-lg-5">{c.contentText}</div>
											<div className="col-lg-2"> 
												<button onClick={() => this.openEditMessage(c)}  className='btn btn-purple'><i className="far fa-file"></i></button>
												<button onClick={() => this.changeMessageActivity(c)} className='btn btn-purple ms-3'>
													{c.deactivationDate === undefined || c.deactivationDate === null ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i> }</button>
											</div>
										</div>
									)}
							</div>
						</div>

						{/*mobile version*/}
						<div className="d-block d-lg-none" > 
							<div className="row standard-table standard-header">
								<div className="col-sm-4">Data rozpoczęcia<br />Typ umowy </div> 
								<div className="col-sm-4">Numer umowy<br />Adres</div>
								<div className="col-sm-4">Odłącz</div>
							</div>

							<div className="row">
								{this.state.messages
									.map((c, idx) =>
										<div key={idx} className="row standard-table">
											<div className="col-sm-4">{moment(c.publicationDate).format('YYYY-MM-DD')}</div> 
											<div className="col-sm-4">{c.subject}</div> 
											<div className="col-sm-4">
												<button onClick={() => this.openEditMessage(c)} className='btn btn-purple'><i className="far fa-file"></i></button>
												<button onClick={() => this.changeMessageActivity(c)} className='btn btn-purple ms-3'>
													{c.deactivationDate === undefined || c.deactivationDate === null ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i> }</button>
											</div> 
										</div>
									)}
							</div>
						</div>
					</PanelBody>
				</Panel>
				<Panel>
					<PanelHeader noButton="true">Synchroinzacja</PanelHeader>
					<PanelBody>
						<a href="/api/admin/synchronizeAll" target="_blank">Synchronizuj dane</a>
					</PanelBody>
				</Panel>
			</div>
		)
	}
	
}

export default MessageManager;  
