import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import moment from 'moment';
import CustomerSection from './components/customers-section';
import { IUserInfoProps, UserContext } from '../config/user-context';
import { documentsService } from '../services/documents.service';
import { dateHelper, IProcessingModel } from '../helpers';
import { DocumentTypeEnum, IDocumentDto } from '../models/generated';
import React from 'react';
import { alertService } from '../services/alert.service';
import { stringHelper } from '../helpers/string';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Redirect } from 'react-router-dom';

interface IInvoicesState extends IProcessingModel {
	toDate: number;
	fromDate: number;
	//selectedContractType: string;
	filteredItems: IDocumentDto[];

	showRequestForAcceptDownloadingInvoice: boolean,
	redirectToForm: boolean
}

class Invoices extends React.Component<IUserInfoProps, IInvoicesState> { 
	static contextType = UserContext;

	constructor(props: any){
		super(props); 
		const now = new Date();
		this.state = {
			//selectedContractType: '',
			filteredItems: [],
			fromDate: new Date(now).setMonth(now.getMonth() - 6),
			toDate: now.getTime(),
			isProcessing: false,
			error: undefined,
			showRequestForAcceptDownloadingInvoice: false,
			redirectToForm: false
		};
	}

	getItems = async (customerId: number | undefined, force: boolean = false): Promise<IDocumentDto[] | undefined> => {
		return new Promise<IDocumentDto[] | undefined>((resolve, reject) => {

			if (customerId !== undefined) { 
				this.setState({ error: undefined, isProcessing: true });
				documentsService.getDocuments(customerId)
					.then((res) => {
						var docs = this.props.userInfo?.selectedCustomerInfo?.documents ?? [];
						// var updatedCi = ci.map(x => {
						// 	x.documents = res.filter(newItem=> newItem.contractId === x.contract.id);
						// 	return x;
						// });

						this.context.setDocuments(customerId, res);
						this.setState({ error: undefined, isProcessing: false,  
							filteredItems: this.getFilterItems(
								docs,
									//this.state.selectedContractType, 
									this.state.fromDate, 
									this.state.toDate)
						});
						resolve(res);
					})
					.catch((err) => {
						this.setState({ error: err, isProcessing: false });
						console.error(err);
						reject(err);
					});
			} else {
				this.setState({ error: undefined, isProcessing: false });
				resolve(undefined);
			}	
		});	
    }

	componentDidMount() {
		console.log('componentDidMount');
		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined
			//&& (this.props.userInfo?.selectedCustomerInfo.contractInfos === undefined
			//|| this.props.userInfo?.selectedCustomerInfo.contractInfos.length === 0)
			) {
			//this.getContracts(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				//.then((contracts) => {
				this.getItems(this.props.userInfo?.selectedCustomerInfo?.customer?.id)//;
				//})
				.catch(err => {
					console.error(err);
				});
			
		}
	}

	componentDidUpdate(prevProps: IUserInfoProps) {
		if (prevProps?.userInfo?.selectedCustomerInfo?.customer.id !== this.props.userInfo?.selectedCustomerInfo?.customer.id) {
			//this.getContracts(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				//.then((contracts) => {
				//	return 
				this.getItems(this.props.userInfo?.selectedCustomerInfo?.customer?.id)//;
				//})
				.then((documents) => {
					this.setState({ 
						filteredItems: this.getFilterItems(
							this.props.userInfo?.selectedCustomerInfo?.documents ?? [],
							//this.state.selectedContractType, 
							this.state.fromDate, this.state.toDate) 
					});
				})
				.catch(err => {
					console.error(err);
				});
		}  else {
			if (prevProps.userInfo.selectedCustomerInfo?.wasDocumentsChanged !== this.props.userInfo.selectedCustomerInfo?.wasDocumentsChanged ) {
				this.setState({ 
					filteredItems: 
						 (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined) ?
							this.getFilterItems(
								this.props.userInfo?.selectedCustomerInfo?.documents ?? [],
									//this.state.selectedContractType, 
									this.state.fromDate, this.state.toDate) : []
				});
			}
		}
	}

	getFilterItems(documents: IDocumentDto[], //selectContractType: DocumentTypeEnum, 
		fromDate: number, toDate: number): IDocumentDto[] {
		return documents
			.filter(d => //(selectContractType === undefined || d.documentType === selectContractType) &&
					d.dateSale !== undefined 
					&& moment(d.dateSale).valueOf() >= fromDate 
					&& moment(d.dateSale).valueOf() <= toDate )			
			.reduce((a, b) => a.concat(b), [] as IDocumentDto[]);
				
	}																  

	// change = (event: any) => {
	// 	this.setState({ selectedContractType: event.target.value ,
	// 		filteredItems: this.getFilterItems(
	// 			this.props.userInfo?.selectedCustomerInfo?.documents ?? [],
	// 			event.target.value, 
	// 			this.state.fromDate, 
	// 			this.state.toDate)
	// 	});
	// }

	fromDateChange = (e: any) => {
		this.setState({ fromDate: new Date(e.target.value).valueOf() ,
			filteredItems: this.getFilterItems(
				this.props.userInfo?.selectedCustomerInfo?.documents ?? [],
				//this.state.selectedContractType, 
				new Date(e.target.value).valueOf(), this.state.toDate)  
			});
	}

	toDateChange = (e: any) => {
		this.setState({ toDate: new Date(e.target.value).valueOf()  ,
			filteredItems: this.getFilterItems(
				this.props.userInfo?.selectedCustomerInfo?.documents ?? [],
				//this.state.selectedContractType, 
				this.state.fromDate, new Date(e.target.value).valueOf()) 
			}); 
	}

	downloadInvoice(id: string | undefined): void {
		if (id !== undefined) {
			documentsService.getInvoiceFile(id)
				.then(b => {
					console.log(b);
				})
				.catch(err => {
					alertService.error('Pogranie faktury', `Błąd: ${err}`, { autoClose: true });
				})
		}
	}

	applyForAcceptDownloadingInvoice(): void {
		this.setState(prev => ({ ...prev, showRequestForAcceptDownloadingInvoice: true,redirectToForm: false  }))
	}

	acceptDownloadingInvoiceConfirmed(param: any)
	{
		this.setState(prev => ({ ...prev, showRequestForAcceptDownloadingInvoice: false,redirectToForm: true  }))
	}
	acceptDownloadingInvoiceCanceled = () => {
		this.setState(prev => ({ ...prev, showRequestForAcceptDownloadingInvoice: false,redirectToForm: false  }))
	}

	render() { 
		 
		if (this.state.redirectToForm) {
			return <Redirect to='/wnioski-i-reklamacje/3'/>;
	 	}
		return (
			<div>
				<SweetAlert
						show={this.state.showRequestForAcceptDownloadingInvoice}
						title="Wymagana zgoda!"
						onConfirm={(evt) => this.acceptDownloadingInvoiceConfirmed(evt)}
						onCancel={() => this.acceptDownloadingInvoiceCanceled()}
						confirmBtnText="Tak"
						cancelBtnText="Nie"
						showCancel={true}
						focusCancelBtn={true}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="primary"
						>
							Aby móc pobierać elektroniczne obrazy faktury niezbędne jest złożenie wniosku 'Zgoda na przesyłanie faktur w formie elektronicznej...'. <br/>
							Czy chcesz złożyć wniosek teraz?
					</SweetAlert>
				<div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div>
				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true">Faktury</PanelHeader>
					<PanelBody> 
						{/*combobox filter*/}
						<div className="row">
							<div className="col">
								Numer rachunku bankowego do opłat jest widoczny na fakturze.
							</div>
						</div>
						<form className="row row-cols-lg-auto g-3 align-items-center">
							{/* <div className="mb-3">
								<label className="form-label">Umowa</label>
								<select className="form-select" 
										onChange={this.change}
										value={this.state.selectedContractType}>
								    <option value="">Wszystkie</option>
									<option value="Water">Woda</option>
									<option value="Waste">Śmieci</option>
								</select>  
							</div> */}
							{/*from dateFilter*/}
							<div className="mb-3">
								<label className="form-label">Od</label> 
								<div className="row"> 
									<input 
										className="form-control" 
										type="date" 
										value={dateHelper.numberToDateFormat(this.state.fromDate)}	
										onChange={this.fromDateChange}
										/>
								</div> 
							</div>
							{/*to dateFilter*/}
							<div className="mb-3">
								<label className="form-label">Do</label>
								<input 
										className="form-control" 
										type="date" 
										value={dateHelper.numberToDateFormat(this.state.toDate)}	
										onChange={this.toDateChange}
										/>
							</div>
	
						</form>
						{/*desktop version*/}
						<div className="d-none d-lg-block" >
							<div className="row standard-table standard-header">
								<div className="col-lg-2">Numer</div>
								<div className="col-lg-2">Data wystawienia</div>
								<div className="col-lg-2">Termin płatności</div>
								<div className="col-lg-2">Kwota</div>
								<div className="col-lg-2">Pozostało do zapłaty</div>
								<div className="col-lg-2">Pobierz</div>
								</div>

							<div className="row">
								{this.state.filteredItems
									.map((c,idx) =>
										<div key={idx} className="row standard-table">
											<div className="col-lg-2">{c.number}</div>
											<div className="col-lg-2">{dateHelper.numberToDateFormat(c.dateSale)}</div>
											<div className="col-lg-2">{dateHelper.numberToDateFormat(c.dueDate)}</div>
											<div className="col-lg-2 currency">{stringHelper.currencyFormat(c.valueTotalGross)}</div>
											<div className="col-lg-2 currency">{(c.valueTotalGross !== undefined && c.valuePaid !== undefined) ? stringHelper.currencyFormat(c.valueTotalGross - c.valuePaid) : '---'}</div>
											<div className="col-lg-2" >
												{
												c.documentType && c.documentType !== DocumentTypeEnum.InterestNote && c.downloadAllowed === true &&
													this.props.userInfo?.selectedCustomerInfo?.customer?.eFaktura === true && 
													<i onClick={(ev) => this.downloadInvoice(c.number)} className="cursor-pointer far fa-file"></i>
												} 
												{
												c.documentType && c.documentType !== DocumentTypeEnum.InterestNote && 
													//c.downloadAllowed === true &&
													this.props.userInfo?.selectedCustomerInfo?.customer?.eFaktura !== true &&
													<i onClick={(ev) => this.applyForAcceptDownloadingInvoice()} className="cursor-pointer far fa-envelope-open"></i>
												} 
											</div>
										</div>
									)}
							</div>
						</div>

						{/*mobile version*/}
						<div className="d-block d-lg-none" > 
							<div className="row standard-table standard-header">
								<div className="col-sm-4">Numer<br />Data wystawienia</div> 
								<div className="col-sm-4">Termin płatności<br />Kwota</div>
								<div className="col-sm-4">Pozostało do zapłaty<br />Pobierz</div> 
							</div>
							
							<div className="row">
								{this.state.filteredItems
									.map((c, idx) =>
										<div key={idx} className="row standard-table">
											<div className="col-sm-4">{c.number}<br /> {dateHelper.numberToDateFormat(c.dateSale)}</div> 
											<div className="col-sm-4">{dateHelper.numberToDateFormat(c.dueDate)}<br /><span className='currency'>{stringHelper.currencyFormat(c.valueTotalGross)}</span></div> 
											<div className="col-sm-4"><span className='currency'>{(c.valueTotalGross !== undefined && c.valuePaid !== undefined) ? stringHelper.currencyFormat(c.valueTotalGross - c.valuePaid) : '---'}</span><br />
											{
												c.documentType && c.documentType !== DocumentTypeEnum.InterestNote && 
													c.downloadAllowed === true &&
													this.props.userInfo?.selectedCustomerInfo?.customer?.eFaktura === true && 
													<i onClick={(ev) => this.downloadInvoice(c.number)} className="cursor-pointer far fa-file"></i>
												} 
												{
												c.documentType && c.documentType !== DocumentTypeEnum.InterestNote && 
													//c.downloadAllowed === true &&
													this.props.userInfo?.selectedCustomerInfo?.customer?.eFaktura !== true &&
													<i onClick={(ev) => this.applyForAcceptDownloadingInvoice()} className="cursor-pointer far fa-envelope-open-o"></i>
												}  
											</div> 
										</div>
									)}
							</div>
						</div>


					</PanelBody>
				</Panel>
			</div>
		 
		)
	}
	// Invoice = 1,
	// InterestNote = 2,
	// Correction = 3
	
}

export default Invoices; 
