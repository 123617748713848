
import { fetchWrapper } from "../helpers";
import { CustomerValidationReqeuest, ICustomerDto } from "../models/generated";
import { getConfiguration } from "./config";

export const customersService = {
    getCustomers,
    sendRequestToAssignCustomer,
    synchronizeAccount,
    removeCustomer
  };

  
async function getCustomers(): Promise<ICustomerDto[]> {        
    const resp = await fetchWrapper.get(getConfiguration().apiUrl + '/customers/getCustomers');
    const res: ICustomerDto[] = resp;
    return res;
}

async function sendRequestToAssignCustomer(request: CustomerValidationReqeuest): Promise<ICustomerDto> {        
    const resp = await fetchWrapper.post(getConfiguration().apiUrl + '/customers/sendRequestToAssignCustomer', request);
    const res: ICustomerDto = resp;
    return res;
}

async function synchronizeAccount(): Promise<void> {        
    await fetchWrapper.post(getConfiguration().apiUrl + '/customers/synchronizeAccount', {});
    return;
}

async function removeCustomer(customerId: number): Promise<ICustomerDto> {        
    const resp = await fetchWrapper.post(getConfiguration().apiUrl + '/customers/removeCustomer', customerId);
    const res: ICustomerDto = resp;
    return res;
}