import { fetchWrapper } from "../helpers";
import { IMessageDto } from "../models/generated";
import { getConfiguration } from "./config";

export const messagesService = {
    getMessages, 
    addMessage,
    updateMessage,
  };

async function getMessages(onlyActive: boolean, skip: number,take?: number): Promise<IMessageDto[]> {        
    const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/messages/getMessages/${onlyActive}/${skip}/${(take) ? '/' + take : ''}`);
    const res: IMessageDto[] = resp;
    return res;
}

async function addMessage(message: IMessageDto): Promise<IMessageDto> {        
  const resp = await fetchWrapper.post(getConfiguration().apiUrl + `/messages/addMessage`, message);
  const res: IMessageDto = resp;
  return res;
}

async function updateMessage(message: IMessageDto): Promise<IMessageDto> {        
  const resp = await fetchWrapper.post(getConfiguration().apiUrl + `/messages/updateMessage`, message);
  const res: IMessageDto = resp;
  return res;
}