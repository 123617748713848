import moment from "moment";

export const dateHelper = {
    numberToDateFormat,
    getMonthName,
    addDays,
    getNextDeclarationMonth,
    getMonthNameNum
}

const monthNames = ["stycznia", "lutego", "marca", "kwietnia", "maja", "czerwca",
  "lipca", "sierpnia", "września", "pażdziernika", "listopada", "grudnia"
];

function getMonthName(d: Date): string {
    return monthNames[d.getMonth() % 12];
}

function getMonthNameNum(n: number): string {
    return monthNames[n % 12];
}

function numberToDateFormat(toDate: number | Date | undefined) : string {
    return moment(toDate).format('yyyy-MM-DD');
}

function  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
}

function getNextDeclarationMonth() {
    var thisMonth = (new Date()).getMonth();
    var monthIn7Days = addDays(new Date(), 7).getMonth();
    if (thisMonth === monthIn7Days)
        return thisMonth + 1;
    else
        return thisMonth + 2;
}
