import React from 'react';

import { Navbar, Container, Nav, NavDropdown, Col } from 'react-bootstrap';
import { accountService } from '../../services/account.service';
import { isLoggedIn, UserContext } from '../../config/user-context';
import { IProcessingModel, roleHelper } from '../../helpers';
import { Role } from '../../models/generated';
import { Link } from 'react-router-dom';
import EditProfile from './edit-profile';
import { IModalDialogProps, ModalDialogMode } from '../../models/models';

// interface IMessageManagerState extends IModalDialogProps, IProcessingModel {
// 	messages: IMessageDto[],
// 	onlyActive: boolean,
// 	messageToManage: IMessageDto | undefined
// }

interface IEbokTopMenuState extends IProcessingModel {
	editProfileProps: IModalDialogProps;
}
class EbokTopMenu extends React.Component<{}, IEbokTopMenuState> {
	constructor(props: {}) {
		super(props);
		this.state = { 
			isProcessing: false,
			error: undefined,
			editProfileProps: {
				isModalDialogOpened: false,
				modalDialogMode: ModalDialogMode.Edit,
				closeModalDialog: this.closeEditProfileModalDialog,
			}
		};
	}

	openEditProfileModalDialog = () => {
		this.setState((prev: IEbokTopMenuState) => ({ ...prev, 
			editProfileProps: { ...prev.editProfileProps, isModalDialogOpened: true }
		}));
	}

	closeEditProfileModalDialog = (exitCode: number | undefined) => {
		this.setState((prev: IEbokTopMenuState) => ({ ...prev, 
			editProfileProps: { ...prev.editProfileProps, isModalDialogOpened: false }
		}));
	}

	render() {
		return (
			<UserContext.Consumer> 
				{(userContextData) => (
					<>
					<EditProfile userContext={userContextData}  {...this.state.editProfileProps} />
					<Navbar collapseOnSelect sticky="top" expand="lg" bg="light" variant="light">
						<Container className="navbar-container">
							<Col>
								<Navbar.Brand as={Link} to="/">
									<span className="navbar-logo"></span>
								</Navbar.Brand>
							</Col>
							
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Col>
									<Nav className="me-auto">
										{isLoggedIn(userContextData.userInfo) &&
											<>
											<Nav.Link as={Link} to="/">Pulpit</Nav.Link>
											<Nav.Link as={Link} to="/faktury">Faktury</Nav.Link>
											<Nav.Link as={Link} to="/platnosci">Płatności</Nav.Link>
											<Nav.Link as={Link} to="/umowy">Umowy</Nav.Link>
											<Nav.Link as={Link} to="/stan-licznikow">Liczniki</Nav.Link>
											<NavDropdown title="Formularze" id="collasible-nav-dropdown">
												<NavDropdown.Item as={Link} to="/wnioski-i-reklamacje">Wnioski i reklamacje</NavDropdown.Item>
													<NavDropdown.Item as={Link} to="/deklaracje-na-odbior-odpadow">Deklaracje na odbiór odpadów</NavDropdown.Item>
											</NavDropdown>											
											{ roleHelper.compareRole(userContextData.userInfo.role, Role.Admin) &&
												<Nav.Link as={Link} to="/zarzadzaj-wiadomosciami">Administracja</Nav.Link> 
											}
											</>
										}
									</Nav>
								</Col>
								<Col >								
									<Nav className="nav-user-profile">
										{isLoggedIn(userContextData.userInfo) &&
										<NavDropdown title={`${userContextData.userInfo.firstName} ${userContextData.userInfo.lastName}`} id="collasible-nav-dropdown" className='nav-dropdown-user-profile'>
											<NavDropdown.Item onClick={() => this.openEditProfileModalDialog()}>Twoje dane</NavDropdown.Item>
											<NavDropdown.Divider />
											<NavDropdown.Item onClick={() => accountService.logout()}>Wyloguj</NavDropdown.Item>
										</NavDropdown>
										}
									</Nav>
								</Col>
							</Navbar.Collapse>
						</Container>
					</Navbar>
					</>
				)}
			</UserContext.Consumer>
		)
	}
}

export default EbokTopMenu;
