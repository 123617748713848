import React from 'react';
import { FieldArrayPath, FieldValues, useFieldArray, useForm, UseFormProps } from "react-hook-form";
import { useParams } from 'react-router-dom';

export const withUseFormHook = <TFieldValues extends FieldValues = FieldValues, TContext = any>(Component: any, userFormOptions?: UseFormProps<TFieldValues, TContext>) => {
    return (props: any) => {
        const form = useForm<TFieldValues>(userFormOptions);
        return <Component {...props} {...form} />
    }       
}

export const withUseFormHookWithFieldArray = <TFieldValues extends FieldValues = FieldValues, TContext = any>(Component: any, arrayFieldName: FieldArrayPath<TFieldValues>, userFormOptions?: UseFormProps<TFieldValues, TContext>) => {
    return (props: any) => {
        const form = useForm<TFieldValues>(userFormOptions);
        
        const { control } = form;
        const arrayField = useFieldArray({
            control,
            name: arrayFieldName
        });
        return <Component {...props} {...form} {...arrayField}/>
    }       
}

export const withParams = (Component: any) => {
    return (props: any) => (<Component {...props} params={useParams()} />);
}