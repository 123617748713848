import React from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';

import { AppContext, IAppContext } from './config/app-settings';
import Sidebar from './components/sidebar/sidebar';
import SidebarRight from './components/sidebar-right/sidebar-right';
//import TopMenu from './components/top-menu/top-menu';
import Content from './components/content/content';
import FloatSubMenu from './components/float-sub-menu/float-sub-menu';
import { IContractInfoDto, ICustomerInfoDto, IUserContextData,  UserContext, UserInfoDtoDefault } from './config/user-context';
import { AuthenticateResponse, IContractDto, IContractWithDetailsDto, ICustomerDto, IDocumentDto, IMeterWithMeterReadsDto, IPaymentDto } from './models/generated';
import { alertService } from './services/alert.service';
import { from, of, Subscription } from 'rxjs';
import { accountService } from './services/account.service';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { customersService } from './services/customers.service';
import { enovaSyncNotification, enovaSyncNotificationService } from './services/enovasync.notificatin.service';
import { contractsService } from './services/contracts.service';
import { documentsService } from './services/documents.service';
import { arrayHelper } from './helpers/array';
import { paymentsService } from './services/payments.service';
import { metersService } from './services/meters.service';
import Header from './components/header/header';
import EbokTopMenu from './pages/components/ebok-top-menu';
import Footer from './pages/components/footer';
import AddCustomer from './pages/components/add-customer';
import { IModalDialogProps, ModalDialogMode } from './models/models';

interface IAppState {
	appContext: IAppContext,
	userContext: IUserContextData,
	addCustomerModal: IModalDialogProps
}

class App extends React.Component<{}, IAppState> {	

//#region APP component from template	
	constructor(props: any) {
		super(props);
		var appSidebarFloatSubMenuRemove : NodeJS.Timeout;
		var appSidebarFloatSubMenuCalculate : NodeJS.Timeout;
		var appSidebarFloatSubMenuRemoveTime = 250;

		this.state = { 
				appContext: {	
					appHeaderNone: true,
					appHeaderInverse: false,
					appHeaderMegaMenu: false,
					appHeaderLanguageBar: false,
					hasScroll: false,
					appSidebarNone: true,
					appSidebarWide: false,
					appSidebarLight: false,
					appSidebarMinify: false,
					appSidebarMobileToggled: false,
					appSidebarTransparent: false,
					appSidebarSearch: false,
					appSidebarFloatSubMenuActive: false,
					appSidebarFloatSubMenu: '',
					appSidebarFloatSubMenuTop: 'auto',
					appSidebarFloatSubMenuLeft: 'auto',
					appSidebarFloatSubMenuBottom: 'auto',
					appSidebarFloatSubMenuLineTop: 'auto',
					appSidebarFloatSubMenuLineBottom: 'auto',
					appSidebarFloatSubMenuArrowTop: 'auto',
					appSidebarFloatSubMenuArrowBottom: 'auto',
					appSidebarFloatSubMenuOffset: '',
					appContentNone: false,
					appContentClass: 'content-test',
					appContentFullHeight: false,
					appTopMenu: true,
					appTopMenuMobileToggled: false,
					appSidebarTwo: false,
					appSidebarEnd: false,
					appSidebarEndToggled: false,
					appSidebarEndMobileToggled: false,


					toggleAppSidebarMinify: (e: any) => {
						e.preventDefault();
						if (this.state.appContext.appSidebarMinify) {
							this.setState(state => ({...state, appContext: ({...state.appContext,
										appSidebarFloatSubMenuActive: false
									})
							}));
						}
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarMinify: !this.state.appContext.appSidebarMinify
							})
						}));
					},
					toggleAppSidebarMobile: (e: any) => {
						e.preventDefault();
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarMobileToggled: !this.state.appContext.appSidebarMobileToggled
							})
						}));
					},
					handleSetAppSidebarNone: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarNone: value
							})
						}));
					},
					handleSetAppSidebarMinified: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarMinify: value
							})
						}));
					},
					handleSetAppSidebarWide: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarWide: value
							})
						}));
					},
					handleSetAppSidebarLight: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarLight: value
							})
						}));
					},
					handleSetAppSidebarTransparent: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarTransparent: value
							})
						}));
					},
					handleSetAppSidebarSearch: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarSearch: value
							})
						}));
					},
					toggleAppSidebarEnd: (e: any) => {
						e.preventDefault();
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarEndToggled: !this.state.appContext.appSidebarEndToggled
							})
						}));
					},
					toggleAppSidebarEndMobile: (e: any) => {
						e.preventDefault();
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarEndMobileToggled: !this.state.appContext.appSidebarEndMobileToggled
							})
						}));
					},
					handleSetAppSidebarEnd: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarEnd: value
							})
						}));
					},
					handleAppSidebarFloatSubMenuOnMouseOver: (e: any) => {
						clearTimeout(appSidebarFloatSubMenuRemove);
						clearTimeout(appSidebarFloatSubMenuCalculate);
					},
					handleAppSidebarFloatSubMenuOnMouseOut: (e: any) => {
						appSidebarFloatSubMenuRemove = setTimeout(() => {
							this.setState(state => ({...state, appContext: ({...state.appContext,
								appSidebarFloatSubMenuActive: false
								})
							}));
						}, appSidebarFloatSubMenuRemoveTime);
					},
					handleAppSidebarOnMouseOver: (e: any, menu: any) => {
						if (this.state.appContext.appSidebarMinify) {
							if (menu.children) {
								var left = ((document.getElementById('sidebar')?.offsetWidth || 0) + (document.getElementById('sidebar')?.offsetLeft || 0)) + 'px';
								
								clearTimeout(appSidebarFloatSubMenuRemove);
								clearTimeout(appSidebarFloatSubMenuCalculate);
						
								this.setState(state => ({...state, appContext: ({...state.appContext,
									appSidebarFloatSubMenu: menu,
									appSidebarFloatSubMenuActive: true,
									appSidebarFloatSubMenuLeft: left
									})
								}));
								
								var offset = e.currentTarget.offsetParent.getBoundingClientRect();
								
								appSidebarFloatSubMenuCalculate = setTimeout(() => {
									var targetTop = offset.top;
									var windowHeight = window.innerHeight;
									var targetHeight = (document.querySelector('.app-sidebar-float-submenu-container') as HTMLElement)?.offsetHeight || 0;
									var top : any, bottom : any, arrowTop : any, arrowBottom : any, lineTop : any, lineBottom : any;
									
									if ((windowHeight - targetTop) > targetHeight) {
										top = offset.top + 'px';
										bottom = 'auto';
										arrowTop = '20px';
										arrowBottom = 'auto';
										lineTop = '20px';
										lineBottom = 'auto';
									} else {
										var aBottom = (windowHeight - targetTop) - 21;
										top = 'auto';
										bottom = '0';
										arrowTop = 'auto';
										arrowBottom = aBottom + 'px';
										lineTop = '20px';
										lineBottom = aBottom + 'px';
									}
									
									this.setState(state => ({...state, appContext: ({...state.appContext,
										appSidebarFloatSubMenuTop: top,
										appSidebarFloatSubMenuBottom: bottom,
										appSidebarFloatSubMenuLineTop: lineTop,
										appSidebarFloatSubMenuLineBottom: lineBottom,
										appSidebarFloatSubMenuArrowTop: arrowTop,
										appSidebarFloatSubMenuArrowBottom: arrowBottom,
										appSidebarFloatSubMenuOffset: offset
										})
									}));
								}, 0);
								
							} else {
								appSidebarFloatSubMenuRemove = setTimeout(() => {
									this.setState(state => ({...state, appContext: ({...state.appContext,
										appSidebarFloatSubMenu: '',
										appSidebarFloatSubMenuActive: false
										})
									}));
								}, appSidebarFloatSubMenuRemoveTime);
							}
						}
					},
					handleAppSidebarOnMouseOut: (e: any) => {
						if (this.state.appContext.appSidebarMinify) {
							appSidebarFloatSubMenuRemove = setTimeout(() => {
								this.setState(state => ({...state, appContext: ({...state.appContext,
									appSidebarFloatSubMenuActive: false
									})
								}));
							}, appSidebarFloatSubMenuRemoveTime);
						}
					},
					handleAppSidebarFloatSubMenuClick: () => {
						if (this.state.appContext.appSidebarMinify) {
							const windowHeight = window.innerHeight;
							const targetHeight = document.getElementById('app-sidebar-float-submenu')?.offsetHeight || 0;
							const targetTop = this.state.appContext.appSidebarFloatSubMenuOffset?.top || 0;
							const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
							const left = ((this.state.appContext.appSidebarFloatSubMenuOffset?.left || 0) + (document.getElementById('sidebar')?.offsetWidth || 0)) + 'px';
							const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
							const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
							const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
							const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
							const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
						
							this.setState(state => ({...state, appContext: ({...state.appContext,
								appSidebarFloatSubMenuTop: top,
								appSidebarFloatSubMenuLeft: left,
								appSidebarFloatSubMenuBottom: bottom,
								appSidebarFloatSubMenuLineTop: lineTop,
								appSidebarFloatSubMenuLineBottom: lineBottom,
								appSidebarFloatSubMenuArrowTop: arrowTop,
								appSidebarFloatSubMenuArrowBottom: arrowBottom
								})
							}));
						}
					},
					handleSetAppContentNone: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appContentNone: value
							})
						}));
					},
					handleSetAppContentClass: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appContentClass: value
							})
						}));
					},
					handleSetAppContentFullHeight: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appContentFullHeight: value
							})
						}));
					},
					handleSetAppHeaderNone: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appHeaderNone: value
							})
						}));
					},
					handleSetAppHeaderInverse: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appHeaderInverse: value
							})
						}));
					},
					handleSetAppHeaderMegaMenu: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appHeaderMegaMenu: value
							})
						}));
					},
					handleSetAppHeaderLanguageBar: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appHeaderLanguageBar: value
							})
						}));
					},
					handleSetAppTopMenu: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appTopMenu: value
							})
						}));
					},
					toggleAppTopMenuMobile: (e: any) => {
						e.preventDefault();
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appTopMenuMobileToggled: !this.state.appContext.appTopMenuMobileToggled
							})
						}));
					},
					handleSetAppSidebarTwo: (value: any) => {
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarTwo: value
							})
						}));
						this.setState(state => ({...state, appContext: ({...state.appContext,
							appSidebarEndToggled: value
							})
						}));
					},
					handleSetAppBoxedLayout: (value: any) => {
						if (value === true) {
							document.body.classList.add('boxed-layout');
						} else {
							document.body.classList.remove('boxed-layout');
						}
					}
				},
				userContext: {
					userInfo: UserInfoDtoDefault, 
					login: this.login,
					setCustomers: this.setCustomers,
					setSelectedCustomer: this.setSelectedCustomer,
					setContracts: this.setContracts,
					setDocuments:  this.setDocuments,
					setPayments:  this.setPayments,  
					setMeterWithMeterReads:  this.setMeterWithMeterReads, 
					setCustomerInfos: this.setCustomerInfos,
					openAddCustomerModalDialog: this.openAddCustomerModalDialog
				},
				addCustomerModal: {
					isModalDialogOpened: false,
					modalDialogMode: ModalDialogMode.Add,
					closeModalDialog: this.closeCustomerModal
				}
			}
		};
	
		
	closeCustomerModal = (customerId: number | undefined) => {
		this.setState(prev => ({ ...prev, addCustomerModal: { 
			...prev.addCustomerModal,
			isModalDialogOpened: false
		}}));
		this.synchronizeAccount();
	};

	synchronizeAccount = () => {
		//this.setState((prevState) => ({ isSynch: true }));	
		from(customersService.synchronizeAccount())
			.pipe(
				first()
			)
			.subscribe({
				next: val => 
				{ 
					//this.setState((prevState) => ({ isSynch: false }));	
					console.log(`synchronizeAccount success started`); 
				},
				complete: () => console.log('synchronizeAccount Complete!'),
				error: err => 
				{ 
					//this.setState((prevState) => ({ isSynch: false }));	
					console.log(`synchronizeAccount Error: ${err}`); 
				} 
			});
	}

	handleScroll = () => {
		if (window.scrollY > 0) {
			this.setState(state => ({...state, appContext: { ...state.appContext,
					hasScroll: true
				}}));
		} else {
			this.setState(state => ({...state, appContext: { ...state.appContext,
				hasScroll: false
			}}));
		}
		var elm = document.getElementsByClassName('nvtooltip');
		for (var i = 0; i < elm.length; i++) {
			elm[i].classList.add('d-none');
		}
	}
//#endregion APP component from template
	
	alertServiceSubscription: Subscription | undefined;	
	accountServiceUser: Subscription | undefined;
	userSubscription: Subscription | undefined;
	enovaSyncNotificationSubscription: Subscription | undefined;

	componentDidMount() {
		console.log('App: componentDidMount');
		window.addEventListener('scroll', this.handleScroll);

		//notifications
		this.alertServiceSubscription = alertService.onAlert()
		.subscribe(alert => {
			console.log(alert);
			var addNotification = store.addNotification({
				title: alert.title,
				message: alert.message,
				type: alert.type,
				insert: "top",
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
				  duration: 5000,
				  onScreen: true
				},
				onRemoval: (id: string, removedBy: any) => { console.log('onRemoval: ' + id)}
			  });
			console.log('addNotification: ' + addNotification);
		});
 
		
		//change user
		this.userSubscription = accountService.user
		.pipe(
			tap(user => {
				if (user !== undefined && user !== null) {
					if (this.enovaSyncNotificationSubscription !== undefined) {
						this.enovaSyncNotificationSubscription.unsubscribe();
						this.enovaSyncNotificationSubscription = undefined;
					}

					this.enovaSyncNotificationSubscription = enovaSyncNotificationService.getEnovaSyncStream()
						.pipe(
							tap(val => {
								console.log("getEnovaSyncStream: " + JSON.stringify(val));
								this.handleEnovaSync(val);
							})
						)
						.subscribe({
							error: (err) => console.error("enovaSyncNotificationSubscription: " + err)
						});
					
				} else {
					if (this.enovaSyncNotificationSubscription !== undefined) {
						this.enovaSyncNotificationSubscription.unsubscribe();
						this.enovaSyncNotificationSubscription = undefined;
					}
				}
				
			}),
			switchMap(x => {
				if (x && x.id && x.email && x.firstName && x.lastName) {
				
					return from(customersService.getCustomers())
						.pipe(
							map((res) => 
								{ return { user: x, customerDtos: res }; }
							),
							catchError(val => { 
								console.log(`GetCustomers Error: ${val}`); 
								alertService.error(`Pobranie informacji o klientach`, `${val}`, { autoClose: true });
								return of({user: x, customerDtos: [] }); 
							})
						);
				}				
				return of({user: x, customerDtos: [] });
			}),
			tap(x => {
				if (this.state.userContext.userInfo.userId !== x.user?.id
					|| this.state.userContext.userInfo.customerInfos?.length !== x.customerDtos.length) {
					this.state.userContext.setCustomers(x.customerDtos);
				}

				var prevUser = accountService.prevUser;
				if (x.user !== undefined 
					&& x.user !== null 
					&& x.user.id !== prevUser?.id
					&& x.customerDtos.length === 0) {
					this.openAddCustomerModalDialog();
				}
			})
		)
		.subscribe();

		//refresh token
		accountService.refreshToken()
			.then(user => {
				console.log('App: accountService.refreshToken ' + user);
				this.login(user);
			})
			.catch(err => {
				console.log('App: accountService.refreshToken+failed ');
				this.login(null);
			})
			.finally(() => {
				if (this.accountServiceUser === undefined) {
					this.accountServiceUser = accountService.user.subscribe(user => {
						console.log('App: accountService.user.subscribe ' + JSON.stringify(user));
						this.login(user);
					});
				}
			});
	}

	openAddCustomerModalDialog = () => {
		this.setState(prev => ({ ...prev, addCustomerModal: { 
			...prev.addCustomerModal,
			isModalDialogOpened: true
		}}));
	};

	handleEnovaSync(val: enovaSyncNotification) {

		switch (val.type) {
			case 'ContractsAdded':
				this.handleEnovaSyncContractsAdded(val.customerId, val.ids, val.error);	
				break;
			case 'ContractsUploaded':
				this.handleEnovaSyncContractsUploaded(val.customerId, val.ids, val.error);	
				break;
			case 'DocumentsAdded':
				this.handleEnovaSyncDocumentsAdded(val.customerId, val.ids, val.error);	
				break;
			case 'PaymentsAdded':
				this.handleEnovaSyncPaymentsAdded(val.customerId, val.ids, val.error);	
				break;
			case 'MeterReadsAdded':
				this.handleEnovaSyncMeterReadsAdded(val.customerId, val.ids, val.error);	
				break;
		}
	}	
	
	handleEnovaSyncContractsUploaded(customerId: number, ids: number[] | undefined, error: string | undefined) {
		if (error) {
			console.error(`handleEnovaSyncContractsUploaded: (${customerId}): `  + error);
			return;
		}
		if (ids === undefined) {
			console.error(`handleEnovaSyncContractsUploaded: (${customerId}): ids undefined`);
			return;
		}
		console.log(`handleEnovaSyncContractsUploaded ${ids}`);
		if (ids.length > 0) {
			contractsService.getContractsByIds(ids)
				.then((res) => {
					this.addOrUpdateContracts(customerId, res);	
					console.log(`handleEnovaSyncContractsUploaded: (${customerId}): addOrUpdateContracts: `  + res.length);			
				})
				.catch((err) => {
					console.error("handleEnovaSyncContractsUploaded: getContractsByIds: " + err);
				});
		}
	}

	handleEnovaSyncContractsAdded(customerId: number, ids: number[] | undefined, error: string | undefined) {
		if (error) {
			console.error(`handleEnovaSyncContractsAdded: (${customerId}): `  + error);
			return;
		}
		if (ids === undefined) {
			console.error(`handleEnovaSyncContractsAdded: (${customerId}): ids undefined`);
			return;
		}
		console.log(`handleEnovaSyncContractsAdded ${ids}`);
		if (ids.length > 0) {
			contractsService.getContractsByIds(ids)
				.then((res) => {
					this.addOrUpdateContracts(customerId, res);	
					console.log(`handleEnovaSyncContractsAdded: (${customerId}): addOrUpdateContracts: `  + res.length);			
				})
				.catch((err) => {
					console.error("handleEnovaSyncContractsAdded: getContractsByIds: " + err);
				});
		}
	}
	
	handleEnovaSyncDocumentsAdded(customerId: number, ids: number[] | undefined, error: string | undefined) {
		if (error) {
			console.error(`handleEnovaSyncDocumentsAdded: (${customerId}): `  + error);
			return;
		}
		if (ids === undefined) {
			console.error(`handleEnovaSyncDocumentsAdded: (${customerId}): ids undefined`);
			return;
		}
		console.log(`handleEnovaSyncDocumentsAdded ${ids}`);
		if (ids.length > 0) {
			documentsService.getDocumentsByIds(ids)
				.then((res) => {
					this.addOrUpdateDocuments(customerId, res);	
					console.log(`handleEnovaSyncDocumentsAdded: (${customerId}): addOrUpdateDocuments: `  + res.length);			
				})
				.catch((err) => {
					console.error("handleEnovaSyncDocumentsAdded: getDocumentsByIds: " + err);
				});
		}
	}

	handleEnovaSyncPaymentsAdded(customerId: number, ids: number[] | undefined, error: string | undefined) {
		if (error) {
			console.error(`handleEnovaSyncPaymentsAdded: (${customerId}): `  + error);
			return;
		}
		if (ids === undefined) {
			console.error(`handleEnovaSyncPaymentsAdded: (${customerId}): ids undefined`);
			return;
		}
		console.log(`handleEnovaSyncPaymentsAdded ${ids}`);
		if (ids.length > 0) {
			paymentsService.getPaymentsByIds(ids)
				.then((res) => {
					this.addOrUpdatePayments(customerId, res);	
					console.log(`handleEnovaSyncPaymentsAdded: (${customerId}): addOrUpdatePayments: `  + res.length);			
				})
				.catch((err) => {
					console.error("handleEnovaSyncPaymentsAdded: getPaymentsByIds: " + err);
				});
		}
	}
	
	handleEnovaSyncMeterReadsAdded(customerId: number, ids: number[] | undefined, error: string | undefined) {
		if (error) {
			console.error(`handleEnovaSyncMeterReadsAdded: (${customerId}): `  + error);
			return;
		}
		if (ids === undefined) {
			console.error(`handleEnovaSyncMeterReadsAdded: (${customerId}): ids undefined`);
			return;
		}
		console.log(`handleEnovaSyncMeterReadsAdded ${ids}`);
		if (ids.length > 0) {
			metersService.getMetersWithMeterReadsByIds(ids)
				.then((res) => {
					this.addOrUpdateMeterReads(customerId, res);	
					console.log(`handleEnovaSyncMeterReadsAdded: (${customerId}): addOrUpdateMeterReads: `  + res.length);			
				})
				.catch((err) => {
					console.error("handleEnovaSyncMeterReadsAdded: getMetersWithMeterReadsByIds: " + err);
				});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		if (this.accountServiceUser) {
			this.accountServiceUser.unsubscribe();
			this.accountServiceUser = undefined;
		}
		if (this.alertServiceSubscription) {
			this.alertServiceSubscription.unsubscribe();
		}
		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
	}

	login = (authenticateResponse: AuthenticateResponse | null)  => {
		if (authenticateResponse 
				&& authenticateResponse.id
				&& authenticateResponse.email
				&& authenticateResponse.role
				&& authenticateResponse.firstName
				&& authenticateResponse.lastName) {
			this.setState(state => ({...state, 
				appContext: { ...state.appContext, appHeaderMegaMenu: true },
				userContext: { ...state.userContext,
				userInfo: { ...state.userContext.userInfo, 
					userId: authenticateResponse.id!, 
					role: authenticateResponse.role!,
					email: authenticateResponse.email!, 
					firstName: authenticateResponse.firstName!, 
					lastName: authenticateResponse.lastName!,
					electronicInvoiceAgreed: authenticateResponse.electronicInvoiceAgreed!,
					isLoggedIn: true 
				}
			}})); 
		} else {
			this.setState(state => ({...state, 
				appContext: { ...state.appContext, appHeaderMegaMenu: false },
				userContext: { ...state.userContext,
					userInfo: UserInfoDtoDefault
			}})); 
		}
	}
    
//#region Customers
	newSelectedCustomer = (customerInfos: ICustomerInfoDto[], 
		prevSelectedCustomerId: number | undefined, 
		newSelectedCustomerId: number | undefined): ICustomerInfoDto | undefined => {
		const result = (customerInfos && customerInfos.length > 0) ? 
			customerInfos.find(x => x.customer?.id !== undefined 
				&& x.customer.id === (newSelectedCustomerId ?? prevSelectedCustomerId)) ?? customerInfos[0] 
			: 
			undefined;
		if ((result === undefined && prevSelectedCustomerId !== undefined) ||
			(result?.customer?.id !== undefined && result.customer.id !== prevSelectedCustomerId))
		{
			console.log('customer changed to ' + result?.customer?.id);
		}
		return result;
	}
	
	setCustomerInfos = (customerInfos: ICustomerInfoDto[]) => {
		const prevSelectedCustomer = this.state.userContext.userInfo?.selectedCustomerInfo?.customer?.id;
		this.setState(prevState => ({...prevState, userContext: { ...prevState.userContext,
			userInfo: { ...prevState.userContext.userInfo, 
				customerInfos: customerInfos, 
				selectedCustomerInfo: this.newSelectedCustomer(customerInfos, prevSelectedCustomer, undefined)
			}
		}})); 
	}
    setCustomers = (customers: ICustomerDto[]) => {
		
		var customerInfos: ICustomerInfoDto[] = customers.map(x => {
			return { customer: x, contractInfos: [],
				documents: [],
    			payments: [],
			    wasContractsChanged: 0,
				wasDocumentsChanged: 0,
				wasPaymentsChanged: 0,
				wasMeterReadsChanged: 0
			} as ICustomerInfoDto;
		});
		this.setCustomerInfos(customerInfos);
	}

    setSelectedCustomer = (customerId: number) => {
		const prevSelectedCustomer = this.state.userContext.userInfo?.selectedCustomerInfo?.customer?.id;
		this.setState(state => ({...state, userContext: { ...state.userContext,
			userInfo: { ...state.userContext.userInfo, 
				selectedCustomerInfo: this.newSelectedCustomer(state.userContext.userInfo.customerInfos, prevSelectedCustomer, customerId)
			}
		}})); 
	}  
//#endregion Customers

	private updateCustomerInfo(customerId: number, contractWithDetails: IContractWithDetailsDto[] | undefined, documents: IDocumentDto[] | undefined, 
		payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined,
			updater: (customerInfo: ICustomerInfoDto, contractWithDetails: IContractWithDetailsDto[] | undefined, documents: IDocumentDto[] | undefined, 
				payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined) => void) {
		var customerInfos =this.state.userContext.userInfo?.customerInfos;
		if (customerInfos !== undefined) {
			var ici = customerInfos.findIndex(x => x.customer?.id === customerId);
			if (ici >= 0) {				
				var customerInfo = { ...customerInfos[ici] };
				customerInfos[ici] = customerInfo;

				updater(customerInfo, contractWithDetails, documents, payments, meterReads);

				if (contractWithDetails !== undefined) {
					customerInfo.wasContractsChanged = (customerInfo.wasContractsChanged + 1) % 100;
				}
				if (contractWithDetails !== undefined || documents !== undefined) {
					customerInfo.wasDocumentsChanged = (customerInfo.wasDocumentsChanged + 1) % 100;
				}
				if (contractWithDetails !== undefined || payments !== undefined) {
					customerInfo.wasPaymentsChanged = (customerInfo.wasPaymentsChanged + 1) % 100;
				}
				if (contractWithDetails !== undefined || meterReads !== undefined) {
					customerInfo.wasMeterReadsChanged = (customerInfo.wasMeterReadsChanged + 1) % 100;
				}
				
				this.setCustomerInfos(customerInfos); 
			}
		}
	};
	
//#region Contracts	
	setContracts = (customerId: number, contractWithDetails: IContractWithDetailsDto[]) => 
	{
		this.updateCustomerInfo(customerId, contractWithDetails, undefined, undefined, undefined,
			(customerInfo: ICustomerInfoDto, contractWithDetails: IContractWithDetailsDto[] | undefined, documents: IDocumentDto[] | undefined, 
				payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined) => {

				if (contractWithDetails !== undefined) {
					customerInfo.contractInfos = contractWithDetails.map(x => {
						return { contractWithDetails: x, documents: [], payments: [], meterInfos: [] } as IContractInfoDto;
					});
				} else {
					customerInfo.contractInfos = [];
				}

			});
	};

	addOrUpdateContracts = (customerId: number, contractWithDetails: IContractWithDetailsDto[] | undefined) => {
		this.updateCustomerInfo(customerId, contractWithDetails, undefined, undefined, undefined,
			(customerInfo: ICustomerInfoDto, contracts: IContractDto[] | undefined, documents: IDocumentDto[] | undefined, 
				payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined) => {

				if (contracts !== undefined) {
					contracts.forEach(con => {
						if (customerInfo.contractInfos === undefined) {
							customerInfo.contractInfos = contracts.map(x => {
								return { contractWithDetails: x,  meterInfos: [] } as IContractInfoDto;
							});
						} else {
							contracts.forEach(contract => {
								var contractInfo = customerInfo!.contractInfos.find(x => x.contractWithDetails.contract?.id === contract.id);
								if (contractInfo) {
									contractInfo.contractWithDetails = contract;
								} else {
									customerInfo!.contractInfos.push({ contractWithDetails: contract,  meterInfos: []});
								}
							});
						}
					});
				} 
			});
	};
//#endregion Contracts

//#region Documents
	setDocuments = (customerId: number, documents: IDocumentDto[]) => 
	{
		this.updateCustomerInfo(customerId, undefined, documents, undefined, undefined,
			(customerInfo: ICustomerInfoDto, contracts: IContractDto[] | undefined, documents: IDocumentDto[] | undefined, 
				payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined) => {
				
				customerInfo.documents = documents ?? [];
			});
	};

	addOrUpdateDocuments = (customerId: number, documents: IDocumentDto[]) => {
		this.setDocuments(customerId, documents);
	};
//#endregion Documents
	
//#region Payments
	setPayments = (customerId: number, payments: IPaymentDto[]) => 
	{
		this.updateCustomerInfo(customerId, undefined, undefined, payments, undefined,
			(customerInfo: ICustomerInfoDto, contracts: IContractDto[] | undefined, documents: IDocumentDto[] | undefined, 
				payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined) => {
				
				customerInfo.payments = payments ?? [];
			});
	};

	addOrUpdatePayments = (customerId: number, payments: IPaymentDto[]) => {
		this.setPayments(customerId, payments);
	};
//#endregion Payments

//#region MeterReads
	setMeterWithMeterReads = (customerId: number, documents: IMeterWithMeterReadsDto[]) => 
	{
		this.updateCustomerInfo(customerId, undefined, undefined, undefined, documents,
			(customerInfo: ICustomerInfoDto, contracts: IContractDto[] | undefined, documents: IDocumentDto[] | undefined, 
				payments: IPaymentDto[] | undefined, meterReads: IMeterWithMeterReadsDto[] | undefined) => {
				
				if (meterReads !== undefined) {
					arrayHelper.groupBy(meterReads, d => d.meter?.contractId).forEach((grouped, contractIdKey) => {
						var contractInfo = customerInfo.contractInfos?.find(x => x.contractWithDetails?.contract?.id === contractIdKey);
						if (contractInfo === undefined) {
							console.error(`setMeterWithMeterReads (customerId): contractInfo is missing`);
							return; //todo:
						}
						contractInfo.meterInfos = contractInfo.meterInfos ?? [];
						contractInfo.meterInfos = contractInfo!.meterInfos
							.filter(x => grouped.find(y => x.meter?.id === y.meter?.id) === undefined).concat(grouped);
					});
				} 
			});
	};

	addOrUpdateMeterReads = (customerId: number, payments: IMeterWithMeterReadsDto[]) => {
		this.setMeterWithMeterReads(customerId, payments);
	};
//#endregion MeterReads

	render() {
		return (
			<AppContext.Provider value={this.state.appContext}>
			<UserContext.Provider value={this.state.userContext}>
			  <div className={
					'app app-test app-sidebar-fixed ' + 
					(this.state.appContext.appHeaderNone ? 'app-without-header ' : 'app-header-fixed ') + 
					(this.state.appContext.appSidebarNone ? 'app-without-sidebar ' : '') + 
					(this.state.appContext.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
					(this.state.appContext.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
					(this.state.appContext.appSidebarLight ? 'app-with-light-sidebar ' : '') +
					(this.state.appContext.appSidebarMinify ? 'app-sidebar-minified ' : '') + 
					(this.state.appContext.appSidebarMobileToggled ? 'app-sidebar-mobile-toggled ' : '') + 
					(this.state.appContext.appTopMenu ? 'app-with-top-menu ' : '') + 
					(this.state.appContext.appContentFullHeight ? 'app-content-full-height ' : '') + 
					(this.state.appContext.appSidebarTwo ? 'app-with-two-sidebar ' : '') + 
					(this.state.appContext.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') + 
					(this.state.appContext.appSidebarEndMobileToggled ? 'app-sidebar-end-mobile-toggled ' : '') + 
					(this.state.appContext.hasScroll ? 'has-scroll ' : '')
				}>
					{!this.state.appContext.appHeaderNone && (<Header />)}
					{!this.state.appContext.appSidebarNone && (<Sidebar />)}
					{this.state.appContext.appSidebarTwo && (<SidebarRight />)}
					{this.state.appContext.appTopMenu && (<EbokTopMenu />)}
					{!this.state.appContext.appContentNone && (<Content />)}
					<FloatSubMenu />
					<ReactNotification />
					<Footer />

					<AddCustomer {...this.state.addCustomerModal} />
				</div> 
			</UserContext.Provider>
			</AppContext.Provider>
		)
	}
}

export default App;
