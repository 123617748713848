import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import { IUserInfoProps, UserContext } from '../config/user-context';
import { IProcessingModel } from '../helpers';
import { ContractTypeEnum, IContractSummaryInfoDto, IMessageDto } from '../models/generated';
import { contractsService } from '../services/contracts.service';
import { documentsService } from '../services/documents.service';
import { messagesService } from '../services/messages.service';
import ContractInfo from './components/contract-info';
import CustomerCard from './components/customer-card';
import CustomerSection from './components/customers-section';
import Message from './components/message';
import { ContractRelatedComponentBase } from './contract-related-base';


interface IHomeState extends IProcessingModel {
	messages: { value: IMessageDto[] } & IProcessingModel,
	contractSummaryInfoWater: { value: IContractSummaryInfoDto } & IProcessingModel,
	contractSummaryInfoWaste: { value: IContractSummaryInfoDto } & IProcessingModel,
	contractSummaryInfoAnother: { value: IContractSummaryInfoDto } & IProcessingModel,
}

class Home extends ContractRelatedComponentBase<IUserInfoProps, IHomeState> {
	static contextType = UserContext;
	
	constructor(props: IUserInfoProps) {
		super(props);
		this.state = { 
			isProcessing: false, 
			error: undefined, 
			messages: { isProcessing: false, error: undefined, value: [] },
			contractSummaryInfoWater: { isProcessing: false, error: undefined, value: {} },
			contractSummaryInfoWaste: { isProcessing: false, error: undefined, value: {} },
			contractSummaryInfoAnother: { isProcessing: false, error: undefined, value: {} },
		}
			
	}

	private setContractsAndDocuments() {
		if (this.props.userInfo?.selectedCustomerInfo?.customer?.id !== undefined) {
			this.setState({ 
				isProcessing: true, error: undefined, 
				contractSummaryInfoWater: { isProcessing: true, error: undefined, value: {} },
				contractSummaryInfoWaste: { isProcessing: true, error: undefined, value: {} },
				contractSummaryInfoAnother: { isProcessing: true, error: undefined, value: {} }
			});
			this.getContractsWithDetails(this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				.then((contracts) => {
					return documentsService.getDocuments(this.props.userInfo!.selectedCustomerInfo!.customer.id!);
				})
				.then((documents) => {
					this.context.setDocuments(this.props.userInfo!.selectedCustomerInfo!.customer.id!, documents);
				})
				.catch((err) => {
					this.setState({ error: err, isProcessing: false });
				});

			contractsService.getContractsSummary(this.props.userInfo?.selectedCustomerInfo?.customer?.id, ContractTypeEnum.Water)
				.then((items) => {
					this.setState({ contractSummaryInfoWater: { isProcessing: false, error: undefined, value: items } });
				})
				.catch((err) => {
					this.setState({ contractSummaryInfoWater: { isProcessing: false, error: err, value: {} } });
				});
			
			contractsService.getContractsSummary(this.props.userInfo?.selectedCustomerInfo?.customer?.id, ContractTypeEnum.Waste)
				.then((items) => {
					this.setState({ contractSummaryInfoWaste: { isProcessing: false, error: undefined, value: items } });
				})
				.catch((err) => {
					this.setState({ contractSummaryInfoWaste: { isProcessing: false, error: err, value: {} } });
				});

			contractsService.getContractsSummary(this.props.userInfo?.selectedCustomerInfo?.customer?.id, ContractTypeEnum.Another)
				.then((items) => {
					this.setState({ contractSummaryInfoAnother: { isProcessing: false, error: undefined, value: items } });
				})
				.catch((err) => {
					this.setState({ contractSummaryInfoAnother: { isProcessing: false, error: err, value: {} } });
				});
		}
	}

	componentDidMount() {
		

		this.setContractsAndDocuments();

		this.setState({ 
			messages: { isProcessing: true, value: [], error: undefined }
		});
		messagesService.getMessages(true, 0, undefined)
			.then((res) => {
				this.setState({ messages: { value: [...res], error: undefined, isProcessing: false } });
			})
			.catch((err) => {
				this.setState({ messages: { value: [], error: err, isProcessing: false } });
			})	
	}
	

	componentDidUpdate(prevProps: IUserInfoProps) {
		if (prevProps.userInfo?.selectedCustomerInfo?.customer?.id !== this.props.userInfo?.selectedCustomerInfo?.customer?.id) {
			this.setContractsAndDocuments();
		}
	}
	
	render() {
		return (
			<>
				
				<div>
					<div className="row justify-content-end mb-15px me-1px">
						<CustomerSection {...this.props} />
					</div>
				
					<div className="row">
						<div className="col-xl-6">
							<Panel className="home-comp-panel" reload={this.state.messages.isProcessing}>
								<PanelHeader noButton="true">Twoje dane</PanelHeader>
								<PanelBody className="home-comp-panel-body">
								{
									this.props.userInfo.isLoggedIn && this.props.userInfo.selectedCustomerInfo?.customer &&
									<CustomerCard displayRemoveButton={false} userContextData={this.context} />
								}
								</PanelBody>
							</Panel>
						</div>
						<div className="col-xl-6">
							<Panel className="home-comp-panel" reload={this.state.messages.isProcessing}>
								<PanelHeader noButton="true">Wiadomości</PanelHeader>
								<PanelBody className="home-comp-panel-body">
								{
									(this.state.messages.error !== undefined) ? 
										<div><span>
											{/* {this.state.contracts.error.message} */}
											Wystąpił błąd, skontaktuj się z administratorem.
										</span></div>
										:	
										(
											(this.state.messages.value.some(x => true)) ?
												this.state.messages.value?.map((value, i) => (
													<Message message={value} key={value.id}>
													</Message>
												))
												:
												<span>Nie masz żadnej wiadomości</span>
										)
								}
								</PanelBody>
							</Panel>	
								
						</div>
						<div className="w-100"></div>
						<div className="col-xl-4">
							<Panel className="home-comp-panel home-comp-panel-contracts" reload={this.state.contractSummaryInfoWater.isProcessing}>
								<PanelHeader noButton="true">Woda</PanelHeader>
								<PanelBody className="home-comp-panel-body">
								{
									(this.state.contractSummaryInfoWater.error) ? 
										<div><span>
											Wystąpił błąd, skontaktuj się z administratorem.
										</span></div>
									:										
									<ContractInfo contractSummaryInfoDto={this.state.contractSummaryInfoWater.value} >
									</ContractInfo>
								}
								</PanelBody>
							</Panel>
						</div>
						<div className="col-xl-4">
							<Panel className="home-comp-panel home-comp-panel-contracts" reload={this.state.contractSummaryInfoWaste.isProcessing}>
								<PanelHeader noButton="true">Odpady</PanelHeader>
								<PanelBody className="home-comp-panel-body">
								{
									(this.state.contractSummaryInfoWaste.error !== undefined) ? 
										<div><span>
											{/* {this.state.contracts.error.message} */}
											Wystąpił błąd, skontaktuj się z administratorem.
										</span></div>
										:
										<ContractInfo contractSummaryInfoDto={this.state.contractSummaryInfoWaste.value} >
										</ContractInfo>
								}
								</PanelBody>
							</Panel>
						</div>
						<div className="col-xl-4 ">
							<Panel className="home-comp-panel home-comp-panel-contracts" reload={this.state.contractSummaryInfoAnother.isProcessing}>
								<PanelHeader noButton="true">Inne</PanelHeader>
								<PanelBody className="home-comp-panel-body">
								{
									(this.state.contractSummaryInfoAnother.error !== undefined) ? 
										<div><span>
											{/* {this.state.contracts.error.message} */}
											Wystąpił błąd, skontaktuj się z administratorem.
										</span></div>
										:
										<ContractInfo contractSummaryInfoDto={this.state.contractSummaryInfoAnother.value} >
										</ContractInfo>
								}
								</PanelBody>
							</Panel>
						</div>
						
					</div>
					
				</div>
			</>
		)
	}
}

export default Home;