import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import React from 'react';
import { staticFilesService } from '../services/static-files.service';
import { IProcessingModel } from '../helpers';
// var perf =require('./test.html');

interface IRegulationsProc extends IProcessingModel {
	innerHtml: string;
}


class Regulations extends  React.Component<{}, IRegulationsProc> { 
	constructor(props: {}) {
		super(props);
		this.state = {innerHtml: '', isProcessing: false, error: undefined}
	}

	componentDidMount() {
		this.setState({ innerHtml: '', isProcessing: true, error: undefined });
		staticFilesService.getRegulations()
			.then(responce => this.setState({ innerHtml: responce, isProcessing: false, error: undefined }))
			.catch(err => { 
				this.setState({ innerHtml: `<p>Wystąpił błąd: ${err}.</p>`, isProcessing: false, error: err });
				console.error(err);
			});
	}

	render() { 
		 
		return (
			<div>
				<Panel >
					<PanelHeader noButton="true">Regulamin</PanelHeader>
					<PanelBody> 
						<div dangerouslySetInnerHTML={{ __html: this.state?.innerHtml }}>
						</div>
					</PanelBody>
				</Panel>
			</div>
		)
	}
}

export default Regulations; 
