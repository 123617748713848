import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { from } from 'rxjs';
import { accountService } from '../../services/account.service';
import { AppContext } from '../../config/app-settings';
import { IUserContextData } from '../../config/user-context';
import { withUseFormHook } from '../../helpers';
import { alertService } from '../../services/alert.service';
import { ResetPasswordRequest } from '../../models/generated';
import queryString from 'query-string';
import { first } from 'rxjs/operators';

interface IResetPasswordState {
	redirect: boolean
}
interface IResetPasswordProps extends UseFormReturn<FormInputs, any> {
	userContext: IUserContextData
}

type FormInputs = {
	password: string,
	confirmPassword: string
};

class ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordState> {
	static contextType = AppContext;
	
	constructor(props: any) {
		super(props);
		
		this.state = {
			redirect: false
		}
	}
	
	onSubmit = (data: FormInputs) => {
		const { token } = queryString.parse(window.location.search);
		from(accountService.resetPassword(new ResetPasswordRequest({token: token as string, ...data})))
		.pipe(first())
		.subscribe({
			next: val => { 
				console.log(`Set Password Success: ${val}`); 
				alertService.info(`Ustaienie hasła`, `Nowe hasło zostało ustawione`, { autoClose: true }); 
				this.setState(state => ({ redirect: true }));
			},
			complete: () => console.log('Set Password Complete!'),
			error: val => { 
				console.log(`Set Password Error: ${val}`); 
				alertService.error(`Ustaienie hasła`, `${val}`, { autoClose: true }); 
			} 
		});	
  	}
  
	render() {
		const { register, handleSubmit, formState: { errors } } = this.props;
		

		if (this.state.redirect) {
			return <Redirect to='/'/>;
	 	}
		
		return (
			<div className="register register-with-news-feed">
				<div className="register-container">
					<div className="register-header mb-25px h1">
						<div className="mb-1">Ustawienie hasła</div>
					</div>
					<div className="register-content">
						<form onSubmit={handleSubmit(this.onSubmit)} className="fs-13px">
							<div className="mb-4">
								<label className="mb-2">Hasło <span className="text-danger">*</span></label>
								<input type="password" className="form-control fs-13px" placeholder="Hasło" 
									{...register("password")} />
								{errors.password && <p>{errors.password.message}</p>}
							</div>
							<div className="mb-4">
								<label className="mb-2">Potwierdz hasło <span className="text-danger">*</span></label>
								<input type="password" className="form-control fs-13px" placeholder="Potwierdz hasło" 
									{...register("confirmPassword")}/>
								{errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
							</div>
							<div className="mb-4">
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Zmień hasło</button>
							</div>
							<hr className="bg-gray-600 opacity-2" />
						</form>
					</div>
				</div>
			</div>	
		)
	}
}

const formSchema = Yup.object().shape({
	password: Yup.string()
		.required('Hasło jest wymagane')
		.min(6, 'Hasło musi mieć conajmniej 6 znaki'),
	confirmPassword: Yup.string()
		.required('Hasło jest wymagane')
		.oneOf([Yup.ref('password')], 'Hasła nie są identyczne'),
})
const formOptions = { resolver: yupResolver(formSchema) }

export default withUseFormHook(ResetPassword, formOptions);


