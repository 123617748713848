
import { fetchWrapper, urlHelper } from "../helpers";
import { IMeterWithMeterReadsDto } from "../models/generated";
import { getConfiguration } from "./config";

export const metersService = {
  getMetersWithMeterReads,
  getMetersWithMeterReadsByIds
};

async function getMetersWithMeterReads(customerId: number): Promise<IMeterWithMeterReadsDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/meters/getMetersWithMeterReads/${customerId}`);
  const res: IMeterWithMeterReadsDto[] = resp;
  return res;
}

async function getMetersWithMeterReadsByIds(ids: number[]): Promise<IMeterWithMeterReadsDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/meters/getMetersWithMeterReadsByIds${urlHelper.parameterizeArray('ids', ids)}`);
  const res: IMeterWithMeterReadsDto[] = resp;
  return res;
}