import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormReturn } from 'react-hook-form';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { IUserContextData, UserContext } from '../../config/user-context';
import { IProcessingModel, withUseFormHook } from '../../helpers';
import { IModalDialogProps } from '../../models/models';
import { accountService } from '../../services/account.service';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { alertService } from '../../services/alert.service';
import { UpdateRequest } from '../../models/generated';
 
export interface IEditProfileModalProps 
	extends IModalDialogProps, UseFormReturn<EditProfileFormInputs, any> {
		userContext: IUserContextData
}

export interface IEditProfileModalState extends IProcessingModel
{
}

type EditProfileFormInputs = {
	//customerNo: string,
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	confirmPassword: string
};

class EditProfile extends React.Component<IEditProfileModalProps, IEditProfileModalState> {
	static contextType = UserContext;

	constructor(props: IEditProfileModalProps) {
		super(props);
		this.state = { 
			isProcessing: false,
			error: undefined
		};
	}

	componentDidMount() {
		console.log('componentDidMount');
	}

	componentDidUpdate(prevProps: IEditProfileModalProps) {
		if (prevProps?.userContext?.userInfo !== this.props.userContext?.userInfo) {
			if (this.props.userContext?.userInfo?.isLoggedIn) {
				this.props.setValue('firstName', this.props.userContext?.userInfo.firstName);
				this.props.setValue('lastName', this.props.userContext?.userInfo.lastName);
			} else {
				this.props.setValue('firstName', '');
				this.props.setValue('lastName', '');
			}

		}
	}

	onSubmit = (data: EditProfileFormInputs) => {	
		this.setState((prevState) => ({ isProcessing: true, error: undefined }));	
		
		from(accountService.update(new UpdateRequest({ ...data })))
		.pipe(first())
		.subscribe({
			next: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	
				this.props.userContext.login(val);
				alertService.info(`Aktualizacja klienta`, `Aktualizacja klienta powiodła się`, { autoClose: true }); 	
				this.toggle();			
			},
			error: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	
				alertService.error(`Aktualizacja klienta nie powiodła się`, `${val}`, { autoClose: true }); 
			} 
		});	
  }

	toggle = () => {
		this.props.closeModalDialog(undefined);
	}
	render() {
		const { register, handleSubmit, formState: { errors } } = this.props;

		return (
			<Modal isOpen={this.props.isModalDialogOpened} >
				<form onSubmit={handleSubmit(this.onSubmit)}  >
					<ModalHeader toggle={this.toggle}>Edytuj dane użytkownika</ModalHeader>
					<ModalBody>
						<div className="form-floating mb-15px">
							<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Imię" id="firstName" 
								{...register("firstName")}/>
							<label htmlFor="firstName" className="d-flex align-items-center fs-13px text-gray-600">Imię</label>
							{errors.firstName && <p>{errors.firstName.message}</p>}
						</div>
						<div className="form-floating mb-15px">
							<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Nazwisko" id="lastName" 
								{...register("lastName")}/>
							<label htmlFor="lastName" className="d-flex align-items-center fs-13px text-gray-600">Nazwisko</label>
							{errors.lastName && <p>{errors.lastName.message}</p>}
						</div> 
						<div className="form-floating mb-4">							
							<input readOnly={this.state.isProcessing} type="password" className="form-control h-45px fs-13px" placeholder="Hasło" id="password"
								{...register("password")} />
							<label htmlFor="password" className="d-flex align-items-center fs-13px text-gray-600">Hasło <span className="text-danger">*</span></label>
							{errors.password && <p>{errors.password.message}</p>}
						</div>
						<div className="form-floating mb-4">							
							<input type="password" className="form-control fs-13px" placeholder="Potwierdz hasło" id="confirmPassword"
								{...register("confirmPassword")}/>
							<label htmlFor="confirmPassword" className="d-flex align-items-center fs-13px text-gray-600">Potwierdz hasło <span className="text-danger">*</span></label>
							{errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
						</div>
					</ModalBody>
					<ModalFooter>
						<Button disabled={this.state.isProcessing} color="primary" type="submit">
								{this.state.isProcessing && <Spinner className="spinner-border spinner-border-sm" animation="border" variant="primary" />}
								Aktualizuj</Button>
						<Button color="secondary" onClick={this.toggle}>Anuluj</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

const formSchema = Yup.object().shape({
	firstName: Yup.string()
		.required('Imię jest wymagane'),
	lastName: Yup.string()
		.required('Nazwisko jest wymagane'),
	password: Yup.string()
		//.required('Hasło jest wymagane')
		//.min(6, 'Hasło musi mieć conajmniej 6 znaki'),
		.matches(/.{6,}/, {
			excludeEmptyString: true,
			message: 'Hasło musi mieć conajmniej 6 znaki',
		}),
	confirmPassword: Yup.string()
		//.required('Hasło jest wymagane')
		.matches(/.{6,}/, {
			excludeEmptyString: true,
			message: 'Hasło musi mieć conajmniej 6 znaki',
		})
		.oneOf([Yup.ref('password')], 'Hasła nie są identyczne'),
})
const formOptions = { resolver: yupResolver(formSchema) }

export default withUseFormHook(EditProfile, formOptions);