import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import React from 'react';
import { staticFilesService } from '../services/static-files.service';
import { IProcessingModel } from '../helpers';

interface IPrivacyPolicyProc extends IProcessingModel {
	innerHtml: string;
}

class PrivacyPolicy extends  React.Component<{}, IPrivacyPolicyProc> { 
	constructor(props: {}) {
		super(props);
		this.state = {innerHtml: '', isProcessing: false, error: undefined}
	}

	componentDidMount() {
		this.setState({ innerHtml: '', isProcessing: true, error: undefined });
		staticFilesService.getPrivatePolicy()
			.then(responce => this.setState({ innerHtml: responce, isProcessing: false, error: undefined }))
			.catch(err => { 
				this.setState({ innerHtml: `<p>Wystąpił błąd: ${err}.</p>`, isProcessing: false, error: err });
				console.error(err);
			});
	}

	render() { 
		 
		return (
			<div>
				
				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true">Polityka prywatności</PanelHeader>
					<PanelBody> 
					<div dangerouslySetInnerHTML={{ __html: this.state?.innerHtml }}>
						</div>
					</PanelBody>
				</Panel>
			</div>
		)
	}
}

export default PrivacyPolicy; 