import { accountService } from "../services/account.service";
import { getConfiguration } from "../services/config";
import { saveAs } from 'file-saver';

export interface IProcessingModel
{
	isProcessing: boolean;
    error: string | undefined;
}

export const fetchWrapper = {
    get,
    getFile,
    saveToFileSystem,
    post,
    postFiles,
    put,
    delete: _delete
}

export const urlHelper = {
    parameterizeArray
}

function parameterizeArray(key: string, arr: any[]) : string {
    var arrEncode = arr.map(encodeURIComponent)
    return '?'+key+'=' + arrEncode.join('&'+key+'=')
}
  
  
function get(url: string) {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function getFile(url: string, actoin: (response: Response, blob: Blob) => any) {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: { 'Accept': 'application/json', ...authHeader(url) },
            credentials: 'include'
        };
        return fetch(url, requestOptions)
            .then(responce => handleResponseBlob(responce, actoin));
}

function post(url: string, body: any) {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        credentials: 'include',
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function postFiles(url: string, values: any) {
    //'Content-Type': 'Multipart/form-data'
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: { 'Accept': 'application/json', ...authHeader(url) },
            credentials: 'include',
            body: values
        };
        return fetch(url, requestOptions).then(handleResponse);
}

function put(url: string, body: any) {
    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);    
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string) {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url: string): HeadersInit {
    // return auth header with jwt if user is logged in and request is to the api url
    const user = accountService.userValue;
    const isLoggedIn = user && user.jwtToken;
    const isApiUrl = url.startsWith(getConfiguration().apiUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.jwtToken}` };
    } else {
        return {};
    }
}

function handleResponse(response: Response) {
    return response.text().then((text: any) => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if ([401, 403].includes(response.status) && accountService.userValue) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                accountService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponseBlob(response: Response, action: (response: Response, blob: Blob) => any)  {
    console.log('handleResponseBlob')
    return response.blob()
        .then((blob: Blob) => {
            if (!response.ok) {
                if ([401, 403].includes(response.status) && accountService.userValue) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    accountService.logout();
                }

                const error = response.statusText;
                return Promise.reject(error);
            }

            if (action !== undefined) {
                return action(response, blob);
            }
            return blob;
        });
}

function saveToFileSystem(response: Response, blob: Blob) {
    if (response) {
        const contentDispositionHeader = response.headers.get(
            'Content-Disposition'
        );
        
        if (contentDispositionHeader) {
            const parts: string[] = contentDispositionHeader.split(';');
            const filename = parts[1].split('=')[1].split('"').join('');
            const type = response.headers.get('Content-Type');
            const blobWithType = new Blob([blob], {
                type: (type === null ? undefined : type),
            });
            saveAs(blobWithType, filename);
        }
    }
  }