import React from 'react';

export interface IAppContext 
{
	appHeaderNone: boolean,
	appHeaderInverse: boolean,
	appHeaderMegaMenu: boolean,
	appHeaderLanguageBar: boolean,
	hasScroll: boolean,
	handleSetAppHeaderNone: (e: any) => void,
	handleSetAppHeaderInverse: (e: any) => void,
	handleSetAppHeaderLanguageBar: (e: any) => void,
	handleSetAppHeaderMegaMenu: (e: any) => void,
	
	appSidebarNone: boolean,
	appSidebarWide: boolean,
	appSidebarLight: boolean,
	appSidebarMinify: boolean,
	appSidebarMobileToggled: boolean,
	appSidebarTransparent: boolean,
	appSidebarSearch: boolean,
	handleSetAppSidebarNone: (e: any) => void,
	handleSetAppSidebarWide: (e: any) => void,
	handleSetAppSidebarLight: (e: any) => void,
	handleSetAppSidebarMinified: (e: any) => void,
	handleSetAppSidebarTransparent: (e: any) => void,
	handleSetAppSidebarSearch: (e: any) => void,
	handleAppSidebarOnMouseOut: (e: any) => void,
	handleAppSidebarOnMouseOver: (e: any, menu: any) => void,
	toggleAppSidebarMinify: (e: any) => void,
	toggleAppSidebarMobile: (e: any) => void,
	
	appSidebarFloatSubMenuActive: boolean,
	appSidebarFloatSubMenu: string,
	appSidebarFloatSubMenuTop: string,
	appSidebarFloatSubMenuLeft: string,
	appSidebarFloatSubMenuBottom: string,
	appSidebarFloatSubMenuLineTop: string,
	appSidebarFloatSubMenuLineBottom: string,
	appSidebarFloatSubMenuArrowTop: string,
	appSidebarFloatSubMenuArrowBottom: string,
	appSidebarFloatSubMenuOffset: any,
	handleAppSidebarFloatSubMenuOnMouseOver: (e: any) => void,
	handleAppSidebarFloatSubMenuOnMouseOut: (e: any) => void,
	handleAppSidebarFloatSubMenuClick: () => void,
	
	appContentNone: boolean,
	appContentClass: string,
	appContentFullHeight: boolean,
	handleSetAppContentNone: (e: any) => void,
	handleSetAppContentClass: (e: any) => void,
	handleSetAppContentFullHeight: (e: any) => void,
	
	appTopMenu: boolean,
	appTopMenuMobileToggled: boolean,
	toggleAppTopMenuMobile: (e: any) => void,
	handleSetAppTopMenu: (e: any) => void,
	
	appSidebarTwo: boolean,
	handleSetAppSidebarTwo: (e: any) => void,
	
	appSidebarEnd: boolean,
	appSidebarEndToggled: boolean,
	appSidebarEndMobileToggled: boolean,
	toggleAppSidebarEnd: (e: any) => void,
	toggleAppSidebarEndMobile: (e: any) => void,
	handleSetAppSidebarEnd: (e: any) => void,
	
	handleSetAppBoxedLayout: (e: any) => void
}

export const IAppContextDefault = {	
    appHeaderNone: false,
    appHeaderInverse: false,
    appHeaderMegaMenu: false,
    appHeaderLanguageBar: false,
    hasScroll: false,
    appSidebarNone: true,
    appSidebarWide: false,
    appSidebarLight: false,
    appSidebarMinify: false,
    appSidebarMobileToggled: false,
    appSidebarTransparent: false,
    appSidebarSearch: false,
    appSidebarFloatSubMenuActive: false,
    appSidebarFloatSubMenu: '',
    appSidebarFloatSubMenuTop: 'auto',
    appSidebarFloatSubMenuLeft: 'auto',
    appSidebarFloatSubMenuBottom: 'auto',
    appSidebarFloatSubMenuLineTop: 'auto',
    appSidebarFloatSubMenuLineBottom: 'auto',
    appSidebarFloatSubMenuArrowTop: 'auto',
    appSidebarFloatSubMenuArrowBottom: 'auto',
    appSidebarFloatSubMenuOffset: '',
    appContentNone: false,
    appContentClass: '',
    appContentFullHeight: false,
    appTopMenu: true,
    appTopMenuMobileToggled: false,
    appSidebarTwo: false,
    appSidebarEnd: false,
    appSidebarEndToggled: false,
    appSidebarEndMobileToggled: false,


    toggleAppSidebarMinify: (e: any) => null,
    toggleAppSidebarMobile: (e: any) => null,
    handleSetAppSidebarNone: (value: any) => null,
    handleSetAppSidebarMinified: (value: any) => null,
    handleSetAppSidebarWide: (value: any) => null,
    handleSetAppSidebarLight: (value: any) => null,
    handleSetAppSidebarTransparent: (value: any) => null,
    handleSetAppSidebarSearch: (value: any) => null,
    toggleAppSidebarEnd: (e: any) => null,
    toggleAppSidebarEndMobile: (e: any) => null,
    handleSetAppSidebarEnd: (value: any) => null,
    handleAppSidebarFloatSubMenuOnMouseOver: (e: any) => null,
    handleAppSidebarFloatSubMenuOnMouseOut: (e: any) => null,
    handleAppSidebarOnMouseOver: (e: any, menu: any) => null,
    handleAppSidebarOnMouseOut: (e: any) => null,
    handleAppSidebarFloatSubMenuClick: () =>null,
    handleSetAppContentNone: (value: any) => null,
    handleSetAppContentClass: (value: any) => null,
    handleSetAppContentFullHeight: (value: any) => null,
    handleSetAppHeaderNone: (value: any) => null,
    handleSetAppHeaderInverse: (value: any) => null,
    handleSetAppHeaderMegaMenu: (value: any) => null,
    handleSetAppHeaderLanguageBar: (value: any) => null,
    handleSetAppTopMenu: (value: any) => null,
    toggleAppTopMenuMobile: (e: any) => null,
    handleSetAppSidebarTwo: (value: any) => null,
    handleSetAppBoxedLayout: (value: any) => null
}

export const AppContext = React.createContext<IAppContext>(IAppContextDefault);