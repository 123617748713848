
import { fetchWrapper, urlHelper } from "../helpers";
import { IDocumentDto } from "../models/generated";
import { getConfiguration } from "./config";

export const documentsService = {
  getDocuments,
  getDocumentsByIds,
  getInvoiceFile
};

async function getDocuments(customerId: number): Promise<IDocumentDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/documents/getDocuments/${customerId}`);
  const res: IDocumentDto[] = resp;
  return res;
}

async function getDocumentsByIds(ids: number[]): Promise<IDocumentDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/documents/getDocumentsByIds${urlHelper.parameterizeArray('ids', ids)}`);
  const res: IDocumentDto[] = resp;
  return res;
}

async function getInvoiceFile(invoiceId: string): Promise<any> {
  const resp = await fetchWrapper
      .getFile(getConfiguration().apiUrl + `/documents/getInvoiceFile/${encodeURIComponent(invoiceId)}`,
      fetchWrapper.saveToFileSystem);
  return resp;
}
