import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { UserContext } from '../../config/user-context';
 

class CustomerSelector extends React.Component<React.HTMLAttributes<HTMLDivElement>> {
	static contextType = UserContext;

	handleChangeCustomer = (ev: any) => {
		ev.persist();
		this.context.setSelectedCustomer(+ev.target.value);
	}

	render() {
		return (
			<UserContext.Consumer>
			{userContext => (
				<FloatingLabel label="Wybierz klienta" className={ this.props.className } >
					<Form.Select value={userContext.userInfo.selectedCustomerInfo?.customer?.id} 	onChange={this.handleChangeCustomer}>
						{
							userContext.userInfo.customerInfos.map((value, i) => (
										<option key={i} value={value.customer?.id}>{value.customer?.name}</option>
									))
						}
				</Form.Select>
			</FloatingLabel>

				// <select className={ this.props.className } 
				// 	value={userContext.userInfo.selectedCustomerInfo?.customer?.id} 
				// 	onChange={this.handleChangeCustomer}>
				// 	{/* <option value={undefined}  key={-1}></option> */}
				// 	{
				// 		userContext.userInfo.customerInfos.map((value, i) => (
				// 			<option value={value.customer?.id}  key={i}>{value.customer?.name}</option>
				// 		))
				// 	}			
				// </select>
			)}
			</UserContext.Consumer>
		);
	}
}

export default CustomerSelector;