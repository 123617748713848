import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { from } from 'rxjs';
import { accountService } from '../../services/account.service';
import { AppContext } from '../../config/app-settings';
import { IUserContextData } from '../../config/user-context';
import { withUseFormHook } from '../../helpers';
import { alertService } from '../../services/alert.service';
import { RegisterRequest } from '../../models/generated';
import { first } from 'rxjs/operators';

interface IRegisterState {
	redirect: boolean
}
interface IRegisterProps extends UseFormReturn<FormInputs, any> {
	userContext: IUserContextData
}

type FormInputs = {
	firstName: string,
	lastName: string,
	email: string,
	emailConfirm: string,
	password: string,
	passwordConfirm: string,
	acceptTerms: boolean
};

class Register extends React.Component<IRegisterProps, IRegisterState> {
	static contextType = AppContext;
	
	constructor(props: any) {
		super(props);
		
		this.state = {
			redirect: false
		}
	}
	
	onSubmit = (data: FormInputs) => {
		
		from(accountService.register(new RegisterRequest({...data})))
		.pipe(first())
		.subscribe({
			next: val => { 
				console.log(`Registration Success: ${val}`); 
				alertService.info(`Rejestracja użytkownika`, `Udana rejestracja, sprawdz skrzynkę odbiorczą`, { autoClose: true }); 
				this.setState(state => ({ redirect: true }));
			},
			complete: () => console.log('Registration Complete!'),
			error: val => { 
				console.log(`Registration Error: ${val}`); 
				alertService.error(`Rejestracja użytkownika`, `${val}`, { autoClose: true }); 
			} 
		});	
  	}
  
	render() {
		const { register, handleSubmit, formState: { errors } } = this.props;

		if (this.state.redirect) {
			return <Redirect to='/'/>;
	 	}
		
		return (
			<div className="register register-with-news-feed">
				<div className="register-container">
					<div className="register-header mb-25px h1">
						<div className="mb-1">Rejestracja Użytkownika</div>
					</div>
					<div className="register-content">
						<form onSubmit={handleSubmit(this.onSubmit)} className="fs-13px">
							<div className="mb-3">
								<label className="mb-2">Dane użytkownika <span className="text-danger">*</span></label>
								<div className="row gx-3">
									<div className="col-md-6 mb-2 mb-md-0">
										<input type="text" className="form-control fs-13px" placeholder="Imię"
										 	{...register("firstName", { required: "Imię jest wymagane"}) }/>
										{errors.firstName && <p>{errors.firstName.message}</p>}
									</div>
									<div className="col-md-6">
										<input type="text" className="form-control fs-13px" placeholder="Nazwisko" 
											{...register("lastName", { required: "Nazwisko jest wymagane"}) }/>
										{errors.lastName && <p>{errors.lastName.message}</p>}
									</div>
								</div>
							</div>
							<div className="mb-3">
								<label className="mb-2">Adres email <span className="text-danger">*</span></label>
								<input type="text" className="form-control fs-13px" placeholder="Adres email" 
									{...register("email")}/>
								{errors.email && <p>{errors.email.message}</p>}
							</div>
							<div className="mb-3">
								<label className="mb-2">Potwierdz adres email <span className="text-danger">*</span></label>
								<input type="text" className="form-control fs-13px" placeholder="Potwierdz adres email" 
									{...register("emailConfirm")}/>
								{errors.emailConfirm && <p>{errors.emailConfirm.message}</p>}
							</div>
							<div className="mb-4">
								<label className="mb-2">Hasło <span className="text-danger">*</span></label>
								<input type="password" className="form-control fs-13px" placeholder="Hasło" 
									{...register("password")} />
								{errors.password && <p>{errors.password.message}</p>}
							</div>
							<div className="mb-4">
								<label className="mb-2">Potwierdz hasło <span className="text-danger">*</span></label>
								<input type="password" className="form-control fs-13px" placeholder="Potwierdz hasło" 
									{...register("passwordConfirm")}/>
								{errors.passwordConfirm && <p>{errors.passwordConfirm.message}</p>}
							</div>
							<div className="form-check mb-4">								
								<input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" 
									{...register("acceptTerms")}/>
								<label className="form-check-label" htmlFor="agreementCheckbox">
								Oświadczam, że zapoznałem się z treścią <Link to="/regulamin">regulaminu</Link> korzystania z portalu i akceptuję go. 
								Wyrażam zgodę na przetwarzanie danych osobowych oraz zapoznałem się z <Link to="/polityka-prywatnosci">polityką prywatności</Link>.
								</label>
								{errors.acceptTerms && <p>{errors.acceptTerms.message}</p>}
							</div>
							<div className="mb-4">
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Rejestruj</button>
							</div>
							<div className="mb-4 pb-5">
								Masz już konto? <Link to="/login">Zaloguj się</Link>.
							</div>
							
						</form>
					</div>
				</div>
			</div>	
		)
	}
}

const formSchema = Yup.object().shape({
	email: Yup.string()
		.required('Adres email jest wymagany')
		.email('Niepoprawny format adresu email'),
	emailConfirm: Yup.string()
		.required('Adres email jest wymagany')
		.oneOf([Yup.ref('email')], 'Adresy email nie są identyczne'),
	password: Yup.string()
		.required('Hasło jest wymagane')
		.min(6, 'Hasło musi mieć conajmniej 6 znaki'),
	passwordConfirm: Yup.string()
		.required('Hasło jest wymagane')
		.oneOf([Yup.ref('password')], 'Hasła nie są identyczne'),
	acceptTerms: Yup.boolean()
		.required("Musisz zatwierdzić warunki")
		.oneOf([true], "Musisz zatwierdzić warunki")
})
const formOptions = { resolver: yupResolver(formSchema) }

export default withUseFormHook(Register, formOptions);


