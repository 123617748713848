
import { fetchWrapper } from "../helpers";
import { IDeclarationDto } from "../models/generated";
import { getConfiguration } from "./config";

export const declarationsService = {
  getDeclarations,
  submitDeclaration
};

async function getDeclarations(customerId: number, contractId: number): Promise<IDeclarationDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/declarations/getDeclarations/${customerId}/${contractId}`);
  const res: IDeclarationDto[] = resp;
  return res;
}

async function submitDeclaration(customerId: number, contractId: number, content: string): Promise<IDeclarationDto> {        
  const resp = await fetchWrapper.post(getConfiguration().apiUrl + `/declarations/submitDeclaration/${customerId}/${contractId}`, content);
  const res: IDeclarationDto = resp;
  return res;
}