import React, { FormEvent } from 'react';
import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import { IUserInfoProps } from '../config/user-context';
import { withParams } from '../helpers';
import { FieldTypeEnum, IFormSubjectDto, IFormTemplateDto  } from "../models/generated"
import { applicationsAndComplainsService } from '../services/applications-and-complains.service';
import CustomerSection from './components/customers-section';

interface IFormTemplateDtoWithValue extends IFormTemplateDto {
	value?: any;
}
interface IApplicationsAndComplainsState {
	formSubjects: {
		isLoading: boolean;
		values: IFormSubjectDto[];
		error?: any;			
	},
	selectedFormSubjectId: string,
	formTemplates: {
		isLoading: boolean;
		values: IFormTemplateDtoWithValue[];
		error?: any;
	}
	isSending: boolean;
	sendingError?: any;
	formWasSend: boolean;
}

const defaultState: IApplicationsAndComplainsState = { 
	formSubjects: {
		isLoading: false,
		values: [],
		error: undefined
	},
	selectedFormSubjectId: "",
	formTemplates: {
		isLoading: false,
		values: [],
		error: undefined
	},
	formWasSend: false,
	isSending: false,
	sendingError: undefined 
}

type FormIdParams = {
	formId: string;
};

interface IApplicationsAndComplainsProps extends IUserInfoProps {
	params: FormIdParams;
}
class ApplicationsAndComplains extends React.Component<IApplicationsAndComplainsProps, IApplicationsAndComplainsState> {
	constructor(props: any) {
		super(props);
		this.state = defaultState;		
	}

	handleAfterSending = (ev: any) => {
		this.setState(state => ({...defaultState, formSubjects: state.formSubjects}));
	}

	handleSubjectOnChange = (ev: any) => {
		ev.persist();		
		this.loadFormTemplates(ev?.target?.value)
	}

	loadFormTemplates = (formId: any) => {
		this.setState(preState => ({...preState, selectedFormSubjectId: formId, 
			formTemplates: { isLoading: (formId !== undefined ), error: undefined, values: []} 
		}));
		
		if (formId !== undefined && formId !== "")
		{
			var numberFormId = parseInt(formId, 10);
			if (!isNaN(numberFormId)) {
				applicationsAndComplainsService.getFormTemplates(numberFormId)
					.then((res) => {
						this.setState(preState => ({...preState, formTemplates: { values: res, error: undefined, isLoading: false }}));
					})
					.catch((err) => this.setState(preState => ({...preState, formTemplates: { values: [], error: err, isLoading: false }})))
			}
		}
	}

	handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		this.setState(preState => ({...preState,  isSending: true, sendingError: undefined }));
		var formData = new FormData();
		
		this.state.formTemplates.values.forEach(x => {
				formData.append(`id_${x.id}`, x?.id?.toString() || '');
				formData.append(`title_${x.id}`, x?.title?.toString() || '');
				formData.append(`fieldType_${x.id}`, x?.fieldType?.toString() || '');
				if (x.fieldType === FieldTypeEnum.File) {
					var form = event.target as HTMLFormElement;
					var fileInput = Array.from(form.elements).find(e => e.id === `id${x.id}`) as HTMLInputElement;
					var file = (fileInput?.files !== null && fileInput.files.length > 0) ? fileInput.files[0] : undefined;
					formData.append(`value_${x.id}`, file || '');
				} else {
					formData.append(`value_${x.id}`, x?.value?.toString() || '');
				}
			});
			applicationsAndComplainsService.sendApplicationAndComplainForm(parseInt(this.state.selectedFormSubjectId), formData, this.props.userInfo?.selectedCustomerInfo?.customer?.id)
				.then((res) => {
					this.setState(prev => ({...prev, isSending: false, formWasSend: true}));
				})
				.catch((err) => {
					this.setState(preState => ({...preState, isSending: false, sendingError: err }))
				});
		
		event.preventDefault();
	}
	
	handleClear = (event: React.MouseEvent<HTMLElement>) => {
		this.setState(defaultState);
		event.preventDefault();
	}

	componentDidMount() {
		this.refreshFormSubjects();
	
	}

	refreshFormSubjects() {
		this.setState(preState => ({...preState, formSubjects: { error: undefined, isLoading: true, values: [] }}));
		applicationsAndComplainsService.getFormSubjects()
			.then((res) => {
				this.setState(preState => ({...preState, formSubjects: { values: res, error: undefined, isLoading: false }}));
				if (this.props.params?.formId) {
					this.loadFormTemplates(this.props.params?.formId);
				}
			})
			.catch((err) => this.setState(preState => ({...preState, formSubjects: { values: [], error: err, isLoading: false }})))
	}

	formatAccount(str : any): string {
		if (!str) {
			return '';
		}

		var v = str.replace(/[^\dA-Z]/g, '').split('');

		if (v.length<3)
		{
			return v.join('');
		}

		var reg = /.{4}/g;
		str = v.slice(0,2).join('') + " " + v.slice(2).join('').replace(reg, (a : string) => a + " ");
		
		return str.trim();
	}

	setValueInArray = (values: IFormTemplateDtoWithValue[], i: number, v: any): IFormTemplateDtoWithValue[] => {
		var res: IFormTemplateDtoWithValue[] = [];
		values.forEach(x => {
			res.push({...x})
		})

		//set value to false for each radio buttons from the same group 
		if (res[i].fieldType === FieldTypeEnum.RadioButton) {
			res.forEach(x => {
				if (x.fieldType === FieldTypeEnum.RadioButton 
					&& x.name === res[i].name /* are in the same group */) {
					x.value = false;
				}	
			});
		} 
		
		res[i].value = v;		
		
		return res;
	}

	render() {
		var renderField = (value: IFormTemplateDtoWithValue, changeState: (v: any) => void) => {
			
			switch (value.fieldType) {
				case FieldTypeEnum.RemarkHeader:
					return (<label className={"form-label col-form-label col"}><b style={{ fontSize: '20px' }}>{value.title}</b></label>)	
				case FieldTypeEnum.Remark:	
					return (<label className={`form-label col-form-label col`}>{value.title}</label>)		
				case FieldTypeEnum.CheckBox:
					value.value = value.value ?? false;
					return  (<>
							<label className={`form-label col-form-label col-md-3`}>{value.title}</label>
							<div className="col-md-9">
								<input
									type={`checkbox`} 
									id={`id${value.id}`} 
									name={`name${value.id}`} 
									className="form-check-input"					
									onChange={(e)=> { 
										//value.value = e.target?.checked; 
										changeState(e.target?.checked)
										console.log(e.target?.checked); }}
									required={value.isRequired} 														
									/>
							</div>
							</>)
					
				case FieldTypeEnum.TextArea:
					return (<>
							<label className={`form-label col-form-label col-md-3`}>{value.title}</label>
							<div className="col-md-9">
								<textarea 
									id={`id${value.id}`} 
									name={`name${value.id}`} 
									className={`form-control mb-5px`} 
									placeholder={value.title} 	
									value={value.value}
									onChange={(e)=> { 
										//value.value = e.target?.value
										changeState(e.target?.value)
									}}
									required={value.isRequired}												
									/>
						 	</div>
					</>)
				case FieldTypeEnum.RadioButton:
					value.value = value.value ?? false;
					return (<>
						<label className={`form-label col-form-label col-md-3`}>{value.title}</label>
						<div className="col-md-9">
							<input
								type={`radio`}
								id={`id${value.id}`}
								name={`name${value.name}`}
								className="form-check-input"
								onChange={(e) => { 
									value.value = e.target?.checked; 
									changeState(e.target?.checked);
									console.log(e.target?.checked); }}
								required={value.isRequired}
							/>
						</div>
					</>)
				case FieldTypeEnum.BankAccountNumber:
					return (<>
						<label className={`form-label col-form-label col-md-3`}>{value.title}</label>
						<div className="col-md-9">
							<input
								type='input'
								id={`id${value.id}`}
								name={`name${value.id}`}
								className={`form-control mb-5px`}
								placeholder={value.title}
								value={value.value || ''}
								onChange={(e) => { 
									var inputValue = e.currentTarget.value;
									var res = this.formatAccount(inputValue);
									console.log('formatAccount: ' + res);
									changeState(res);
								}}
								required={value.isRequired}
								maxLength={32}
								minLength={32}
							/>
						</div>
					</>)
				default:
					return (<>
							<label className={`form-label col-form-label col-md-3`}>{value.title}</label>
							<div className="col-md-9">
								<input 
									type={value.fieldType} 
									id={`id${value.id}`} 
									name={`name${value.id}`} 
									className={`form-control mb-5px`} 
									placeholder={value.title}
									value={value.value}	
									onChange={(e)=> { 
										//value.value = e.target?.value
										changeState(e.target?.value)
									}}
									required={value.isRequired} 														
									/>
							</div>
							</>)
		}}
		return (
			<div>
				<div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div>
				<Panel reload={this.state.formSubjects.isLoading || this.state.isSending}>
					<PanelHeader noButton="true">Formularz zgłoszeniowy</PanelHeader>
					
					<PanelBody>
						{										
							(this.state.formWasSend) ?
							<div className="row">
								<span>Twoja wiadomość została wysłana.</span>
								<button className="btn btn-primary w-100px me-5px" onClick={this.handleAfterSending}>OK</button>
							</div>
							:						
							<form onSubmit={this.handleSubmit}>	
								<div className="row mb-15px">
									<label className="form-label col-form-label col-md-3">Wybierz temat</label>
									<div className="col-md-9">
										<select className={`form-select`} name="topicId"
											value={this.state.selectedFormSubjectId} 
											onChange={this.handleSubjectOnChange}>
											<option value=""  key={-1}></option>
											{
												this.state.formSubjects.values?.map((value, i) => (
													<option value={value.id}  key={i}>{value.title}</option>
												))
											}			
										</select>
									</div>
								</div>
								{
								this.state.formSubjects.error &&
									<div>
										<span>
											{/* {this.state.formSubjects.error.message} */}
											Wystąpił błąd, skontaktuj się z administratorem.
										</span>
									</div>
								}
								{
									this.state.selectedFormSubjectId !== "" &&
										(<hr className="line-spacer" />)
								}
								{
									
								this.state.formTemplates.values.map((value, i) => (
									<div key={i} className="row mb-15px">										
									{
										renderField(value, (v: any) => {
											this.setState(preState => ({...preState,
												//selectedFormSubjectId: preState.selectedFormSubjectId, 
												formTemplates: { 
													...preState.formTemplates,
													values: this.setValueInArray(preState.formTemplates.values, i, v)
													//isLoading: preState.formTemplates.isLoading, error: undefined, values: []
												} 
											}))
										})
									}
									</div>
									))
								}
								{
								this.state.formTemplates.error &&
									<div>
										<span>
											{/* {this.state.formTemplates.error.message} */}
											Wystąpił błąd, skontaktuj się z administratorem.
										</span>
									</div>
								}
								{
								this.state.selectedFormSubjectId !== "" &&
								<div>
									<input type="submit"  className="btn btn-primary w-100px me-5px"></input>
								</div>									
								}
								{
								this.state.sendingError &&
									<div>
										<span>
											{/* {this.state.sendingError.message} */}
											Wystąpił błąd, skontaktuj się z administratorem.
										</span>
									</div>
								}
							</form>
						}
					</PanelBody>
				</Panel>
			</div>
		)
	}
}	

export default withParams(ApplicationsAndComplains);
