import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserContext } from '../../config/user-context';
import { IProcessingModel, withUseFormHook } from '../../helpers';
import { IMessageDto } from '../../models/generated';
import { alertService } from '../../services/alert.service';
import { messagesService } from '../../services/messages.service';
import { IModalDialogProps, ModalDialogMode } from "../../models/models";
 

export interface IMessageEditorModalProps extends IModalDialogProps, UseFormReturn<FormInputs, any> {
	messageToManage: IMessageDto | undefined
}

export interface IMessageEditorModalState extends IProcessingModel
{
	
}

type FormInputs = {
	id: number | undefined,
	subject: string,
	contentText: string,
	publicationDate: Date,
	
};

class MessageEditor extends React.Component<IMessageEditorModalProps, IMessageEditorModalState> {
	static contextType = UserContext;

	constructor(props: IMessageEditorModalProps) {
		super(props);
		this.state = { 
			isProcessing: false,
			error: undefined
		};
	}

	onSubmit = (data: FormInputs) => {
	
		this.setState((prevState) => ({ isProcessing: true }));	
		from(this.props.modalDialogMode === ModalDialogMode.Add ?
			 messagesService.addMessage({ ...data })
			 :
			 messagesService.updateMessage({ ...data }))			 
		.pipe(first())
		.subscribe({
			next: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	

				console.log(this.props.modalDialogMode === ModalDialogMode.Add ? 
					`AddMessage: ${val}!` : `UpdateMessage: ${val}!`);
				alertService.info(`${this.props.modalDialogMode === ModalDialogMode.Add ? 'Tworzenie wiadomości powiodło' : 'Aktualizacja wiadomości powiodła'} się`, `Wiadomość będzie widoczna dla użytkowników.`, { autoClose: true }); 
				if (val) {
					this.props.closeModalDialog(val.id);				
				}
			},
			complete: () => {
				this.setState((prevState) => ({ isProcessing: false }));	
				console.log(this.props.modalDialogMode === ModalDialogMode.Add ? 
					'AddMessage Complete!' : 'UpdateMessage Complete!' );
			},
			error: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	
				console.log(this.props.modalDialogMode === ModalDialogMode.Add ? 
					`AddMessage Error: ${val}!` : `UpdateMessage Error: ${val}!`);
				alertService.error(`${this.props.modalDialogMode === ModalDialogMode.Add ? 'Tworzenie wiadomości nie powiodło' : 'Aktualizacja wiadomości nie powiodła'}  się`, `${val}`, { autoClose: true }); 
			} 
		});	
  }

	toggle = () => {
		this.props.closeModalDialog(undefined);
	}
	render() {
		const { setValue, register, handleSubmit, formState: { errors } } = this.props;
		if (this.props.messageToManage !== undefined) {
			//if (this.props.messageToManage.id) {
				setValue("id", this.props.messageToManage.id);
			//}
			//if (this.props.messageToManage.publicationDate) {
				setValue("publicationDate", this.props.messageToManage.publicationDate ?? new Date());
			//}
			//if (this.props.messageToManage.subject){
				setValue("subject", this.props.messageToManage.subject ?? '');			
			//}
			//if (this.props.messageToManage.contentText){
				setValue("contentText", this.props.messageToManage.contentText ?? '');			
			//}
		}
		return (
			<Modal isOpen={this.props.isModalDialogOpened} >
				<form onSubmit={handleSubmit(this.onSubmit)}  >
					<ModalHeader toggle={this.toggle}>{ this.props.modalDialogMode === ModalDialogMode.Add ? 'Tworzenie wiadomości' : 'Edycja wiadomości'}</ModalHeader>
					<ModalBody>
						{/* <div className="form-floating mb-15px">
							<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Numer klienta" id="customerNo" 
								{...register("customerNo", { required: "Numer klienta jest wymagany"})}/>
							<label htmlFor="customerNo" className="d-flex align-items-center fs-13px text-gray-600">Numer klienta</label>
							{errors.customerNo && <p>{errors.customerNo.message}</p>}
						</div> */}
						<div className="form-floating mb-15px">
							<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Tytuł" id="subject" 
								{...register("subject", { required: "Tytuł jest wymagany" })}/>
							<label htmlFor="subject" className="d-flex align-items-center fs-13px text-gray-600">Tytuł</label>
							{errors.subject && <p>{errors.subject.message}</p>}
						</div>
						<div className="form-floating mb-15px">
							< textarea readOnly={this.state.isProcessing}  className="form-control fs-13px "  style={{height: '300px'}} placeholder="Treść wiaomości" id="contentText" 
								{...register("contentText", { required: "Treść wiadomości jest wymagana"})}/>
							<label htmlFor="contentText" className="d-flex align-items-center fs-13px text-gray-600">Treść wiaomości</label>
							{errors.contentText && <p>{errors.contentText.message}</p>}
						</div>
						
					</ModalBody>
					<ModalFooter>
						<Button disabled={this.state.isProcessing} color="primary" type="submit">
								{this.state.isProcessing && <Spinner className="spinner-border spinner-border-sm" animation="border" variant="primary" />}
								{ this.props.modalDialogMode === ModalDialogMode.Add ? 'Dodaj wiadomość' : 'Uaktualnij wiadomość'}</Button>
						<Button color="secondary" onClick={this.toggle}>Anuluj</Button>
					</ModalFooter>
				</form>
			</Modal>
		);
	}
}

export default withUseFormHook(MessageEditor);