import Home from '../pages/home';
import Invoices from '../pages/invoices';
import Payments from '../pages/payments';
import Contracts from '../pages/contracts';
import ApplicationsAndComplains from '../pages/applications-and-complains';
import MeterReading from '../pages/meter-reading';
import Declarations from '../pages/declarations';
import { isLoggedIn, UserContext } from './user-context';
import Login from '../pages/account/login';
import Register from '../pages/account/register';
import ForgotPassword from '../pages/account/forgot-password';
import ResetPassword from '../pages/account/reset-password';
import { Redirect } from 'react-router-dom';
import { VerifyEmail } from '../pages/account/verify-email';
import MessageManager from '../pages/message-manager';
import PrivacyPolicy from '../pages/privacy-policy';
import Contact from '../pages/contact';
import Regulations from '../pages/regulations';

/* <AuthorizeRoute path='/' component={FetchData} /> */
const routes = [

  {
    path: '/',
    exact: true,
    title: 'Pulpit',
    component: () => 
    <UserContext.Consumer>
      {(userContext) => (
         isLoggedIn(userContext?.userInfo) ?          
          <Home  userInfo={userContext.userInfo}/>
          :
          <Redirect to='/login' /> 
      )}
      </UserContext.Consumer>
  },
  { 
    path: '/login',
    exact: true,
    title: 'Login',
    component: () => <UserContext.Consumer>
      {(userContext) => (
        <Login  userContext={userContext}/>
      )}
      </UserContext.Consumer>
  },
  { 
    path: '/register',
    exact: true,
    title: 'Register',
    component: () => <Register />
  },
  { 
    path: '/verify-email',
    exact: true,
    title: 'Register',
    component: () => <VerifyEmail />
  },
  { 
    path: '/forgot-password',
    exact: true,
    title: 'ForgotPassword',
    component: () => <ForgotPassword />
  },
  { 
    path: '/reset-password',
    exact: true,
    title: 'ResetPassword',
    component: () => <ResetPassword />
  }, 
  {
    path: '/faktury',
    exact: true,
    title: 'Faktury',
    component: () => <UserContext.Consumer>
    {(userContext) => (
      isLoggedIn(userContext?.userInfo) ?          
      <Invoices userInfo={userContext.userInfo}/>
      :
      <Redirect to='/login' /> 
    )}
    </UserContext.Consumer>
  },
  {
    path: '/platnosci',
    exact: true,
    title: 'Płatności',
    component: () => <UserContext.Consumer>
    {(userContext) => (
      isLoggedIn(userContext?.userInfo) ?          
      <Payments userInfo={userContext.userInfo}/>
      :
      <Redirect to='/login' /> 
    )}
    </UserContext.Consumer>
  },

  {
    path: '/umowy',
    exact: true,
    title: 'Umowy',
    component: () => <UserContext.Consumer>
    {(userContext) => (
      isLoggedIn(userContext?.userInfo) ?          
      <Contracts userInfo={userContext.userInfo}/>
      :
      <Redirect to='/login' /> 
    )}
    </UserContext.Consumer>
  },

  {
    path: ['/wnioski-i-reklamacje', '/wnioski-i-reklamacje/:formId'],
    exact: true,
    title: 'Wnioski i reklamacje',
    component: () => <UserContext.Consumer>
      {(userContext) => (
        isLoggedIn(userContext?.userInfo) ?          
        <ApplicationsAndComplains userInfo={userContext.userInfo}/>
        :
        <Redirect to='/login' /> 
      )}
      </UserContext.Consumer>
  },
  {
    path: '/stan-licznikow',
    exact: true,
    title: 'Stan liczników',
      component: () => <UserContext.Consumer>
      {(userContext) => (
        isLoggedIn(userContext?.userInfo) ?          
        <MeterReading userInfo={userContext.userInfo}/>
        :
        <Redirect to='/login' /> 
      )}
      </UserContext.Consumer>
  },
  {
    path: '/deklaracje-na-odbior-odpadow',
    exact: true,
    title: 'Deklaracje na odbiór odpadów',
    component: () => <UserContext.Consumer>
      {(userContext) => (
        isLoggedIn(userContext?.userInfo) ?          
        <Declarations userInfo={userContext.userInfo}/>
        :
        <Redirect to='/login' /> 
      )}
      </UserContext.Consumer>
  },
  {
    path: '/zarzadzaj-wiadomosciami',
    exact: true,
    title: 'Zarządzanie wiadomościami',
    component: () => <UserContext.Consumer>
      {(userContext) => (
        isLoggedIn(userContext?.userInfo) ?          
        <MessageManager userInfo={userContext.userInfo}/>
        :
        <Redirect to='/login' /> 
      )}
      </UserContext.Consumer>
  },


  {
    path: '/regulamin',
    exact: true,
    title: 'Regulamin',
    component: Regulations
  },
  {
    path: '/polityka-prywatnosci',
    exact: true,
    title: 'Polityka prywatnosci',
    component: PrivacyPolicy
  },
  {
    path: '/kontakt',
    exact: true,
    title: 'Kontakt',
    component: Contact
  }
];


export default routes;