import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const alertSubject = new Subject<Alert>();
const defaultId = 'default-alert';

export const alertService = {
    onAlert,
    success,
    error,
    info,
    warn,
    alert,
    clear
};

export enum AlertType
{ 
    Success = "success",
    Error = "danger", 
    Info = "info",
    Warning = "warning"
 };

export type AlertOptions = Partial<{ id: string, autoClose: boolean, keepAfterRouteChange: boolean }>;
export type Alert = { type: AlertType, message: string, title: string } & AlertOptions; 
// enable subscribing to alerts observable
function onAlert(id = defaultId) {
    return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

// convenience methods
function success(title: string, message: string, options: AlertOptions) {
    alert({ ...options, type: AlertType.Success, message, title });
}

function error(title: string, message: string, options: AlertOptions) {
    alert({ ...options, type: AlertType.Error, message, title });
}

function info(title: string, message: string, options: AlertOptions) {
    alert({ ...options, type: AlertType.Info, message, title });
}

function warn(title: string, message: string, options: AlertOptions) {
    alert({ ...options, type: AlertType.Warning, message, title });
}

// core alert method
function alert(alert: Alert) {
    alert.id = alert.id || defaultId;
    alert.autoClose = (alert.autoClose === undefined ? true : alert.autoClose);
    alertSubject.next(alert);
}

// clear alerts
function clear(id = defaultId) {
    alertSubject.next({ id, 
        type: AlertType.Info, autoClose: false, title: '', message: '', keepAfterRouteChange: false });
}