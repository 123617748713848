
import { fetchWrapper, urlHelper } from "../helpers";
import { IPaymentDto } from "../models/generated";
import { getConfiguration } from "./config";

export const paymentsService = {
  getPayments,
  getPaymentsByIds
};

async function getPayments(customerId: number): Promise<IPaymentDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/payments/getPayments/${customerId}`);
  const res: IPaymentDto[] = resp;
  return res;
}

async function getPaymentsByIds(ids: number[]): Promise<IPaymentDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/payments/getPaymentsByIds${urlHelper.parameterizeArray('ids', ids)}`);
  const res: IPaymentDto[] = resp;
  return res;
}