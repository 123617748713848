import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { ICustomerInfoDto, UserContext } from '../../config/user-context';
import { IProcessingModel, withUseFormHook } from '../../helpers';
import { CustomerValidationReqeuest } from '../../models/generated';
import { alertService } from '../../services/alert.service';
import { customersService } from '../../services/customers.service';
import { IModalDialogProps } from '../../models/models';
import SweetAlert from 'react-bootstrap-sweetalert';
 
export interface IAddCustomerModalProps extends IModalDialogProps, UseFormReturn<FormInputs, any> {
	
}

export interface IAddCustomerModalState extends IProcessingModel
{
	showInfoAfterValidation: boolean;
}

type FormInputs = {
	//customerNo: string,
	invoiceNumber: string,
	invoiceAmount: number,
};

class AddCustomer extends React.Component<IAddCustomerModalProps, IAddCustomerModalState> {
	static contextType = UserContext;

	constructor(props: IAddCustomerModalProps) {
		super(props);
		this.state = { 
			isProcessing: false,
			error: undefined,
			showInfoAfterValidation: false
		};
	}

	onSubmit = (data: FormInputs) => {	
		this.setState((prevState) => ({ isProcessing: true }));	
		from(customersService.sendRequestToAssignCustomer(new CustomerValidationReqeuest({ ...data })))
		.pipe(first())
		.subscribe({
			next: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false, showInfoAfterValidation: true }));	

				console.log(`SendRequestToAssignCustomer: ${val}`); 
				alertService.info(`Weryfikacja klienta poprawna`, `Nastąpi synchronizacja danych dla klienta`, { autoClose: true }); 
				if (val) {
					var newCustomerInfos: ICustomerInfoDto[] = this.context.userInfo?.customerInfos ?? [];
					var newCustomers = newCustomerInfos.map(x => x.customer);
					newCustomers.push(val);
					this.context.setCustomers(newCustomers);
					this.context.setSelectedCustomer(val);
					this.props.closeModalDialog(val.id);				
				}
			},
			complete: () => console.log('SendRequestToAssignCustomer Complete!'),
			error: val => 
			{ 
				this.setState((prevState) => ({ isProcessing: false }));	
				console.log(`SendRequestToAssignCustomer Error: ${val}`); 
				alertService.error(`Weryfikacja klienta nie powiodła się`, `${val}`, { autoClose: true }); 
			} 
		});	
  	}

	hideInfoAfterValidation(evt: any): any {
		this.setState((prevState) => ({  showInfoAfterValidation: false }));	
	}

	toggle = () => {
		this.props.closeModalDialog(undefined);
	}
	render() {
		const { register, handleSubmit, formState: { errors } } = this.props;

		return (
			<div>
				<SweetAlert
					show={this.state.showInfoAfterValidation}
					title="Dane poprawne"
					onConfirm={(evt) => this.hideInfoAfterValidation(evt)}
					confirmBtnText="Ok"
					showCancel={false}
					focusCancelBtn={false}
					confirmBtnBsStyle="primary"
					>
						Twoje dane są obecnie pobierane i będą dostępne w ciągu 10 minut.
				</SweetAlert>
			
				<Modal isOpen={this.props.isModalDialogOpened} >
					
					<form onSubmit={handleSubmit(this.onSubmit)}  >
						<ModalHeader toggle={this.toggle}>Weryfikacja klienta</ModalHeader>
						<ModalBody>
							{/* <div className="form-floating mb-15px">
								<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Numer klienta" id="customerNo" 
									{...register("customerNo", { required: "Numer klienta jest wymagany"})}/>
								<label htmlFor="customerNo" className="d-flex align-items-center fs-13px text-gray-600">Numer klienta</label>
								{errors.customerNo && <p>{errors.customerNo.message}</p>}
							</div> */}
							<div>
								Aby zobaczyć swoje faktury i rozliczenia musimy zweryfikować czy jesteś naszym klientem.
								W poniższe pola wpisz odpowiednio numer dowolnej faktury wystawionej przez ZGK Szamotuły oraz widniejącą na niej, kwotę do zapłaty.<br />
								<br/>
							</div>
							<div className="form-floating mb-15px">
								<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Numer faktury" id="invoiceNumber" 
									{...register("invoiceNumber", { required: "Numer faktury jest wymagany"})}/>
								<label htmlFor="invoiceNumber" className="d-flex align-items-center fs-13px text-gray-600">Numer faktury</label>
								{errors.invoiceNumber && <p>{errors.invoiceNumber.message}</p>}
							</div>
							<div className="form-floating mb-15px">
								<input readOnly={this.state.isProcessing} type="number" step="0.01" className="form-control h-45px fs-13px" placeholder="Kwota do zapłaty" id="invoiceAmount"
									{...register("invoiceAmount", { required: "Kwota do zapłaty jest wymagana"})}/>
								<label htmlFor="invoiceAmount" className="d-flex align-items-center fs-13px text-gray-600">Kwota do zapłaty</label>
								{errors.invoiceAmount && <p>{errors.invoiceAmount.message}</p>}
							</div> 
							
						</ModalBody>
						<ModalFooter>
							<Button disabled={this.state.isProcessing} color="primary" type="submit">
									{this.state.isProcessing && <Spinner className="spinner-border spinner-border-sm" animation="border" variant="primary" />}
									Weryfikuj dane</Button>
							<Button color="secondary" onClick={this.toggle}>Anuluj</Button>
						</ModalFooter>
					</form>
				</Modal>
			</div>
		);
	}
	
}

export default withUseFormHook(AddCustomer);
