import { Panel, PanelHeader, PanelBody } from '../components/panel/panel';
import { IUserInfoProps, UserContext } from '../config/user-context';
import { IProcessingModel, withUseFormHook } from '../helpers';
import { UseFormReset, UseFormReturn } from 'react-hook-form';

import React from 'react';
import { contactsService } from '../services/contacts.service';
import { ContactFormDto } from '../models/generated';


interface IContactState extends IProcessingModel {
	formWasSend: boolean
}

interface ContactFormInputs {
	email: string,
	subject: string,
	content: string
}
export interface IContactProps 
	extends IUserInfoProps, UseFormReturn<ContactFormInputs, any>  {
}
class Contact extends React.Component<IContactProps, IContactState> {
	static contextType = UserContext;

	constructor(props: IContactProps) {
		super(props);
		this.state = { 
			error: undefined, 
			isProcessing: false,
			formWasSend: false		 
		};
	}

	  
	handleAfterSending = (ev: any, reset: UseFormReset<ContactFormInputs>) => {
		reset();
		this.setState({ formWasSend: false });
	}

	onSubmit = (data: ContactFormInputs) => {
		contactsService.submitContactForm(new ContactFormDto({...data}))
				.then((res) => {
					this.setState(prev => ({...prev, isSending: false, formWasSend: true}));
				})
				.catch((err) => {
					this.setState(preState => ({...preState, isSending: false, sendingError: err }))
				});
	}

	render() {
		const { register,  handleSubmit, formState: { errors }, reset } = this.props;

		return (
			<div>
				{/* <div className="row justify-content-end mb-15px me-1px">
					<CustomerSection {...this.props} />
				</div> */}
				<Panel reload={this.state.isProcessing}>
					<PanelHeader noButton="true">Kontakt/Zgłoś błąd</PanelHeader>
					<PanelBody>
					{
					(this.state.formWasSend) ?
						<div className="row">
							<span>Twoja wiadomość została wysłana.</span>
							<button className="btn btn-primary w-100px me-5px" onClick={(evt) => this.handleAfterSending(evt, reset)}>OK</button>
						</div>
						:	
						
						<form onSubmit={handleSubmit(this.onSubmit)}>
							{/* <input name="customerId" type="hidden"  value={this.props.userInfo?.selectedCustomerInfo?.customer?.id || ''} /> */}
							<div className="form-floating mb-15px">
								<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Adres email" id="email" 
									{...register("email", { 
										required: "Adres email jest wymagany",
										pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
									})
								}/>
								<label htmlFor="email" className="d-flex align-items-center fs-13px text-gray-600">Adres email</label>
								{errors.email && <p>Sprawdz poprawność adresu email, email jest wymagany.</p>}
							</div>
							<div className="form-floating mb-15px">
								<input readOnly={this.state.isProcessing} type="text" className="form-control h-45px fs-13px" placeholder="Tytuł" id="subject" 
									{...register("subject", { required: "Tytuł jest wymagany" })}/>
								<label htmlFor="subject" className="d-flex align-items-center fs-13px text-gray-600">Tytuł</label>
								{errors.subject && <p>{errors.subject.message}</p>}
							</div>
							<div className="form-floating mb-15px">
								<textarea readOnly={this.state.isProcessing}  className="form-control fs-13px "  style={{height: '300px'}} placeholder="Treść wiadomości" id="content" 
									{...register("content", { required: "Treść wiadomości jest wymagana"})}/>
								<label htmlFor="content" className="d-flex align-items-center fs-13px text-gray-600">Treść wiadomości</label>
								{errors.content && <p>{errors.content.message}</p>}
							</div> 
							<div >
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Wyślij</button>
							</div>
						</form>
					}
					</PanelBody>
				</Panel>
			</div>
		)
	}
} 

export default withUseFormHook(Contact);
