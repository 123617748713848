
import { fetchWrapper, urlHelper } from "../helpers";
import { ContractSummaryInfoDto, ContractTypeEnum, IContractDto, IContractWithDetailsDto } from "../models/generated";
import { getConfiguration } from "./config";

export const contractsService = {
    getContracts,
    getContractsWithDetails,
    getContractsByIds,
    deactivateContract,
    getContractsSummary
  };

async function getContracts(customerId: number): Promise<IContractDto[]> {        
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/contracts/getContracts/${customerId}`);
  const res: IContractDto[] = resp;
  return res;
}

async function getContractsWithDetails(customerId: number): Promise<IContractWithDetailsDto[]> {
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/contracts/getContractsWithDetails/${customerId}`);
  const res: IContractWithDetailsDto[] = resp;
  return res; 
}

async function getContractsByIds(ids: number[]): Promise<IContractDto[]> {
  const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/contracts/getContractsByIds${urlHelper.parameterizeArray('ids', ids)}`);
  const res: IContractDto[] = resp;
  return res;
}

async function deactivateContract(id: number): Promise<IContractDto> {
  const resp = await fetchWrapper.post(getConfiguration().apiUrl + `/contracts/deactivateContract`, id);
  const res: IContractDto = resp;
  return res;
}

async function getContractsSummary(customerId: number, contractType: ContractTypeEnum): Promise<ContractSummaryInfoDto> {
    const resp = await fetchWrapper.get(getConfiguration().apiUrl + `/contracts/getContractsSummary/${customerId}/${contractType === ContractTypeEnum.Water ? 1 : (contractType === ContractTypeEnum.Waste ? 2 : 3)}`);
  const res: ContractSummaryInfoDto = resp;
  return res; 
}
