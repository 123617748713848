import React from 'react';
import { IUserInfoProps, UserContext } from '../../config/user-context';
import AddCustomer from './add-customer';
import { IModalDialogProps, ModalDialogMode } from "../../models/models";
import CustomerSelector from './customer-selector';
import { customersService } from '../../services/customers.service';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';

interface ICustomerSectionStatus extends IModalDialogProps {
	isSynch: boolean
}


class CustomerSection extends React.Component<IUserInfoProps, ICustomerSectionStatus> {
	static contextType = UserContext;
	
	constructor(props: IUserInfoProps) {
		super(props);
		this.state = { 
			isSynch: false,
			isModalDialogOpened: false,
			closeModalDialog: this.closeCustomerModal,
			modalDialogMode: ModalDialogMode.Edit
		};
	}

	closeCustomerModal = (customerId: number | undefined) => {
		this.setState({ 
			isModalDialogOpened: false
		});
		this.synchronizeAccount();
	};

	synchronizeAccount = () => {
		
		//if (customerId) {
			// var customerInfos: ICustomerInfoDto[]  = this.context.userInfo?.customerInfos ?? [];		
			// var customer = customerInfos.find(x => x.customer?.id === customerId)?.customer;

			// if (customer === undefined) {
			// 	alertService.error(`Synchronizacja klienta`, `Klient nie istnieje`, { autoClose: true });
			// }

			this.setState((prevState) => ({ isSynch: true }));	
			from(customersService.synchronizeAccount())
				.pipe(
					first()
				)
				.subscribe({
					next: val => 
					{ 
						this.setState((prevState) => ({ isSynch: false }));	
						console.log(`synchronizeAccount success started`); 
						//alertService.info(`Synchronizację dla użytkownika`, `Rozpoczęto synchronizację dla użytkownika` , { autoClose: true }); 
						//todo: update consumers 
						//this.context.setCustomers(val);
						
					},
					complete: () => console.log('synchronizeAccount Complete!'),
					error: err => 
					{ 
						this.setState((prevState) => ({ isSynch: false }));	
						console.log(`synchronizeAccount Error: ${err}`); 
						//alertService.error(`Synchronizacja klienta nie powiodła się`, `${err}`, { autoClose: true }); 
					} 
				});
		//}
	}

	// synchronizeCurrentCustomer = (event: any)  => {
	// 	console.log('synchronizeCurrentCustomer');
	// 	var customerId = this.context.userInfo?.selectedCustomerInfo?.customer?.id;
	// 	this.synchronizeCustomer(customerId);
	// }

	openAddCustomer = () => {
		this.setState({ 
			isModalDialogOpened: true
		});
	};
	openRemoveCustomer = () => {

	}

	render() {
		return (
			<>
				<div className="row justify-content-end ">
					{/* <label className="form-label  col-form-label text-align-end col-md-3">Wybierz klienta:</label> */}
					<CustomerSelector className="form-col-select col-lg-4 col-md-9  customer-selector"/>
					{/* <button  className="btn btn-purple col-md-1 me-md-1 mb-1" onClick={this.openAddCustomer}><i  className="far ion-md-add"></i></button>
					<button  className="btn btn-purple col-md-1  mb-1" onClick={this.openRemoveCustomer}><i  className="far ion-md-remove"></i></button> */}
					{/* <button  className="btn btn-purple col-md-1 mx-1 mb-1" onClick={this.synchronizeAccount}>Synch...</button> */}
					<AddCustomer {...this.state} />
				</div>
			</>
		)
	}
}

export default CustomerSection;