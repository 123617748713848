import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AppContext } from '../../config/app-settings';
import { isLoggedIn, IUserContextData, UserContext } from '../../config/user-context';
import { accountService } from '../../services/account.service';
import { UseFormReturn } from "react-hook-form";
import { from } from 'rxjs';
import { alertService } from '../../services/alert.service';
import { withUseFormHook } from '../../helpers';
import { first } from 'rxjs/operators';

interface IForgotPasswortState {
	redirect: boolean,
	loading: boolean,
	message: string
}
interface IForgotPasswortProps extends UseFormReturn<FormInputs, any> {
	userContext: IUserContextData
}

type FormInputs = {
	email: string,
};

class ForgotPassword extends React.Component<IForgotPasswortProps, IForgotPasswortState> {
	static contextType = AppContext;
	
	constructor(props: any) {
		super(props);
		var shouldRedirect = accountService.userValue?.isVerified === true;
		console.log('Login, shouldRedirect: ' + shouldRedirect)
		this.state = {
			redirect: shouldRedirect,
			loading: false,
			message: ""
		}
	}
	
	
    onSubmit = (data: FormInputs) => {		
		from(accountService.forgotPassword(data.email))
		.pipe(first())
		.subscribe({
			next: val => 
			{ 
				console.log(`Forgot Password Success: ${val}`); 
				alertService.info(`Resetowanie hasła`, `Sprawdz skrzynkę mailową`, { autoClose: true }); 
				this.setState(state => ({
					redirect: true
				}));	
			},
			complete: () => console.log('Forgot Password Complete!'),
			error: val => 
			{ 
				console.log(`Forgot Password Error: ${val}`); 
				alertService.error(`Resetowanie hasła`, `${val}`, { autoClose: true }); 
			} 
		});	
  }
  
	render() {
		const { register, handleSubmit, formState: { errors } } = this.props;
		
		if (this.state.redirect) {
			return <Redirect to='/'/>;
	 	}
		return (
			<UserContext.Consumer>
			{(userContext) => { 
				if (isLoggedIn(userContext.userInfo)) {
					return (<Redirect to='/'/>)	
				}
				else {
					return (
						<div className="login login-with-news-feed">
							<div className="login-container">
								<div className="login-content">
									<form onSubmit={handleSubmit(this.onSubmit)} className="fs-13px">
										<div className="form-floating mb-15px">
											<input type="text" className="form-control h-45px fs-13px" placeholder="Email Adres" id="emailAddress" 
												{...register("email", { required: "Email adres jest wymagany", pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message: "Niepoprawny format email"
												}})}/>
											<label htmlFor="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email Adres</label>
											{errors.email && <p>{errors.email.message}</p>}
										</div>
										<div className="mb-15px">
											<button type="submit" className="btn btn-success d-block h-45px w-100 btn-lg fs-14px">Resetuj hasło</button>
										</div>
										<div className="mb-4 text-inverse">
											Nie masz konta? <Link to="/register" className="text-primary">Zarejestruj się</Link>.
										</div>
										<div className="mb-4 pb-5">
											Masz już konto? <Link to="/login"  className="text-primary">Zaloguj się</Link>.
										</div>
									</form>
								</div>
							</div>
						</div>
					)
				}
			}}
			</UserContext.Consumer>
		)
	}
}

export default withUseFormHook(ForgotPassword);