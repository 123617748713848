import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { IUserContextData } from '../../config/user-context';
import { addressHelper } from '../../helpers/address';
import { IModalDialogProps, ModalDialogMode } from '../../models/models';
import { alertService } from '../../services/alert.service';
import { customersService } from '../../services/customers.service';
 
interface CustomerCardProps {
	userContextData: IUserContextData;
	displayRemoveButton: boolean;
}

interface CustomerCardState extends IModalDialogProps {
	isSynch: boolean,
	showRemoveCustomerConfirmation: boolean, 
	removingCustomerId: number | undefined
}

class CustomerCard extends React.Component<CustomerCardProps, CustomerCardState> {
	constructor(props: CustomerCardProps) {
		super(props);
		this.state = { 
			isSynch: false,
			isModalDialogOpened: false,
			closeModalDialog: this.closeCustomerModal,
			modalDialogMode: ModalDialogMode.Edit,
			showRemoveCustomerConfirmation: false, 
			removingCustomerId: undefined
		};
	}

	closeCustomerModal = (customerId: number | undefined) => {
		this.setState({ 
			isModalDialogOpened: false
		});
		this.synchronizeAccount();
	};

	synchronizeAccount = () => {
		this.setState((prevState) => ({ isSynch: true }));	
		from(customersService.synchronizeAccount())
			.pipe(
				first()
			)
			.subscribe({
				next: val => 
				{ 
					this.setState((prevState) => ({ isSynch: false }));	
					console.log(`synchronizeAccount success started`); 
					
				},
				complete: () => console.log('synchronizeAccount Complete!'),
				error: err => 
				{ 
					this.setState((prevState) => ({ isSynch: false }));	
					console.error(`synchronizeAccount Error: ${err}`); 
				} 
			});
	}

	openAddCustomer = () => {
		this.setState({ 
			isModalDialogOpened: true
		});
	};


	removeCustomer(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number | undefined): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.setState(prev => ({ ...prev, showRemoveCustomerConfirmation: true, removingCustomerId: id  }))
	}

	removeCustomerConfirmed(param: any)
	{
		console.log('removeCustomerConfirmed');
		var id = this.state.removingCustomerId;

		if (id !== undefined) {
			customersService.removeCustomer(id)
			.then(removedCustomer => {
				alertService.info('Usuwanie klienta', 'Klient został usunięty', { autoClose: true });
				var newCustomerInfos = this.props.userContextData.userInfo.customerInfos.filter(x => x.customer.id !== removedCustomer.id);
				this.props.userContextData.setCustomerInfos(newCustomerInfos);
				this.setState(prev => ({ ...prev, showRemoveCustomerConfirmation: false, removingCustomerId: undefined  }))
			})
			.catch(err => {
				alertService.error('Usuwanie klienta nie powiodło się', `${err}`, { autoClose: true });
				console.error(`RemoveCustomer Error: ${err}`); 
				this.setState(prev => ({ ...prev, showRemoveCustomerConfirmation: false, removingCustomerId: undefined  }))
			})
		}
	}
	removeCustomerCanceled = () => {
		this.setState(prev => ({ ...prev, showRemoveCustomerConfirmation: false, removingCustomerId: undefined  }))
	}

	render() {
		return (
			<>
			{
				this.props.userContextData.userInfo.selectedCustomerInfo?.customer &&
		
				<div className="row" style={{ marginRight: '1px'}}>
					<SweetAlert
						show={this.state.showRemoveCustomerConfirmation}
						title="Usuwanie klienta!"
						onConfirm={(evt) => this.removeCustomerConfirmed(evt)}
						onCancel={() => this.removeCustomerCanceled()}
						dependencies={[ this.state.removingCustomerId ]}											
						showCancel={true}
						focusCancelBtn={true}
						confirmBtnBsStyle="danger"
						cancelBtnBsStyle="primary"
						>
							Czy na pewno chcesz usunąć klienta?
					</SweetAlert>
						
					{/* <div className='row justify-content-end' > */}
						{
							this.props.displayRemoveButton &&					
								<button  className="btn btn-purple d-sm-none"  >
									Usuń...
								</button>
						}
					{/* </div> */}
					<div className="col-sm-10 mb-4	">
						<div className={`ebok-color-text`}>{this.props.userContextData.userInfo.selectedCustomerInfo?.customer?.name}</div>
						<div className="ebok-color-text">{addressHelper.format(this.props.userContextData.userInfo.selectedCustomerInfo?.customer, true)}</div>
						<div className="ebok-color-text" >NIP: {this.props.userContextData.userInfo.selectedCustomerInfo?.customer?.vatid}</div>
						
					</div>
					<div className="d-none d-sm-block col-sm-2 " style={{textAlign: 'end', justifyContent: 'end'}}>
					{
						this.props.displayRemoveButton &&					
								<button onClick={(evt) => this.removeCustomer(evt, this.props.userContextData.userInfo.selectedCustomerInfo?.customer?.id)} 
								 	className="btn btn-purple " style={{ marginRight: '-10px', marginLeft: 'auto'}}  >
									Usuń...
								</button>
					}
					</div>
				</div>
			}
			</>
		);
	}
	
}

export default CustomerCard;
