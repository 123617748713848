export const staticFilesService = {
    getRegulations,
    getPrivatePolicy
};

function getRegulations() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
    };
    return fetch('./regulamin.html', requestOptions)
        .then(handleResponse);
}

function getPrivatePolicy() {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
    };
    return fetch('./polityka-prywatnosci.html', requestOptions)
        .then(handleResponse);
}

function handleResponse(response: Response) {
    return response.text().then((text: any) => {
        const data = text;
        
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}


