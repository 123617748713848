import React from 'react';
import { Link } from 'react-router-dom';
//import DropdownNotification from './dropdown/notification';
import DropdownLanguage from './dropdown/language';
import DropdownProfile from './dropdown/profile';
//import SearchForm from './search/form';
import DropdownMegaMenu from './dropdown/mega';

import { AppContext } from './../../config/app-settings';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggleAppHeaderMegaMenuMobile = this.toggleAppHeaderMegaMenuMobile.bind(this);
		this.state = { appHeaderMegaMenuMobile: false };
	}

	toggleAppHeaderMegaMenuMobile() {
		this.setState({ appHeaderMegaMenuMobile: !this.state.appHeaderMegaMenuMobile });
	}
	render() {
		return (
			<AppContext.Consumer>
				{({toggleAppSidebarMobile, toggleAppSidebarEnd, toggleAppSidebarEndMobile, toggleAppTopMenuMobile, appHeaderLanguageBar, appHeaderMegaMenu, appHeaderInverse, appSidebarTwo, appTopMenu, appSidebarNone}) => (
					<div id="header" className={'app-header ' + (appHeaderInverse ? 'app-header-inverse' : '')}>
						<div className="navbar-header">
							{appSidebarTwo && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarEndMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							<Link to="/" className="navbar-brand">
								{/* <span className="navbar-logo"></span>  */}
								{/* <b>eBok</b> */}
							</Link>
							
							{appHeaderMegaMenu && (
								<button type="button" className="navbar-mobile-toggler" onClick={this.toggleAppHeaderMegaMenuMobile}>
									<span className="fa-stack fa-lg text-inverse">
										<i className="far fa-square fa-stack-2x"></i>
										<i className="fa fa-cog fa-stack-1x"></i>
									</span>
								</button>
							)}
							{appTopMenu && !appSidebarNone && (
							<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
								<span className="fa-stack fa-lg text-inverse">
									<i className="far fa-square fa-stack-2x"></i>
									<i className="fa fa-cog fa-stack-1x"></i>
								</span>
							</button>
							)}
							{appSidebarNone && appTopMenu && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppTopMenuMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							{!appSidebarNone && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
						</div>
						
						{appHeaderMegaMenu && (
							<DropdownMegaMenu collapse={this.state.appHeaderMegaMenuMobile} />
						)}
						
						<div className="navbar-nav">
							{/* <SearchForm /> */}
							{/* <DropdownNotification /> */}
							
							{appHeaderLanguageBar && (
								<DropdownLanguage />
							)}
							
							<DropdownProfile />
							
							{appSidebarTwo && (
								<div className="navbar-divider d-none d-md-block"></div>
							)}
							
							{appSidebarTwo && (
								<div className="navbar-item d-none d-md-block">
									<Link to="/" onClick={toggleAppSidebarEnd} className="navbar-link icon">
										<i className="fa fa-th"></i>
									</Link>
								</div>
							)}
						</div>
					</div>
				)}
			</AppContext.Consumer>
		)
	}
}

export default Header;
