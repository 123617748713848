import React from 'react';
import { CardHeader, CardBody, Card } from 'reactstrap';
import { stringHelper } from '../../helpers/string';
import { IContractSummaryInfoDto } from '../../models/generated';

interface IContractInfoProps {
	contractSummaryInfoDto: IContractSummaryInfoDto;
} 

class ContractInfo extends React.Component<IContractInfoProps> {
	render() {
		return (
		<Card  >
			<CardHeader className={'d-flex align-items-center '}>
				{/* <i className="fa fa-circle ebok-color-text fs-6px me-2 "></i> Umowa: {this.props.contractInfo?.contractWithDetails?.contract?.contractNumber} */}
			</CardHeader>
			<CardBody className="p-3">
				<div className="row">
					<div><span>{this.props.contractSummaryInfoDto.currentDueCaption}</span> <b>{ stringHelper.currencyFormat(this.props.contractSummaryInfoDto.currentDueValue, '') }</b></div>
					<div><span>{this.props.contractSummaryInfoDto.paymentCaption}</span> <b>{this.props.contractSummaryInfoDto.paymentDate}</b></div>
					{this.props.contractSummaryInfoDto.overdueValue === null &&
						<div><span>{this.props.contractSummaryInfoDto.overdueCaption}</span></div>
					}
					{this.props.contractSummaryInfoDto.overdueValue !== null &&
							<div><span className="text-danger">{this.props.contractSummaryInfoDto.overdueCaption} <b>{stringHelper.currencyFormat(this.props.contractSummaryInfoDto.overdueValue, '')}</b></span></div>
					}
					{this.props.contractSummaryInfoDto.overpaidValue != null && this.props.contractSummaryInfoDto.overpaidValue > 0 &&
							< div ><span className="text-success">{this.props.contractSummaryInfoDto.overpaidCaption} <b>{stringHelper.currencyFormat(this.props.contractSummaryInfoDto.overpaidValue, '')}</b></span></div>
					}
				</div>
			</CardBody>
		</Card>);
	}
}

export default ContractInfo;
