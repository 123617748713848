
import { fetchWrapper } from "../helpers";
import { IContactFormDto, IContractDto } from "../models/generated";
import { getConfiguration } from "./config";

export const contactsService = {
    submitContactForm
  };



async function submitContactForm(contactForm: IContactFormDto): Promise<IContractDto> {
  const resp = await fetchWrapper.post(getConfiguration().apiUrl + `/contactForms/submitContactForm`, contactForm);
  return resp.ok;
}

