import React from "react"
import { Nav } from "react-bootstrap"
import { Link } from "react-router-dom"

const Footer = (): JSX.Element => <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-4 mb-md-0 mb-3">
                <Nav.Link  as={Link} to="/regulamin">Regulamin</Nav.Link>
            </div>
            <div className="col-md-4 mb-md-0 mb-3">
                <Nav.Link  as={Link} to="/polityka-prywatnosci">Polityka Prywatności</Nav.Link>
            </div>
            <div className="col-md-4 mb-md-0 mb-3">
                <Nav.Link  as={Link} to="/kontakt">Kontakt/Zgłoś błąd</Nav.Link>
            </div>
        </div>
    </div>
{/* 
    <div className="footer-copyright text-center py-3">© 2020 Copyright:
        <a href="https://mdbootstrap.com/"> MDBootstrap.com</a>
    </div> */}

</footer>

export default Footer