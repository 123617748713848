import React from 'react';
import { ContractTypeEnum, IContractWithDetailsDto } from '../../models/generated';


interface IContractDetailsProps {
	contractDetails: IContractWithDetailsDto;
}

class ContractDetails extends React.Component<IContractDetailsProps & React.HTMLAttributes<HTMLDivElement>, {}> {
	
	render() {
		return (
			<div className={this.props.className}>
				{(this.props.contractDetails.contract?.contractType === ContractTypeEnum.Water ?
				<div >
					{/* className="d-none d-lg-block" > */}
					<div className="row standard-table standard-header">
						<div className="col">Numer licznika</div>
						<div className="col">Licznik główny</div>
					</div>

					<div className="row">
						
							{this.props.contractDetails.meters !== undefined && 
							this.props.contractDetails.meters
							.map((c,idx) =>
							<div key={idx} className="row standard-table"  >
								<div  className="row ">
									<div className="col center">{c.number}</div>
									<div className="col center">{c.master ? <i className="far ion-md-checkmark"></i> : <></>}</div>
								</div>
							</div>
							)}
					</div>
				</div>
				:
				<div >
					{
						this.props.contractDetails.bankAccount &&
						<span> Rachunek bankowy: <strong>{this.props.contractDetails.bankAccount} </strong></span>
					}
					{/* className="d-none d-lg-block" > */}
					<div className="row standard-table standard-header">
						<div className="col-7">Nazwa</div>
						<div className="col-3">Rodzaj</div>
						<div className="col-2">Ilość</div>
					</div>

					<div className="row">
						
							{this.props.contractDetails.containers !== undefined && 
							this.props.contractDetails.containers
							.map((c,idx) =>
							<div key={idx} className="row standard-table"  >
								<div  className="row ">
									<div className="col-7">{c.name}</div>
									<div className="col-3">{c.kind}</div>
									<div className="col-2">{c.number}</div>
								</div>
							</div>
							)}
					</div>
				</div>
				)}
			</div>
		)
	}
} 

export default ContractDetails;
