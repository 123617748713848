import { BehaviorSubject } from 'rxjs';
import { fetchWrapper, history } from '../helpers';
import { AuthenticateResponse, RegisterRequest, ResetPasswordRequest, UpdateRequest } from '../models/generated';
import { getConfiguration } from "./config";


const userSubject = new BehaviorSubject<AuthenticateResponse | null>(null);
const baseUrl = `${getConfiguration().apiUrl}/accounts`;
let prevUser: AuthenticateResponse | null = null;

export const accountService = {
    login,
    logout,
    refreshToken,
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    update,
    user: userSubject.asObservable(),
    get prevUser () { return prevUser },
    get userValue () { return userSubject.value }
};



function login(email: string, password: string) {
    return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password })
        .then((user: AuthenticateResponse) => {
            // publish user to subscribers and start timer to refresh token
            prevUser = userSubject.value;
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });
}

function logout() {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
    fetchWrapper.post(`${baseUrl}/revokeToken`, {});
    stopRefreshTokenTimer();
    prevUser = userSubject.value;
    userSubject.next(null);
    history.push('/login');
}

function refreshToken() {
    console.log('account.service: refreshToken()')
    return fetchWrapper.post(`${baseUrl}/refreshToken`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            console.log('account.service: refreshToken() ' + JSON.stringify(user))
            prevUser = userSubject.value;
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        })
        .catch(err => {
            console.error('account.service ' + err);
        });
}

function register(params: RegisterRequest) {
    return fetchWrapper.post(`${baseUrl}/register`, params);
}

function verifyEmail(token: string) {
    return fetchWrapper.post(`${baseUrl}/verifyEmail`, { token });
}

function forgotPassword(email: string) {
    return fetchWrapper.post(`${baseUrl}/forgotPassword`, { email });
}

function validateResetToken(token: string) {
    return fetchWrapper.post(`${baseUrl}/validateResetToken`, { token });
}

function resetPassword(request: ResetPasswordRequest) {
    return fetchWrapper.post(`${baseUrl}/resetPassword`, request);
}

function update(request: UpdateRequest) {
    return fetchWrapper.put(`${baseUrl}/update`, request);
}


let refreshTokenTimeout: NodeJS.Timeout | undefined;

function startRefreshTokenTimer() {
    stopRefreshTokenTimer();

    if (userSubject.value && userSubject.value.jwtToken) {
    // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(refreshToken, timeout);
    }
}

function stopRefreshTokenTimer() {
    if (refreshTokenTimeout !== undefined) {
        clearTimeout(refreshTokenTimeout);
    }
    refreshTokenTimeout = undefined;
}
