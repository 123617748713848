import React from 'react';
import { CardHeader, CardBody, Card } from 'reactstrap';
import { IMessageDto } from '../../models/generated';

interface IMessageProps {
	message: IMessageDto;
} 

class Message extends React.Component<IMessageProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> {

	render() {
		return (
		<Card className={`bg-gray-200 border-0 ${ this.props.className }`} >
			<CardHeader className={'d-flex align-items-center '}>
				<i className="fa fa-circle ebok-color-text fs-6px me-2"></i>{this.props.message.subject}
			</CardHeader>
			<CardBody className="p-3">
				<span>{this.props.message.contentText}</span>
				
			</CardBody>
		</Card>);
	}
}

export default Message;