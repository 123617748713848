import React from 'react';
import { IProcessingModel } from '../helpers';
import { IContractDto, IContractWithDetailsDto } from '../models/generated';
import { contractsService } from '../services/contracts.service';
import { IUserInfoProps } from '../config/user-context';

export abstract class ContractRelatedComponentBase<P extends IUserInfoProps, S extends IProcessingModel> extends React.Component<P, S> {
    getContractsWithDetails = async (customerId: number | undefined, force: boolean = false): Promise<IContractWithDetailsDto[] | undefined> => {
		return new Promise<IContractDto[] | undefined>((resolve, reject) => {
			if (customerId !== undefined) { 
				this.setState({ error: undefined, isProcessing: true  });
				if (this.props.userInfo.selectedCustomerInfo?.contractInfos === undefined
					 || this.props.userInfo.selectedCustomerInfo?.contractInfos.length === 0
					 || force
				 	) {
						contractsService.getContractsWithDetails(customerId)
							.then((res) => {
								this.context.setContracts(customerId, res);				
								this.setState({  error: undefined, isProcessing: false });					
								resolve(res);
							})
							.catch((err) => {
								this.context.setContracts(customerId, []);
								this.setState({  error: err, isProcessing: false });		
								reject(err);
							});
					} else {
						this.setState({  error: undefined, isProcessing: false });
						resolve(this.props.userInfo.selectedCustomerInfo?.contractInfos.map(x => x.contractWithDetails));
					}
			} else {
				this.context.setContracts(customerId, []);	
				this.setState({ error: undefined, isProcessing: false });
				resolve(undefined);
			}		
		});
    }
}