import { Observable, Observer } from "rxjs";
import { getConfiguration } from "./config";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { accountService } from "./account.service";

// export interface IEnovaSyncServerInfo {
//     type: number
// }

export const enovaSyncNotificationService = {
    getEnovaSyncStream
};

export type EnovaSyncNotificationType = "ContractsAdded" | "ContractsUploaded" | "DocumentsAdded" | "PaymentsAdded" | "MeterReadsAdded";
export interface enovaSyncNotification {
    customerId: number, 
    error: string | undefined,
    ids: number[] | undefined,
    type: EnovaSyncNotificationType
}

function  getEnovaSyncStream(): Observable<enovaSyncNotification> {
    return new Observable<enovaSyncNotification>((obs: Observer<enovaSyncNotification>) => {
        const operationsHubConnection = new HubConnectionBuilder()
            .withUrl(getConfiguration().baseUrl + '/hubs/enovaSynchronization'
            // , {
            //     //headers: { "Authorization": `Bearer ${accountService.userValue?.jwtToken}`},
            //     accessTokenFactory: () => { return accountService.userValue?.jwtToken ?? 'TEST' }
            // }
            )
            .withAutomaticReconnect()
            .build();
        operationsHubConnection.onclose(error => {
            if (error) {
                console.error(`Connection close with error ${error}`);
            } else {
                console.info('Connection stoped.');
            }
        });
        operationsHubConnection.onreconnected(id => {
            operationsHubConnection.invoke('UnsubscribeAsync', accountService.userValue?.id).then(
                () => operationsHubConnection.invoke('SubscribeAsync', accountService.userValue?.id)
            );
            
        });

        operationsHubConnection.on('contractsSynchronized', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: undefined,
                ids: evt,
                type: "ContractsAdded"
            });
        });
        operationsHubConnection.on('contractsUpdatedDuringSynchronization', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: undefined,
                ids: evt,
                type: "ContractsUploaded"
            });
        });
        operationsHubConnection.on('contractsSynchronizationError', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: evt,
                ids: undefined,
                type: "ContractsAdded"
            });
        });
        operationsHubConnection.on('documentsSynchronized', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: undefined,
                ids: evt,
                type: "DocumentsAdded"
            });
        });
        operationsHubConnection.on('documentsSynchronizationError', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: evt,
                ids: undefined,
                type: "DocumentsAdded"
            });
        });


        operationsHubConnection.on('paymentsSynchronizationError', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: evt,
                ids: undefined,
                type: "PaymentsAdded"
            });
        });
        operationsHubConnection.on('paymentsSynchronized', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: undefined,
                ids: evt,
                type: "PaymentsAdded"
            });
        });

        operationsHubConnection.on('meterReadsSynchronizationError', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: evt,
                ids: undefined,
                type: "MeterReadsAdded"
            });
        });
        operationsHubConnection.on('meterReadsSynchronized', (customerId, evt) => {
            obs.next({ 
                customerId: customerId,
                error: undefined,
                ids: evt,
                type: "MeterReadsAdded"
            });
        });

        operationsHubConnection.start().then(
            () => operationsHubConnection.invoke('SubscribeAsync', accountService.userValue?.id));

        return () => {
            operationsHubConnection.invoke('UnsubscribeAsync', accountService.userValue?.id).then(
                () => operationsHubConnection.stop()
            );
        };
    });
}
